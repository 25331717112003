import { flow, getEnv, types } from "mobx-state-tree";
import BatchStore from "../../stores/domain/BatchStore";
import { get } from "../../services/httpService";

export const BatchList = types.model('BatchList',{
  // items: types.optional(types.array(Batch),[]),
  batchStore: types.optional(BatchStore,{}),
  isLoading: types.optional(types.boolean, false), //types.enumeration('State', ['pending', 'done', 'error']),
  page: types.optional(types.integer, 1),
  perPage: types.optional(types.integer, 10),
  totalRows: types.optional(types.integer, 0),
  sortOrder: types.optional(types.string, "desc"),
  sortField:  types.optional(types.string, "updatedAt"),
}).volatile(self => ({
  items: [],
  searchParams: {}
}))
  .actions((self) => ({
    getItems() {
      return self.items
    },
    setSortOrder(sortDirection){
      self.sortOrder = sortDirection
    },
    setSortField(sortField){
      self.sortField = sortField
    },
    fetchAll: flow(function* fetchAll(sortParams = {}) {
      self.items = []
      self.isLoading = true
      if (Object.keys(sortParams).length === 0) {
        sortParams = {
          sort: 'updatedAt',
          order: self.sortOrder
        };
      }
      try {
        const queryParams = {
          page: self.page,
          perPage: self.perPage,
          ...sortParams,
          ...self.searchParams
        }
        console.log(queryParams)
        // ... yield can be used in async/await style
        const manager = getEnv(self).batchManager
        const response = yield manager.fetchBatches(queryParams)
        //CodeModel.create({uuid: one.uuid, status: one.status}) //TODO: convert
        if(response){
          self.items = response.results;
          self.totalRows = response._meta.itemCount;
        }

        self.isLoading = false;
      } catch (error) {
        console.error('Failed to fetch tags', error)
      }
    }),
    addItems(item){
      self.items.push(item)
    },
    updatePage: function (page) {
      self.page = page
    },
    updatePerPage: function (perPage) {
      self.perPage = perPage
    },
    addSearchParams(param){
      self.searchParams = {...self.searchParams, ...param}
    },
    removeSearchParams(names){
      if(names){
        names.map((name) => delete self.searchParams[name])
      }
    },
    afterCreate() {
      console.log("Init Search Params")
      self.searchParams = {}
    },
    mergeBatch: flow(function* mergeBatch(batchUuid, sortParams = {}) {
      const queryParams = {
        page: self.page,
        perPage: self.perPage,
        ...sortParams
      };
      const manager = getEnv(self).batchManager
      const response = yield manager.mergeBatch(batchUuid, queryParams);
    }),
  }))
