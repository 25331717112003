import React, {useState} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    CustomInput,
    Form,
    FormGroup, Input,
    InputGroup,
    Label,
    Row,
    CardImg, Collapse
} from "reactstrap";
import Select from "react-select";
import { types, flow } from "mobx-state-tree"
import {observer} from "mobx-react-lite";
import * as Icon from "react-feather";
import ColorButton from "../../../ColorButton";
import Caption from "./Caption";

const CaptionList = observer(({frameAndCaptionsStore, extendConfig, imageCharsStore, colorStore}) => {
    const { captionRows, handleAddCaption, handleRemoveCaption } = frameAndCaptionsStore;
    return (
        <>
            <Row>
                <Col sm={12}>
                {captionRows.map((CaptionState, index) => {
                    return (
                        <>

                            <Caption key={index} caption={CaptionState} imageCharsStore={imageCharsStore} onRemove={() => handleRemoveCaption(index, CaptionState.id, extendConfig)} colorStore={colorStore}/>

                        </>
                    )
                })}

                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm={4}>
                    <div id="new-caption"> <Icon.PlusCircle size={24} style={{color: '#0093DB'}} onClick={() => handleAddCaption(extendConfig)}/>Add New Caption</div>
                </Col>
            </Row>
            <Row>
                <Col sm={2}>
                    Circular Text
                </Col>
                <Col sm={3}>
                    <div className="move-switch-position">
                        <CustomInput
                            type="switch"
                            id="circularText"
                            checked={frameAndCaptionsStore.circularText}
                            onChange={() => frameAndCaptionsStore.toggleCircularText()}
                            />
                    </div>
                </Col>

            </Row>
        </>
    )
})

export default CaptionList;
