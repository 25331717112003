import HttpClient from "../../services/HttpClient";
import PreviewManager from "../preview/PreviewManager";
import lodash from "lodash";
import {setDefaultBackgroundColor, setEyesAndModules} from "../../utils/common";
import {scannabilityCheck, generateImageCheckscannability} from "../../utils/scannability";

const IMAGE_SERVICE = "https://nigiu8dgm4.execute-api.us-east-2.amazonaws.com/dev";

class ImageConfigManager {

  constructor(httpClient = null) {
    this.httpClient = httpClient || new HttpClient()
    console.log(this.httpClient)
    this.url = `/qr/image/configs`;
    this.designerHttpClient = new HttpClient(IMAGE_SERVICE);
  }

  async create(instance) {
    try {
      const data = {
        "name": instance.name ? instance.name : "You forgot to give me a name",
        "qrPreview": instance.qrPreview,
        "data": instance.qrImageFormData,
        "owner": {
          "groupUuid":instance.groupUuid,
          "dimensions": instance.dimensions
        },
        "isExclusivelyGroupOwned": instance.isExclusivelyGroupOwned,
        "tags": instance.tags
      }
      const res = await this.httpClient.post(this.url, data);
      console.log('ImageConfigManager', res)
      return res
    } catch (e) {
      console.error(e);
      return null;
    }
  }
async applyToCode(instance) {
    try {
      const data = {
        "level":"qr",
        "name": "Designer QR",
        "qrPreview": instance.qrPreview,
        "data": instance.qrImageFormData,
        "owner": { "dimensions": ""},
      }
      const res = await this.httpClient.post(this.url, data);
      console.log(res)
      return res
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async delete(uuid) {

    try {
      const url = `${this.url}/${uuid}`;
      const res = await this.httpClient.destroy(url);
      console.log("delete response in the try block ", res)
      return res
    } catch (e) {
      console.error("Failed to delete design", e.message)
      return null;
    }
  }

  async fetchAll(queryParams) {
    try {
      //only if its not a group query add isExclusivelyGroupOwned
      queryParams = Object.assign({
        ...queryParams,
        level: 'global'
      },
        lodash.isEmpty(queryParams.groupUuid) && {isExclusivelyGroupOwned: false}
      )
      const res = await this.httpClient.get(this.url, queryParams);
      console.log("response in get request", res)
      if (res && res.results) {
        return res
      }
    } catch (e) {
      console.error("Failed to get designs", e);
    }

    return null;
  }

  async fetchFilters (queryParams) {
    const url = '/sites/filters/imageConfigs'
    try {
      //only if its not a group query add isExclusivelyGroupOwned
      queryParams = Object.assign({
          ...queryParams,
          level: 'global'
        },
        lodash.isEmpty(queryParams.groupUuid) && {isExclusivelyGroupOwned: false}
      )
      const res = await this.httpClient.get(url, queryParams);
      console.log("response in get request", res)
      if (res) {
        return res
      }
    } catch (e) {
      console.error("Failed to get designs", e);
    }
    return null;
  }

  async update(instance) {
    let extendConfig = JSON.parse(instance.data)
    let isDefaultDesignerConfig = true
    if(extendConfig.isDefaultDesigner === false) {
      isDefaultDesignerConfig = extendConfig.isDefaultDesigner
    }
    setDefaultBackgroundColor(extendConfig)
    setEyesAndModules(extendConfig)
    const previewManager = new PreviewManager(this.designerHttpClient)
    const scanabilityCheckConfig = {
      qrImageFormData: JSON.stringify({...extendConfig}),
      shortUrl: ""
    }
    const scanabilityImage = await previewManager.create(scanabilityCheckConfig)
    if(scanabilityImage){  const scanabilityCheck = await scannabilityCheck(scanabilityImage?.data?.split("'")[1])}
    const formDataForIcon = JSON.stringify({...extendConfig ,size: 0.1, resolution: 300 })
    const config = {
      qrImageFormData: formDataForIcon,
      shortUrl: ""
    }
    const isSavingConfig = true
    const resp = await previewManager.create(config, isSavingConfig)
    try {
      let qrPreview
      // if the config is v1, we need to create a v1 qrPreview
      if(!isDefaultDesignerConfig) {
        qrPreview = `data:image/png;base64,` + resp.data.split("'")[1].split("=")[0]
      } else {
        if(JSON.parse(resp?.config?.data).imageFormat === "pdf" || JSON.parse(resp?.config?.data).imageFormat === "eps"){
          const pdfEpsConfig = config
          const pdfEpsQr = JSON.parse(pdfEpsConfig.qrImageFormData)
          pdfEpsQr.imageFormat = "png"
          pdfEpsConfig.qrImageFormData = JSON.stringify(pdfEpsQr)
          const pdfEpsPreview = await previewManager.create(pdfEpsConfig, isSavingConfig)
          qrPreview = `data:image/png;base64,` + pdfEpsPreview.data.split("'")[1].split("=")[0]
        } else {
          qrPreview = `data:image/${JSON.parse(resp.config.data).imageFormat};base64,` + resp.data.split("'")[1].split("=")[0]
        }
      }
      const data = {
        "name": instance.name ? instance.name : "You forgot to name me",
        "qrPreview": qrPreview,
        "data": JSON.stringify(extendConfig),
        "owner":{
          "groupUuid":instance.groupUuid,
          "dimensions": instance.dimensions
        },
        "isExclusivelyGroupOwned" : instance.isExclusivelyGroupOwned,
        "tags": instance.tags
      }
      const url = `${this.url}/${instance.uuid}`
      const res = await this.httpClient.put(url, data);
      if (res.data) {
        console.log("Successfully updated", res)
        return res
      }
    } catch (e) {
      // instance.handleHasErrors(true)
      // instance.handleErrorMessage(e.message)
      console.log("failed to update code: ", e.message);
    }
  }

  async updateApplyToCode(extendData,uuid) {
    let extendConfig = JSON.parse(extendData)
    setDefaultBackgroundColor(extendConfig)
    const previewManager = new PreviewManager(this.designerHttpClient)
    const scanabilityCheck = await generateImageCheckscannability(extendConfig)
    const formDataForIcon = JSON.stringify({...extendConfig ,size: 0.1, resolution: 300 })
    let isDefaultDesignerConfig = true
    if(extendConfig.isDefaultDesigner === false) {
      isDefaultDesignerConfig = extendConfig.isDefaultDesigner
    }
    const config = {
      qrImageFormData: formDataForIcon,
      shortUrl: ""
    }
    //const previewManager = new PreviewManager(this.designerHttpClient)
    const resp = await previewManager.create(config)
    try {
      let qrPreview;
      if(!isDefaultDesignerConfig) {
        qrPreview = `data:image/png;base64,` + resp.data.split("'")[1].split("=")[0]
      } else {
        qrPreview = `data:image/${JSON.parse(resp.config.data).imageFormat};base64,` + resp.data.split("'")[1].split("=")[0]
      }
      const data = {
        "level":"qr",
        "name": "Designer QR",
        "qrPreview": qrPreview,
        "data": JSON.stringify(extendConfig),
        "owner": {"dimensions": "" },
      }
      const url = `${this.url}/${uuid}`
      const res = await this.httpClient.put(url, data);
      if (res.data) {
        console.log("Successfully updated", res)
        return res
      }
    } catch (e) {
      // instance.handleHasErrors(true)
      // instance.handleErrorMessage(e.message)
      console.log("failed to update code: ", e.message);
    }
  }


  async fetchWithId(uuid) {
    const payload = async () => {
      try {
        const res = await this.httpClient.get(this.url + "/" + uuid);
        if (res?.qrPreview) {
          const data = {image: res.qrPreview, label: res.name, isExclusivelyGroupOwned: res?.isExclusivelyGroupOwned,
            name: res.name, qrConfig: res.data, uuid: res.uuid, owner:res.owner, level:(res.level||""), tags:res.tags}
          return data
        }
      } catch (e) {
        console.error("Failed to get design", e);
      }

    }
    return await payload()
  }
}

export default ImageConfigManager;
