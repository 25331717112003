import axios from "axios";

class AwsHttpClient {
	async put(url, data, config = {}) {
		try {
			const response = await axios.put(url, data, {});
			return response.data;
		} catch (e) {
			// console.log("e",e);
			// console.log("e.response.data",e.response.data);
			// console.log("e.response.status",e.response.status);
			// console.log("e.response.headers",e.response.headers);
			throw new Error(`${e.response.status} status. Current error message comes back as a html doctype`);
		}
	}
}

export default AwsHttpClient;
