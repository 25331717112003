import {getEnv, types} from "mobx-state-tree";
import {TableColumn} from "../../tables/ScanbuyTable";
import {DesignsList} from "../../../models/DesignsList";
import {ModalState} from "../../elements/modal/GenericModal";
import {GenericToastState} from "../../elements/toast/GenericToastState";
import {GroupSelectState} from "../../elements/groups/GroupSelectState";
import {CodeOwnerFilterSelectState} from "../../elements/filters/CodeOwnerFilterSelectState";
import lodash from "lodash";
import {TagSelectState} from "../../elements/tags/TagSelectState";
import {TagModel as Tag} from "../../../models/tags/TagModel";
import {formConstants} from "../../../utils/constants";
import {AdvancedConfFilterSelectState} from "../../elements/filters/AdvancedConfFilterSelectState";
import {flow} from "mobx";
import {DimensionSelectViewState} from "../../elements/dimensions/dimensionSelectViewState";
import {TagFilterSelectState} from "../../elements/filters/TagFilterSelectState";
import {GroupFilterSelectState} from "../../elements/filters/GroupFilterSelectState";

export const DesignsListState = types.model('DesignsListState',{
  store: types.late(() => DesignsList),
  columns: types.maybe(types.array(types.late(() => TableColumn))),
  modalStore: types.maybe(ModalState),
  toastStore: types.maybe(GenericToastState),
  groupSelectStore: types.maybe(types.reference(GroupSelectState)),
  codeOwnerFilterSelectState: types.maybeNull(types.reference(CodeOwnerFilterSelectState)),
  advFilterSelectState: types.maybeNull(types.reference(AdvancedConfFilterSelectState)),
  tagFilterSelectState: types.maybeNull(types.reference(TagFilterSelectState)),
  groupFilterSelectState: types.maybeNull(types.reference(GroupFilterSelectState)),
  dimensionSelectViewState: types.maybeNull(types.reference(DimensionSelectViewState)),
  tagSelectStore: types.maybe(types.reference(TagSelectState)),
  selectedTags: types.optional(types.array(types.reference(Tag, {acceptsUndefined: true})), []),
  isFilterTypeOR: types.optional(types.boolean, true),
  isFilterVisible: types.optional(types.boolean, true),
  filterGroup: types.optional(types.boolean, true)
}).volatile((self)=>({
  modalComponent: types.object,
  modalComponentStore: types.object,
  toastComponentStore: types.object,
})).actions((self) => ({
  handleRowClick(row, componentStore, component) {
    self.modalComponent = component;
    self.modalComponentStore = componentStore
    self.modalStore.handleTitle(componentStore.title)
    self.modalStore.toggle()
  },
  toggleFilterVisible() {
    self.isFilterVisible = !self.isFilterVisible
  },
  setOnSelectChanges() {
    self.groupSelectStore.setMultiSelectConsumer(self.selectGroup)
    // self.codeOwnerFilterSelectState?.setConsumer(self.selectFilter)
    self.tagSelectStore?.setConsumer(self.selectTag)
    self.advFilterSelectState?.setConsumer(self.selectFilter)
    self.dimensionSelectViewState?.setFilterConsumer(self.selectFilter)
    self.tagFilterSelectState?.setConsumer(self.selectFilter)
    self.groupFilterSelectState?.setConsumer(self.selectFilter)
  },
  selectTag(obj) {
    obj = obj || []
    self.store.addSearchParams({tags: obj.flatMap((row) => row.uuid).join(",")})
    self.refresh()
  },
  onSearchChange(txt){
    console.log(txt?.currentTarget?.value);
    const searchText = txt?.currentTarget?.value;
    if(searchText){
      if(searchText.length > 2) {
        self.store.removeSearchParams(["q"]);
        self.store.addSearchParams({ q: searchText })
        self.refresh()
      } else {
        self.store.removeSearchParams(["q"])
      }
    } else {
      self.store.removeSearchParams(["q"]);
      self.refresh()
    }
  },
  selectFilterMethod() {
    if (!self.isFilterTypeOR) {
      self.isFilterTypeOR = !self.isFilterTypeOR
      self.store.addSearchParams({tagFilterType: formConstants.Mode.OR})
    } else {
      self.isFilterTypeOR = !self.isFilterTypeOR
      self.store.addSearchParams({tagFilterType: formConstants.Mode.AND})
    }
    self.refresh()
  },
  selectFilter(obj, covered){

    const shouldRemoveDimensions = covered.includes("dimensionUuid")
    const regex = shouldRemoveDimensions ? /^dimension/ : undefined;
    self.store.removeSearchParams(covered, regex);

    if(obj){
      const transpiledDimensions = {};
      if(!lodash.isEmpty(obj.dimensions)){
        obj.dimensions.forEach(dimension => {
          const [uuid, value] = dimension.split('_');
          if (transpiledDimensions.hasOwnProperty(`dimension[${uuid}]`)) {
            transpiledDimensions[`dimension[${uuid}]`] += ',' + value;
          } else {
            transpiledDimensions[`dimension[${uuid}]`] = value;
          }
        });
        delete obj.dimensions
      }

      const params = lodash.mapValues(obj,(value, key) => {
        return {}[key] = value.join(',');
      });
      self.store.addSearchParams({...params,...transpiledDimensions})
      self.refresh()
    }
    const prevGroupUuid = self.prevGroupUuid || [];
    const currentGroupUuid = obj.groupUuid || [];
    if (!lodash.isEqual(prevGroupUuid, currentGroupUuid)) {
      self.filterGroup = false
      self.prevGroupUuid = currentGroupUuid;
    }
    self.getFilters();
  },
  selectGroup(obj) {
    obj = obj || []
    self.store.addSearchParams({groupUuid: obj.flatMap((row) => row.uuid).join(",")})
    self.refresh()
  },
  refresh() {
    self.store.fetchAll()
  },
  getFilters() {
    self.store.fetchFilters();
  }
}))

