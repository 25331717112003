export const formConstants = {
  Mode: {
    CREATE: "Create",
    EDIT: "Edit/View",
    VIEW: "VIEW",
    DELETE: "Delete",
    OR: "OR",
    AND: "AND"
  }
};

export const codeTypes = {
  URL: "url",
  GS1: "gs1",
  APP: "appLink",
  CALL: "tel",
  EMAIL: 'mailto',
  EVENT: 'vevent',
  MINILANDINGPAGE: 'html5',
  MENULITE: "menulite",
  NOTE: "note",
  SMS: "sms",
  MOBILEWALLET: "passpage",
  SKYPE: "skype",
  VCARD: "vcard",
  WHATSAPP: "wa",
  WIFI: "wifi",
  ZOOM: 'zoom',
  SocialMedia: "socialMedia",
}
export const codeBehavior = {
  STATIC: "STATIC",
  DYNAMIC: "DYNAMIC"
}

export const codeTypesWithBehavior = {
  URL: codeTypes.URL + "|" + codeBehavior.DYNAMIC,
  APP: codeTypes.APP + "|" + codeBehavior.DYNAMIC,
  CALL: codeTypes.CALL + "|" + codeBehavior.DYNAMIC,
  EMAIL: codeTypes.EMAIL + "|" + codeBehavior.DYNAMIC,
  EVENT: codeTypes.EVENT + "|" + codeBehavior.DYNAMIC,
  MINILANDINGPAGE: codeTypes.MINILANDINGPAGE + "|" + codeBehavior.DYNAMIC,
  NOTE: codeTypes.NOTE + "|" + codeBehavior.DYNAMIC,
  SMS: codeTypes.SMS + "|" + codeBehavior.DYNAMIC,
  MOBILEWALLET: codeTypes.MOBILEWALLET + "|" + codeBehavior.DYNAMIC,
  SKYPE: codeTypes.SKYPE + "|" + codeBehavior.DYNAMIC,
  VCARD: codeTypes.VCARD + "|" + codeBehavior.DYNAMIC,
  WHATSAPP: codeTypes.WHATSAPP + "|" + codeBehavior.DYNAMIC,
  WIFI: codeTypes.WIFI + "|" + codeBehavior.DYNAMIC,
  ZOOM: codeTypes.ZOOM + "|" + codeBehavior.DYNAMIC,
  SocialMedia: codeTypes.SocialMedia + "|" + codeBehavior.DYNAMIC,
  MENULITE: codeTypes.MENULITE + "|" + codeBehavior.DYNAMIC,
}
