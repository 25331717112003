import { RouterState, RouterStore } from "mobx-state-router";
import { BatchList } from "../../models/batch/BatchList";
import BatchFormState from "../../components/codes/batches/BatchFormState";
import BatchListState from "../../components/codes/batches/BatchListState";
import { buildColumns as buildBatchColumns } from "../../pages/codes/batches/List";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import async from "../../components/Async";
import React from "react";
import {BatchFilterSelectState} from "../../components/elements/filters/BatchFilterSelectState";
import BatchItemListState from "src/components/codes/batches/items/BatchItemListState";
import {BatchItemList} from "../../models/batch/BatchItemList";
import { buildColumns as buildBatchItemsColumns } from "../../pages/codes/batches/items/BatchItemsList";
const BatchesPage = async(() => import("../../pages/codes/batches/List"));
const BatchItemsPage = async(() => import("../../pages/codes/batches/items/BatchItemsList"));

export const viewMap = {
  batches: <BatchesPage/>,
  batchItems: <BatchItemsPage/>
}

export const routeDefinitions = [
  {
    path: "batches",
    name: "Batches",
    icon: faMailBulk,
    component: BatchesPage,
    children: null,
    featureFlagRequired: "cmp_ui_batches"
  }
]

export const routeTransitions = [
  {
    name: 'batches',
    pattern: '/batches',
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
      const groupSelectState = rootStore.rootUiStore.groupSelectStore;
      const tagSelectStore = rootStore.rootUiStore.tagSelectStore;
      const batchFilterSelectState = rootStore.rootUiStore.batchFilterSelectState
      tagSelectStore?.tagStore?.init(); //reset tags
      tagSelectStore?.setSelectedTags([]);
      batchFilterSelectState.reset()
      // const batchStore = BatchStore.create({cost: 'hi'})
      const batchList = BatchList.create({})
      const batchFormState = BatchFormState.create({
        title: "Create Batch",
        tagSelectStore: tagSelectStore,
        groupSelectStore: groupSelectState,
      })

      const viewState = BatchListState.create({
        store: batchList,
        columns: buildBatchColumns(routerStore, batchList),
        groupSelectStore: groupSelectState,
        batchFormState: batchFormState,
        batchFilterSelectState: batchFilterSelectState
      });
      
      console.log("Attaching Page State : batchListViewState ")
      await rootStore.rootUiStore.attachPageState('batchListViewState', viewState)
    },
    onExit: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
      //setTimeout(()=>{rootStore.rootUiStore.detachPageState('batchListViewState')},100)

    }
  },
  {
    name: 'batchItems',
    pattern: '/batches/:batchUuid/items',
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
      const batchItems = BatchItemList.create({
        batchUuid: toState.params.batchUuid
      })
      const batchItemListState = BatchItemListState.create({
        store: batchItems,
        columns: buildBatchItemsColumns(routerStore)
      })
      console.log("Attaching Page State : batchItemListViewState ")
      await rootStore.rootUiStore.attachPageState('batchItemListViewState', batchItemListState)

    },
    onExit: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;


    }
  }
];
