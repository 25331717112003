import { types } from "mobx-state-tree";
import GroupStore from "./domain/GroupStore";
import CodeTypeStore from "./domain/CodeTypeStore";
import { QrConfigStore} from "./domain/QrConfigStore";
import PublishDomainStore from "./domain/PublishDomainStore";
import TagStore from './domain/TagStore';
import TenantStore from './domain/TenantStore';
import {PageTemplateStore} from "./domain/PageTemplateStore";
import RuleTypeStore from "./domain/RuleTypeStore";
import UtmStore from "./domain/UtmStore";
import {RuleSetsStore} from "./domain/RuleSetsStore";
import {DashboardStore} from "./domain/DashboardStore";
import DimensionStore from "./domain/DimensionStore";
import QrTemplateStore from "./domain/QrTemplateStore";
import ParameterSetStore from "./domain/ParameterSetStore";
import UserStore from "./domain/UserStore";
import DataExportTypeStore from "./domain/DataExportTypeStore";
import ExtendedDataStore from "./domain/ExtendedDataStore";
import ExtendedDataTypeStore from "./domain/ExtendedDataTypeStore";
import ExtendedDataSetStore from "./domain/ExtendedDataSetStore";
/*--YEOMAN-ADD-IMPORT--*/

const RootDomainStore = types.model({
  groupStore: types.maybeNull(GroupStore),
  codeTypeStore: types.maybeNull(CodeTypeStore),
  dataExportTypeStore: types.maybeNull(DataExportTypeStore),
  qrConfigStore: types.maybeNull(QrConfigStore),
  publishDomainStore: types.maybeNull(PublishDomainStore),
  tagStore: types.maybeNull(TagStore),
  tenantStore: types.maybeNull(TenantStore),
  pageTemplateStore : types.maybeNull(PageTemplateStore),
  ruleTypeStore: types.maybeNull(RuleTypeStore),
  utmStore:types.maybeNull(UtmStore),
  extendedDataStore: types.maybeNull(ExtendedDataStore),
  extendedDataSetStore: types.maybeNull(ExtendedDataSetStore),
  extendedDataTypeStore: types.maybeNull(ExtendedDataTypeStore),
  ruleSetStore:types.maybeNull(RuleSetsStore),
  dashboardStore: types.maybeNull(DashboardStore),
  dimensionStore: types.maybeNull(DimensionStore),
  qrtemplateStore:types.maybeNull(QrTemplateStore),
  parameterSetStore: types.maybeNull(ParameterSetStore),
  userStore: types.maybeNull(UserStore),

  /*--YEOMAN-ADD-STATE--*/

  // ,tagStore

  // ,ruleStore
  // ,attributeStore
  // ,domainStore
  // ,prefixStore
  // ,appLinkStore
  // ,
}).actions((self)=>({
  //store['name'] ->  self['name'] ... or load

  afterCreate(){

  }
}))

export default RootDomainStore


