import React, {useState, useEffect} from 'react'
import {Row, Col } from 'reactstrap';
import {observer} from "mobx-react-lite";

const Slider = observer(({defaultValue, minValue, maxValue, handleSliderChange}) => {
    // const [value, setValue] = useState(defaultValue);
    // useEffect(() => {
    //   setValue(defaultValue);
    // }, [defaultValue]);
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', width:'80%' }}>

                <div style={{
                    textAlign: 'left',

                }}>Size:</div>
                <input
                type="range"
                min={minValue}
                max={maxValue}
                value={defaultValue}
                onChange={(event) => handleSliderChange(event)}
                style={{ width: '100%', background: '#0093DB' }}
                />

                <div style={{
                    textAlign: 'left',
                    fontSize: '18px',
                }}>{defaultValue}</div>


            </div>
      </>
    );
  });

  export default Slider;
