import {types} from "mobx-state-tree";


const  DashboardModel = types.model('DashboardModel',{
  bulletins: types.array(types.frozen()),
  experiences: types.array(types.frozen()),
  bulletinText: types.optional(types.string,'')
})

export default DashboardModel;
