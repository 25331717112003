import {flow, getEnv, resolveIdentifier, types} from "mobx-state-tree";
import HttpClient from "../../../services/HttpClient";
import PreviewManager from "../../../managers/preview/PreviewManager";
import {QrConfigSelectState} from "../../elements/qrconfig/QrConfigSelectState";
import QrConfiguration from "../../../models/QrConfiguration";
import {ModalState} from "../../elements/modal/GenericModal";
import {saveAs} from "file-saver";
import {setDefaultBackgroundColor, setEyesAndModules} from "../../../utils/common";
import {scannabilityCheck} from "../../../utils/scannability";
import {QrDownloadState} from "./qrDownload/QrDownloadState";

const QrPreviewState = types.model('QrPreviewState', {
  title:types.optional(types.string, ""),
  isLoading: types.optional(types.boolean, false),
  testMode: types.optional(types.boolean, false),
  preSaveMode: types.optional(types.boolean, true),
  previewImage: types.optional(types.string, ""),
  imgFormat: types.optional(types.string, "png"),
  shortUrl: types.optional(types.string, 'Th1s 1s @ s@mpl3 QR v@lu3'),
  experienceData: types.optional(types.frozen(),{}),
  qrType: types.maybe(types.string),
  qrConfigSelectStore: types.maybe(types.late(() => types.reference(QrConfigSelectState))),
  qrConfigSelected: types.maybe(types.reference(QrConfiguration)),
  baseQRImageFormData: types.optional(types.string, ''),
  qrImageFormData: types.optional(types.string, ''),
  modalStore: types.maybe(ModalState),
  ableToEditConfig: types.optional(types.boolean, true),
  epsPdfDownloadFile: types.optional(types.string, ''),
  isEditCodePage: types.optional(types.boolean, false),
  validQr: types.optional(types.boolean, true),
  qrDownloadStore: types.maybeNull(QrDownloadState)
})
  .volatile((self) => ({
    modalComponent: types.object,
    modalComponentStore: types.object,
    toastComponentStore: types.object
  }))
  .actions((self) => ({
    updateImage(shortUrl) {
      console.log("QrPreviewState updateImage:", shortUrl)
      self.shortUrl = shortUrl
      self.setQRImageFormData(self.qrImageFormData ? self.qrImageFormData : self.baseQRImageFormData)
    },
    setShortUrl(shortUrl) {
      self.shortUrl = shortUrl
    },
    setPreviewImageNull() {
      self.previewImage = ""
    },
    updateName(title) {
      self.title = title;
    },
    selectQRConfig(obj) {
      // self.qrConfigSelected = obj; // does this need to be here?
      // self.qrConfigSelectStore.selectedConfig = obj; // does this need to be here?
      self.setQRImageFormData(obj.qrConfig)
      const extend = JSON.parse(obj.qrConfig)
      // change download options
      self.handleDownload(extend.colorMode, extend.transparentBackground)
      // change default download option to imageFormat
      self.imgFormat = self.qrDownloadStore.defaultImageFormat.value
    },
    handleOpenEditModal(componentStore, component) {
      self.modalComponentStore = componentStore
      self.modalComponent = component;
      self.modalStore.toggle()
    },
    setQRImageFormData(data) {
      if(data) {
        const extend = JSON.parse(data)
        // set default background color to white
        setDefaultBackgroundColor(extend)
        self.qrImageFormData = JSON.stringify({ ...extend,"imageFormat": self.imgFormat})
        self.generateImage()
        // self.handleDownload(extend.colorMode, extend.transparentBackground)
      }
    },
    setOnSelectChanges() {
      self.qrConfigSelectStore.setConsumer(self.selectQRConfig)
    },
    handleImgFormat(e) {
      self.imgFormat = e.value
      self.qrDownloadStore.handleDownloadImageFormat(e)
      const extend = JSON.parse(self.qrConfigSelectStore.selectedConfig?.qrConfig ? self.qrConfigSelectStore?.selectedConfig?.qrConfig : "{}")
      self.setQRImageFormData(JSON.stringify({...extend,"imageFormat": self.imgFormat}))
    },
    handleDownload(colorMode, transparentBackground) {
      // base designer template color mode, filter out png selection if cmyk 
      //const colorMode = colorMode
      self.qrDownloadStore.handleDefaultImageFormat(colorMode, transparentBackground)
      self.qrDownloadStore.handleOptions(colorMode, transparentBackground)
      //self.qrDownloadStore.handleDownloadImageFormat(colorMode, transparentBackground)
      
    },
    copyUrl(){
      navigator.clipboard.writeText(self.shortUrl).then(() => {alert("'"+self.shortUrl+ "' was copied to your clipboard.")});
    },
    downloadConfig(){
      console.log("downloadConfig", self.imgFormat, self.epsPdfDownloadFile)
      let filename = self.title;
      let fileNameWithUnderScore = filename.replaceAll(" ", "_").replaceAll(".", "_");
      let url = self.previewImage;
      if (self.imgFormat === "eps" || self.imgFormat === "pdf") {
        url = self.epsPdfDownloadFile
      }
      if(self.imgFormat === "eps") {
        fileNameWithUnderScore = fileNameWithUnderScore + ".eps"
      }
      if(self.imgFormat === "jpg") {
        fileNameWithUnderScore = fileNameWithUnderScore + ".jpeg"
      }
      saveAs(url, fileNameWithUnderScore);
    },
    generateImage: flow(function* f() { // <= for now shortUrl but we need configParams = {}
      self.isLoading = true;
      const designerHttpClient = new HttpClient("https://nigiu8dgm4.execute-api.us-east-2.amazonaws.com/dev");
      const previewManager = new PreviewManager(designerHttpClient);
      let epsPdfFormat;
      let fileSuffix = "image"
      // if imgFormat is eps or pdf, we need to create a png preview first

      if(self.imgFormat === "eps" || self.imgFormat === "pdf"){
        epsPdfFormat = self.imgFormat;
        if(self.imgFormat === "pdf"){
          fileSuffix = "application"
        }
        const epsPdfpreview = yield previewManager.create(self)
        self.epsPdfDownloadFile = `data:${fileSuffix}/${self.imgFormat};base64,` + epsPdfpreview.data.split("'")[1].split("=")[0]
        self.imgFormat = "png"
        // self.setQRImageFormData(JSON.stringify({"imageFormat": self.imgFormat}))
        const extend = JSON.parse(self.qrConfigSelectStore.selectedConfig?.qrConfig ? self.qrConfigSelectStore.selectedConfig.qrConfig :  "{}")
        self.setQRImageFormData(JSON.stringify({...extend,"imageFormat": self.imgFormat}))
      }
      // self.setQRImageFormData(JSON.stringify({"imageFormat": self.imgFormat}))
      const resp = yield previewManager.create(self, false)
      console.log("generateImage resp:", resp)
      self.isLoading = false
      if (resp?.status === 201) {
        const scanabilityCheck = yield scannabilityCheck(resp.data.split("'")[1])
        self.handleValidQr(scanabilityCheck)
        // let fileSuffix = "image"
        //self.imgFormat = "svg"
        if(self.imgFormat === "svg"){
          self.imgFormat = "svg+xml"
        }
        self.previewImage = `data:${fileSuffix}/${self.imgFormat};base64,` + resp.data.split("'")[1].split("=")[0]
        if(epsPdfFormat === "eps" || epsPdfFormat === "pdf"){
          self.imgFormat = epsPdfFormat;
          // self.setQRImageFormData(JSON.stringify({"imageFormat": epsPdfFormat}));
        }
      }
    }),
    fetchQRConfigs: flow(function* f(sortParams = {}){
      self.isLoading = true

      try {
        const queryParams = {
          page: 1,
          perPage: 50,
          ...sortParams,

        }
        const imageConfigManager = getEnv(self).imageConfigManager
        const response = yield imageConfigManager.fetchAll(queryParams);
        if(response !=null) {
          const qrConfigStore = response.results.map((el) => {
            return {
              "uuid": el.uuid,
              "name": el.name,
              "label": el.name,
              "image": el.qrPreview,
              "qrConfig": el.data
            }
          })
          self.qrConfigSelectStore.configStore.setConfigStore(qrConfigStore)
          self.isLoading = false;
        } else {
          self.isLoading = false;
        }
      } catch (error) {
        console.error('Failed to fetch all', error)
      }
    }),
    handleValidQr(validQr){
      self.validQr = validQr
    }
  }))


export default QrPreviewState;


