import React, {useContext} from "react";
import {observer} from "mobx-react-lite";
import staticIcon from "../../../assets/img/alt/static_code.png";
import lodash from 'lodash';

import {
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  Button,
  DropdownItem,
  Badge,
  UncontrolledTooltip,
  UncontrolledButtonDropdown,
  DropdownToggle, DropdownMenu, CustomInput
} from "reactstrap";

//Our Custom Components
import ScanbuyTable, {TableColumn} from "../../tables/ScanbuyTable";
import TagSelect from "../../elements/tags/TagSelect";
import QrTemplateSelect from "../../elements/qrtemplates/QrTemplateSelect";
import CodeFilterSelect from "../../elements/filters/CodeFilterSelect";
import {RouterLink, useRouterStore} from "mobx-state-router";
import TableActionsDropdown from "../../tables/TableActionsDropdown";
import * as Icon from "react-feather";
import GenericModal from "../../elements/modal/GenericModal";
import PublishView from "../publish/PublishView";
import PublishState from "../publish/PublishState";
import DeleteState from "../delete/DeleteState";
import DeleteView from "../delete/DeleteView";
import dayjs from 'dayjs';
import GenericToastView from "../../elements/toast/GenericToastView";
import QrPreviewState from "../../qr/qrpreview/QrPreviewState";
import {StateContext} from "../../../App";
import {useIntl, FormattedMessage} from "react-intl";
import {getSnapshot} from "mobx-state-tree";
import "./CodeList.css"

import {CodeModel} from "../../../models/codes/CodeModel";
import CodeOwnerFilterSelect from "../../elements/filters/CodeOwnerFilterSelect";
import PreviewCodeAndEditDesign from "../view/PreviewCodeAndEditDesign";
import {PreviewCodeAndEditDesignState} from "../view/PreviewCodeAndEditDesignState";
import DesignerState from "../../qr/designer/DesignerState";
import ColorState from "../../qr/designer/color/ColorState";
import EyesAndModulesState from "../../qr/designer/eyesAndModules/EyesAndModulesState";
import LogoState from "../../qr/designer/logo/LogoState";
import ImageAndCharacteristicsState from "../../qr/designer/imageAndCharacteristics/ImageAndCharacteristicsState";
import FrameAndCaptionsState from "../../qr/designer/frameAndCaption/FrameAndCaptionsState";
import {v4 as uuidv4} from 'uuid';
import CaptionState from "../../qr/designer/frameAndCaption/CaptionState";
import CreateQrFromTemplateState from '../../qrTemplates/CreateQrFromTemplateState'
import CreateQrFromTemplateView from '../../qrTemplates/CreateQrFromTemplateView'
import {codeBehavior, formConstants} from "../../../utils/constants";
import {createdByColumn} from "../../tables/TableColumns"
import {buildDimensionFilters} from "../../../utils/buildDimensionFilters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleDoubleDown, faAngleDoubleUp} from "@fortawesome/free-solid-svg-icons";
import GroupSelect from "../../elements/groups/GroupSelect";
import TagFilterSelect from "../../elements/filters/TagFilterSelect";
import {QrDownloadState} from "../../qr/qrpreview/qrDownload/QrDownloadState";
import GenericModal2 from "../../elements/modal2/GenericModal2";
import GroupFilterSelect from "../../elements/filters/GroupFilterSelect";
import ExportButton from "../../data/exports/button/ExportButton";

export const buildColumns = (routerStore, codeListViewState) => {
  const {rootStore} = routerStore.options;

  const codeTypeStore = rootStore.domainStore.codeTypeStore;
  const codeTypes = codeTypeStore.codeTypes;

  const typeMap = lodash.keyBy(codeTypes, "name")

  console.log(typeMap);
  const staticImg = <img style={{width:"15px",marginLeft:"10px"}} alt="static icon" src={staticIcon}/>
  const c1 = TableColumn.create({
    name: 'Code Name', property: 'name', width: '20%', sortable: true, sortField: 'name',
  })
  c1.setSelector((row)=> {
  return(
    <>
    <span id={`tooltip-${row.uuid}`}>{row.name} {row.behavior === codeBehavior.STATIC && staticImg}</span>
    <UncontrolledTooltip target={`tooltip-${row.uuid}`} placement="top">{row.name}</UncontrolledTooltip>
    </>
  )})

  const c2 = TableColumn.create({
    name: 'Status', property: 'status', sortable: true, sortField: 'status', selector: 'status', width: '100px',
  })
  c2.setSelector((row) => {
    return row.status
  })

  const c3 = TableColumn.create({
    name: 'Code Experience', property: 'type', width: '120px',
  });
  c3.setSelector((row) => {
    let label = row.type;
    if (typeMap[row.type]) {
      label = typeMap[row.type].label || row.type;
    }

    return label;
  });

  const c4 = TableColumn.create({
    name: 'Create Date', property: 'created', width: '15%', hide: 'sm', sortable: true, sortField: 'createdAt',
  });
  c4.setSelector((row) => {
    // console.log(row)
    const {createdAt} = row || {};
    const formattedCreateDate = dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss');
    return formattedCreateDate;
  });

  const updatedColumn = TableColumn.create({
    name: 'Last Modified', property: 'updated', width: '15%', hide: 'sm', sortable: true, sortField: 'updatedAt',
  });
  updatedColumn.setSelector((row) => {
    // console.log(row)
    const {updatedAt} = row || {};
    const formattedUpdateDate = dayjs(updatedAt).format('YYYY-MM-DD HH:mm:ss');
    return formattedUpdateDate;
  });

  const c5 = TableColumn.create({
    name: 'Tag(s)', property: 'tags', hide: 'sm'
  });
  c5.setSelector((row) => {
    return row.tags;
  });
  c5.setCell((row) => {
    const tags = row.tags.flatMap((t) => {
      return {uuid: t.uuid, name: t.name, color: t.uiStyleConfigs?.color || 'info'}
    });
    return (<div>
      {tags.map((tag, index) => (<Badge
        key={index}
        color="info"
        className="badge-pill mr-1 mb-1"
      >
        {tag.name}
      </Badge>))}
    </div>)
  })

  const createdBy = createdByColumn();

  const actionCol = TableColumn.create({
    name: 'Action', property: 'action', allowOverflow: true, button: true, width: '100px',
  })

  actionCol.setCell((row) => (<TableActionsDropdown
    actionsList={[
      <DropdownItem id={"action-btn-view"+row} key={'view' + row.uuid} value={'View ' + row.uuid}
                    onClick={async () => {
                      const qrConfigSelectStore = rootStore.rootUiStore.qrConfigSelectStore
                      const qrConfigStore = rootStore.rootUiStore.qrConfigStore
                      codeListViewState.pageTemplateSelectStore.clearSelectedConfig()
                      codeListViewState.pageTemplateSelectStore.setStyles(row?.dataDetail?.styles)
                      const ColorStore = ColorState.create({})
                      const EyesAndModulesStore = EyesAndModulesState.create({})
                      const LogoStore = LogoState.create({})
                      const ImageCharsStore = ImageAndCharacteristicsState.create({})
                      const frameAndCaptionsStore = FrameAndCaptionsState.create({
                        captionRows: [CaptionState.create({ id: uuidv4(), captionTextPosition: 'Top', captionFontSize: 50, captionContent: '', captionColor: '#000000' }) ],
                        fillInColor: false,
                      })
                      const designerViewState = DesignerState.create({
                        colorStore: ColorStore,
                        eyeStore: EyesAndModulesStore,
                        logoStore: LogoStore,
                        imageCharsStore: ImageCharsStore,
                        frameAndCaptionsStore:frameAndCaptionsStore,
                        groupSelectStore:rootStore.rootUiStore.groupSelectStore,
                      })
                      const qrPreviewStore = QrPreviewState.create({
                        title: row.name,
                        shortUrl: row.behavior === codeBehavior.STATIC? row.shortUrl.uri:row.shortUrl.domain + "/" + row.shortUrl.uri,
                        ableToEditConfig: false,
                        qrConfigStore: qrConfigStore,
                        qrConfigSelectStore: qrConfigSelectStore,
                        qrType: row?.type,
                        experienceData: row?.data,
                        qrDownloadStore: QrDownloadState.create({})
                      });
                      const previewCodeAndEditDesignState = PreviewCodeAndEditDesignState.create({
                        codeUuid: row.uuid,
                        groupUuid: row.owner.groupUuid,
                        // row:row
                      })
                      rootStore.rootUiStore.attachPageState('qrPreviewStore', qrPreviewStore)
                      rootStore.rootUiStore.attachPageState('designerViewState', designerViewState)
                      rootStore.rootUiStore.attachPageState('previewCodeAndEditDesignState', previewCodeAndEditDesignState)
                      previewCodeAndEditDesignState.setCodeListModalSizeListener(codeListViewState.setEditModalMode)
                      await qrConfigSelectStore.setSelectedGroup(row.owner.groupUuid)
                      qrConfigSelectStore.configStore.removeImageConfig();
                      const imageUuid = row?.images?.configs[0]?.uuid
                      if (imageUuid) {
                        const res = await qrConfigSelectStore.configStore.fetchImageConfigWithId(imageUuid)
                        qrConfigSelectStore?.setHydrateModal(designerViewState?.hydrateEditComponent)
                        qrPreviewStore?.setQRImageFormData(res?.qrConfig)
                        qrConfigSelectStore.setSelectedConfig(res);
                      } else {
                        qrPreviewStore.generateImage()
                        qrConfigSelectStore.setSelectedConfig("")
                      }
                      // const res =  await qrConfigSelectStore.configStore.fetchImageConfigWithId(row?.images?.configs[0]?.uuid)
                      // qrPreviewStore?.setQRImageFormData(res?.qrConfig)
                      // qrConfigSelectStore.setSelectedConfig(res);
                      codeListViewState.handleRowClick(PreviewCodeAndEditDesign,row.name ,previewCodeAndEditDesignState,"xl","preview-experience",row);


                    }}><Icon.Search/>&nbsp;<FormattedMessage id="listCodes.actions.dropdown.view.defaultMessage"/>
      </DropdownItem>,
      <DropdownItem id="action-btn-edit" key={'edit/' + row.uuid} onClick={() => {
        routerStore.goTo('editCode', {
          params: {id: row.uuid},
        });
      }}><Icon.Edit/>&nbsp;<FormattedMessage id="listCodes.actions.dropdown.edit.defaultMessage"/>
      </DropdownItem>,
      <DropdownItem id="action-btn-clone" key={'new'} onClick={() => {
        const snapshot = getSnapshot(row);
        rootStore.rootUiStore.addCloneToCache(`clone:${row.uuid}`,snapshot);
        routerStore.goTo('createCode',{
          queryParams: {q: row.uuid}
        })
      }}><Icon.Copy/>&nbsp;<FormattedMessage id="listCodes.actions.dropdown.clone.defaultMessage"/>
      </DropdownItem>,
      ////////////////////////////////// download is in view action ////////////////////////////////////////////////////
      // <DropdownItem key={'download' + row.uuid} value={"Download " + row.uuid} className="align-text-bottom"
      //               onClick={() => {
      //                 const componentStore = QrDownloadState.create({title: "Download"});
      //                 codeListViewState.handleRowClick(row, componentStore, QrDownloadView);
      //               }}><Icon.Download/>&nbsp;Download
      // </DropdownItem>,
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      <DropdownItem id="action-btn-publish" key={'publish' + row.uuid} value={"Publish " + row.uuid} className="align-text-bottom"
      onClick={() => {
      const componentStore = PublishState.create(
    {
      codeListStore: codeListViewState,
      title: `Publish ${row.name ? row.name : ""}`,
      uuid: row.uuid
    }
      );
      componentStore.setPublishStateListener(codeListViewState.modalStore.toggle);
      componentStore.setSecondPublishStateListener(codeListViewState.publishRow);
      componentStore.setThirdPublishStateListener(codeListViewState.fetchRow);
      codeListViewState.handleRowClick(PublishView, `Publish ${row.name ? row.name : ""}`, componentStore);

      }}><Icon.Send/>&nbsp;<FormattedMessage id="listCodes.actions.dropdown.publish.defaultMessage"/>
      </DropdownItem>,

      <DropdownItem key={'divider' + row.uuid} divider/>,
      <DropdownItem id="action-btn-delete" key={row.uuid} value={"Delete " + row.uuid} className="align-text-bottom"
      onClick={() => {
      const componentStore = DeleteState.create(
    {
      codeListStore: codeListViewState,
      title: `Delete ${row.name ? row.name : ""}`,
      uuid: row.uuid
    });
      componentStore.setDeleteStateListener(codeListViewState.modalStore.toggle)
      componentStore.setSecondDeleteStateListener(codeListViewState.deleteRow)
      codeListViewState.setDeleteStateRef(componentStore);
      codeListViewState.handleRowClick( DeleteView,`Delete ${row.name ? row.name : ""}`,componentStore);

    }}> <Icon.Delete/>&nbsp;<FormattedMessage id="listCodes.actions.dropdown.delete.defaultMessage"/>
      </DropdownItem>]}/>))

  return [c1, c3, c2, c5, createdBy, c4, updatedColumn, actionCol];
}

/**
 *
 * @param codeListState - CodeListState
 */




const CodeListView = observer(({codeListState}) => {
  const routerStore = useRouterStore();
  const intl = useIntl();
  codeListState.setOnSelectChanges()
  const stateContext = useContext(StateContext);
  codeListState?.codeFilterSelectState.setEmails(codeListState?.store?.filters?.users);
  codeListState?.codeFilterSelectState.setCodeTypes(codeListState?.store?.filters?.types);
  codeListState?.codeFilterSelectState.setStatusTypes(codeListState?.store?.filters?.status)
  codeListState?.codeFilterSelectState.setDomains(codeListState?.store?.filters?.domains)
  codeListState?.codeFilterSelectState.setBehaviorTypes(codeListState?.store?.filters?.behaviors)
  codeListState?.tagFilterSelectState.setTags(codeListState?.store?.filters?.tags)
  codeListState?.filterGroup && codeListState?.groupFilterSelectState?.setGroups(codeListState?.store?.filters?.groups)
  codeListState?.dimensionSelectViewState?.setFilteredDimensions(codeListState?.store?.filters.dimensions);
  // const qrPreviewStore = stateContext.rootUiStore.qrPreviewStore

  const onRowClicked = async (row, event) => {
    console.log(row)
    //here

    const snap = getSnapshot(row); //disconnect from tree?

    row = CodeModel.create({...snap})
    const qrConfigSelectStore = stateContext.rootUiStore.qrConfigSelectStore
    const qrConfigStore = stateContext.domainStore.qrConfigStore
    if (row?.type === 'vcard') {
      codeListState.pageTemplateSelectStore.clearSelectedConfig()
      codeListState.pageTemplateSelectStore.setStyles(row?.dataDetail?.styles)
    }
    const frameAndCaptionsStore = FrameAndCaptionsState.create({
      captionRows: [CaptionState.create({ id: uuidv4(), captionTextPosition: 'Top', captionFontSize: 50, captionContent: '', captionColor: '#000000' }) ],
      fillInColor: false,
    })
    const designerViewState = DesignerState.create({
      colorStore: ColorState.create({}),
      eyeStore: EyesAndModulesState.create({}),
      logoStore: LogoState.create({}),
      imageCharsStore: ImageAndCharacteristicsState.create({}),
      frameAndCaptionsStore:frameAndCaptionsStore,
      groupSelectStore:stateContext.rootUiStore.groupSelectStore,
    })
    const qrPreviewStore = QrPreviewState.create({
      title: row.name,
      shortUrl: row.behavior === codeBehavior.STATIC ? row.shortUrl.uri:row.shortUrl.domain + "/" + row.shortUrl.uri,
      ableToEditConfig: false,
      qrConfigStore: qrConfigStore,
      qrConfigSelectStore: qrConfigSelectStore,
      qrType: row?.type,
      experienceData: row?.data,
      qrDownloadStore: QrDownloadState.create({})
    });
    const previewCodeAndEditDesignState = PreviewCodeAndEditDesignState.create({
      codeUuid: row.uuid,
      groupUuid: row.owner.groupUuid,
    })
    stateContext.rootUiStore.attachPageState('qrPreviewStore', qrPreviewStore)
    stateContext.rootUiStore.attachPageState('designerViewState', designerViewState)
    stateContext.rootUiStore.attachPageState('previewCodeAndEditDesignState', previewCodeAndEditDesignState)
    previewCodeAndEditDesignState.setCodeListModalSizeListener(codeListState.setEditModalMode)
    qrConfigSelectStore.setSelectedGroup(row.owner.groupUuid)
    qrConfigSelectStore.configStore.removeImageConfig();
    const imageUuid = row?.images?.configs[0]?.uuid
    if (imageUuid) {
      const res = await qrConfigStore.fetchImageConfigWithId(imageUuid)
      qrConfigSelectStore?.setHydrateModal(designerViewState?.hydrateEditComponent)
      qrPreviewStore?.setQRImageFormData(res?.qrConfig)
      qrConfigSelectStore.setSelectedConfig(res);
    } else {
      qrPreviewStore.generateImage()
      qrConfigSelectStore.setSelectedConfig("")
    }
    codeListState.updateExperienceData(row.data)
    codeListState.handleRowClick(PreviewCodeAndEditDesign,row.name ,previewCodeAndEditDesignState,"xl","preview-experience",row);
  }

  const handleItemClick = (item) => {
    const componentState = CreateQrFromTemplateState.create({
      title: item?.name,
      uuid: item?.uuid,
      formSchema: item?.inputSchema,
    });
    stateContext.rootUiStore.attachPageState('createQrFromTemplateState', componentState)
    codeListState.handleClick(componentState,CreateQrFromTemplateView);
  };

  const isDimensionsVisible = codeListState?.dimensionSelectViewState?.dimensionsEnabled && codeListState?.dimensionSelectViewState?.dimensionSelectStates?.length > 0;

  return (
  <>
      <Card>
        <CardBody>
          <Row>
            <Col className="text-right">
              {codeListState.isFilterVisible ? <Button id="codelist-dropdown-open" color="primary" onClick={()=> {codeListState.toggleFilterVisible()}}><FontAwesomeIcon icon={faAngleDoubleUp} /></Button>
                : <Button id="codelist-dropdown-closed" color="primary" onClick={()=> {codeListState.toggleFilterVisible()}}><FontAwesomeIcon icon={faAngleDoubleDown} /></Button>}
            </Col>
          </Row>
          {codeListState.isFilterVisible && <>
            <Row>
              <Col sm={5}>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    {intl.formatMessage({id: "filterByGroups.defaultMessage"})}
                  </InputGroupAddon>
                  {/*<CodeOwnerFilterSelect codeOwnerFilterSelectState={codeListState.codeOwnerFilterSelectState}/>*/}
                  {/*<GroupSelect groupSelectStore={codeListState.groupSelectStore} multi={true}/>*/}
                  <GroupFilterSelect groupFilterSelectState={codeListState.groupFilterSelectState}/>
                </InputGroup>
              </Col>
              <Col sm={5}>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    {intl.formatMessage({id: "listCodes.filter.filterName.defaultMessage"})}
                  </InputGroupAddon>
                  <CodeFilterSelect codeFilterSelectState={codeListState.codeFilterSelectState}/>
                </InputGroup>
              </Col>
              {!isDimensionsVisible && <ExportButton viewState={codeListState}/>}
            </Row>
            <Row style={{ alignItems: "flex-end" }}>
              <Col sm={10}>
                <Row>
                  {codeListState?.dimensionSelectViewState?.dimensionsEnabled && codeListState?.dimensionSelectViewState?.dimensionSelectStates?.map(dimensionSelectStore => buildDimensionFilters(dimensionSelectStore, 6))}
                </Row>
              </Col>
              {isDimensionsVisible && <ExportButton viewState={codeListState}/>}
            </Row>
            <Row>
              <Col sm={5}>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    {intl.formatMessage({id: "listCodes.searchInput.defaultMessage"})}
                  </InputGroupAddon>
                  <Input
                    type="text"
                    name="searchInput"
                    placeholder="Search"
                    onChange={codeListState.onSearchChange}

                  />
                </InputGroup>
              </Col>
              <Col sm={5}>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    {intl.formatMessage({id: "listCodes.filterBytags.defaultMessage"})}
                  </InputGroupAddon>
                  <TagFilterSelect tagFilterSelectState={codeListState.tagFilterSelectState}/>
                </InputGroup>
              </Col>
              <Col sm={1}>
                <CustomInput
                  type="switch"
                  id="isFilterTypeOR"
                  name="isFilterTypeOR"
                  label={formConstants.Mode.OR}
                  checked={codeListState.isFilterTypeOR}
                  onChange={() => codeListState.selectFilterMethod()}
                />
              </Col>
              <Col sm={0.5}  >
                <InputGroup className="justify-content-end">
                  <RouterLink routeName="createCode">
                    <Button id="create-code-btn" color="primary"><FormattedMessage id="listCodes.create.defaultMessage"/></Button>
                  </RouterLink>
                  <QrTemplateSelect qrtemplateSelectStore={codeListState.qrtemplateSelectStore}
                                    onItemClick = {handleItemClick} />
                </InputGroup>
              </Col>
            </Row>
          </>}
          <ScanbuyTable
            columns={codeListState.columns}
            store={codeListState.store}
            queryParams={{}}
            onRowClicked={onRowClicked}
            highlightOnHover={true}
            pointerOnHover={true}
          />
        </CardBody>
      </Card>
      <GenericModal
        modalStore={codeListState.modalStore}
        componentStore={codeListState.modalComponentStore}
        EmbedComponent={codeListState.modalComponent}
        size={codeListState.modalSize}
        className={codeListState.modalClassName}
      />
    <GenericModal2
      modal2Store={codeListState.modal2Store}
      componentStore={codeListState.modal2ComponentStore}
      EmbedComponent={codeListState.modal2Component}
      size={codeListState.modal2Size}
      className={codeListState.modal2ClassName}
    />
      <GenericToastView genericToastState={codeListState.toastStore}/>
    </>
  );
})

export default CodeListView;
