import {flow, getEnv, resolveIdentifier, types} from "mobx-state-tree";
import {QrConfigStore} from "../stores/domain/QrConfigStore";
import lodash from "lodash";
import {TagModel} from "./tags/TagModel";

//QrConfigStore.defaultConfiguration
export const DesignsList = types.model({
  configStore: types.maybe(types.late(() => types.reference(QrConfigStore))),
  isLoading: types.optional(types.boolean, false), //types.enumeration('State', ['pending', 'done', 'error']),
  page: types.optional(types.integer, 1),
  perPage: types.optional(types.integer, 10),
  totalRows: types.optional(types.integer, 0),
  sortOrder: types.optional(types.string, "desc"),
  sortField:  types.optional(types.string, "updatedAt"),
}).volatile(self => ({
  qrStoreListener: types.fn,
  searchParams: {},
  filters: {}
})).actions((self) => ({
  setQrStoreListener(setConfigStore) {
    self.qrStoreListener = setConfigStore
  },
  setSortOrder(sortDirection){
    self.sortOrder = sortDirection
  },
  setSortField(sortField){
    self.sortField = sortField
  },
  fetchAll: flow(function* fetchAll(sortParams = {}) {
    self.isLoading = true
    if (Object.keys(sortParams).length === 0) {
      sortParams = {
        sort: 'updatedAt',
        order: self.sortOrder
      };
    }
    try {
      const queryParams = {
        page: self.page,
        perPage: self.perPage,
        ...sortParams,
        ...self.searchParams
      }

      const imageConfigManager = getEnv(self).imageConfigManager
      const response = yield imageConfigManager.fetchAll(queryParams);
      if(response !=null) {
        const qrConfigStore = response.results.map((el) => {
          let nestedTags = [];
          if(el.tags && el.tags.length > 0){
            nestedTags = el.tags.map(t => resolveIdentifier(TagModel, self, t?.uuid))
          }
          return {
            "uuid": el.uuid,
            "name": el.name,
            "label": el.name,
            "image": el.qrPreview,
            "owner": el.owner,
            "qrConfig": el.data,
            "createdAt": el?.createdAt,
            "updatedAt": el?.updatedAt,
            "isExclusivelyGroupOwned": el?.isExclusivelyGroupOwned,
            "isEditable": el?.isEditable,
            "tags": nestedTags,
            "_audit": el?._audit
          }
        })
        // self.items = qrConfigStore
        self.qrStoreListener(qrConfigStore)
        self.totalRows = response._meta.itemCount;
        self.isLoading = false;
      } else {
        self.isLoading = false;
      }

    } catch (error) {
      console.error('Failed to fetch all', error)
    }
  }),
  fetchFilters: flow(function* fetchFilters(sortParams = {}) {
    self.isLoading = true;

    try {
      const queryParams = {
        ...self.searchParams
      }

      const imageConfigManager = getEnv(self).imageConfigManager
      const response = yield imageConfigManager.fetchFilters(queryParams);
      if(response !=null) {
        self.filters = response
        self.isLoading = false;
      } else {
        self.isLoading = false;
      }

    } catch (error) {
      console.error('Failed to fetch all', error)
    }

  }),
  updatePage: function (page) {
    self.page = page
  },
  updatePerPage: function (perPage) {
    self.perPage = perPage
  },
  addSearchParams(param){
    self.searchParams = {...self.searchParams, ...param}
  },
  removeSearchParams(names,regex = ''){
    if(names){
      names.map((name) => delete self.searchParams[name])
    }
    if(regex){
      const keysToDelete = Object.keys(self.searchParams).filter(key => regex.test(key));
      keysToDelete.forEach(key => delete self.searchParams[key]);
    }


  },
  removeItem() {
    self.fetchAll({})
  },
  removeListOnExit(){
    self.items = undefined
  },

})).views( (self) => ({
  getItems() {
    const { alternateConfiguration } = self.configStore || {};
    return alternateConfiguration || []
  }
}))
