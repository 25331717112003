import {getEnv, getType, resolveIdentifier, types} from 'mobx-state-tree'
import TagStore from '../../../stores/domain/TagStore';
import { TagModel as Tag, TagModel } from "../../../models/tags/TagModel";

export const TagSelectState = types.model('TagSelectState',{
  selectStateUuid: types.identifier,
  tagStore: types.maybe(types.late(() => types.reference(TagStore))),//types.optional(types.array(Tag),[]),
  selectedTags: types.optional(types.array(types.safeReference(Tag)),[]),
  // selectedGroup: types.maybe(types.string),
  //types.optional(types.array(types.safeReference(Tag)),[]),
  //types.array(types.safeReference(Tag), [])
  //types.array(types.safeReference(Tag, { acceptsUndefined: false })) // types.optional(types.array(Tag),[]),
}).volatile(self => ({
  notifySelect: types.function
})).actions( (self)=>({
  // onCreate() {
  //   console.log("*************** On Create Tag Select State");
  //   self.selectedTags = []; //not working
  // },
  searchTag(txt) {
    console.log('searching for :'+ txt)
  },
  selectTag(one){
    self.notifySelect(one)
  },
  setConsumer(f){
    self.notifySelect = f
  },
  // setSelectedGroup(uuid) {
  //   self.selectedGroup = uuid;
  // },
  afterCreate() {
    //console.log("*************** After Create Tag Select State");
    console.log("Instantiated " + getType(self).name)
    //self.selectedTags = []; //not working
    self.notifySelect = (data) => {
      console.log("placeholder notifySelect")
    }
  },
  setSelectedTags(selectedTags) {
    self.selectedTags = selectedTags?.map( (t) => {
      return resolveIdentifier(TagModel, self,t?.uuid || t)
    });
    self.notifySelect(self.selectedTags);
  },
  // async configOptionLoadValues(inputValue) {
  //   try {
  //     const queryParams = {
  //       perPage: 25,
  //       sort: "updatedAt",
  //       order: "desc",
  //       isExclusivelyGroupOwned: false
  //     };
  //     if (inputValue) {
  //       queryParams.q = inputValue;
  //     }
  //     if (self.tagStore?.selectedGroup){
  //       queryParams.groupUuid = self.tagStore?.selectedGroup;
  //     }
  //     let response = await getEnv(self).tagManager.fetchAllTags(queryParams);
  //     if(response?.results) {
  //       self.tagStore?.initWithData(response.results, self.selectedTags);
  //     }
  //     const {tags}  = self?.tagStore || {};
  //     return tags || []
  //   } catch (error) {
  //     console.error("Failed to fetch Configs", error);
  //   }
  //   return []
  // },
})).views( (self) => ({
  tagOptionValues() {
    const tags = self.tagStore.filteredList() || {};
    tags.sort((a, b) => a?.name?.localeCompare(b?.name));
    return tags || []
  },
  // getSelectedTags() {
  //   console.log("%%%% getSelectedTags", self.selectedTags?.length)
  //   return self.selectedTags || []
  // },
}))
