import { getType, types } from "mobx-state-tree";
import lodash from "lodash";

export const BatchFilterSelectState = types.model('BatchFilterSelectState', {
    selectStateUuid: types.maybe(types.identifier),
    statusTypes: types.optional(types.array(types.string), ["CREATED", "PROCESSING", "PENDING", "MERGING", "DONE", "DELETED"]),
    batchTypes: types.optional(types.array(types.string), ["DOWNLOAD", "UPLOAD"])
}).volatile(self => ({
    notifySelect: types.f,
    selected: types.array
})).actions((self) => ({
    reset() {
        self.selected = [];
    },
    filterChange(data){
        self.selected = data;
        self.notifySelect(lodash.reduce(data, function(result, value, key) {
          const parts = value.value.split('.');
          const pushValue = parts[parts.length-1];
          const fieldName = value.value.substr(0, value.value.length - pushValue.length - 1 );
          (result[fieldName] || (result[fieldName] = [])).push(pushValue);
          return result;
        }, {}), ["batchType","status"]);
      },
      setConsumer(f){
        self.notifySelect = f
      },
      afterCreate() {
        console.log("Instantiated " + getType(self).name)
        self.notifySelect = (data) => {
          console.log("placeholder notifySelect")
        }
      }
})).views((self) => ({
    optionValues() {
        const experiences = self.batchTypes;

        const expOptions = experiences
          .slice()
          .sort((a, b) => a?.label?.localeCompare(b?.label))
          .flatMap( (exp) => {
          return {
            label: exp.label,
            value: "batchType."+exp.type
          }
        });

        const statusOptions = self.statusTypes
          .slice()
          .sort((a, b) => a?.localeCompare(b))
          .flatMap( (stat) =>{
          return {
            label: stat,
            value: "status."+stat
          }
        })

        const options = [
          {
            label: "BatchType",
            options: expOptions
          },
          {
            label: "Status",
            options: statusOptions
          },
        ];
        return options;
    }
}));
