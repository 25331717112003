// noinspection DuplicatedCode

import React, {useContext, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import { saveAs } from 'file-saver';
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Container,
  DropdownItem,
  Row,
} from 'reactstrap';

import * as Icon from "react-feather";
import { observer } from "mobx-react-lite";
import {types} from 'mobx-state-tree';
import { RouterLink } from "mobx-state-router";
import { FormattedMessage } from "react-intl";

import BatchListView from "../../../components/codes/batches/BatchListView";
import { StateContext } from "../../../App";
import { TableColumn } from "../../../components/tables/ScanbuyTable";
import Header from "../../../components/Header";
import TableActionsDropdown from "../../../components/tables/TableActionsDropdown";
import ScanbuyModal, {ModalState} from '../../../components/elements/modal/GenericModal';
import BatchItemListView from '../../../components/codes/batches/items/BatchItemListView';
import {BatchItemList} from '../../../models/batch/BatchItemList';
import BatchManager from '../../../managers/codes/BatchManager';
import BatchItemListState from '../../../components/codes/batches/items/BatchItemListState';
import BatchForm from '../../../components/codes/batches/BatchForm';





export const buildColumns = (routerStore, batchList) => {
  const {rootStore} = routerStore.options;
  const c1 = TableColumn.create({ name: 'uuid', property: 'uuid' });
  c1.setSelector((row) => row.uuid);

  const c2 = TableColumn.create({
    name: 'Status',
    property: 'status',
    sortable: true,
    sortField: 'status',
    selector: 'status',
  })
  c2.setSelector((row)=>{
    return row.status
  })

  const c3 = TableColumn.create({ name: 'BatchType', property: 'batchType' });
  c3.setSelector((row) => {
    return row.batchType;
  });

  const c4 = TableColumn.create({
    name: 'File Name',
    property: 'fileName'
  });
  c4.setSelector((row) => {
    return row.fileName;
  });

  const c5 = TableColumn.create({
    name: 'Total Items',
    property: 'totalItems',
    width: '10%',
    hide: 'sm'
  });
  c5.setSelector((row) => {
    return row.totalItems;
  });

  const c6 = TableColumn.create({
    name: 'Created',
    property: 'created',
    width: '10%',
    hide: 'sm'
  });

  c6.setSelector((row) => {
    return row.createdAt;
  });

  const c7 = TableColumn.create({
    name: 'Updated',
    property: 'updated',
    width: '10%',
    hide: 'sm'
  });

  c7.setSelector((row) => {
    return row.updatedAt;
  });

  const actionColl = TableColumn.create({
    name: 'Actions', property: 'actions', button: true, allowOverflow: true, ignoreRowClick: true
  });

  actionColl.setCell((row) => (
    <div>

        <TableActionsDropdown actionsList={[
          <DropdownItem key={'batches/' + row.uuid} onClick={() => {
            routerStore.goTo('batchItems', {
              params: { batchUuid: row.uuid },
            });
          }}><Icon.Archive/>&nbsp;Details</DropdownItem>,
          row.status === 'DONE' && row.batchType === 'DOWNLOAD' ? (
          <DropdownItem key={'download/' + row.uuid} onClick={() => {
            const downloadFile = async () => {
              // Replace 'url-to-file' with the actual URL of the file to download
              if (row.details.presignUrl && row.details.presignUrl !== "") {
                saveAs(row.details.presignUrl, row.fileName + '.zip');
              }
            };
            downloadFile();
          }}>
            <Icon.Edit/>&nbsp;Download
          </DropdownItem>) : null,
          row.status === 'MERGEABLE' ? (
            <DropdownItem key={'merge/' + row.uuid} onClick={() => {
              const mergeBatch = async () => {
                await batchList.mergeBatch(row.uuid)
              }
              mergeBatch();
              batchList.fetchAll()
            }}>
            <Icon.Edit/>&nbsp;Merge
            </DropdownItem>) : null,
        ]}/>

    </div>
  ));

  return [
    // c1,
    c4,
    c3,
    c2,
    c5,
    c6,
    c7,
    actionColl
  ];

}

const BatchListPage = observer(() => {
  console.log("Start Page, Batches")
  const stateContext = useContext(StateContext);
  const batchListViewState = stateContext.rootUiStore.batchListViewState
  const history = useHistory();
  const [uploadFiles, setUploadFiles] = useState([]);
  const [tagDownload, setTagDownload] = useState(false);
  // const batchManager = new BulkManager(new HttpClient(config.API_DOMAIN_NAME));

  /* Batch Item Modal*/
  const modalTitle = 'Batch Items';
  const startOpen = false;
  const modalStore = ModalState.create({ open: startOpen, title: modalTitle });
  const batchItemStore = BatchItemList.create();

  const handleViewActionClick = async (rowUuid) => {
    await batchItemStore.updateBatchUuid(rowUuid);
    modalStore.toggle();
  };

  const ComponentStoreState = types
  .model({})
  .actions((self) => ({
    saveAndClose(modalStore) {
      //do save.
      //if ok..
      return modalStore.toggle();
    },
  }));

  const componentStore = ComponentStoreState.create({});

  const batchItemColumn1 = TableColumn.create({ name: 'uuid', property: 'uuid' });
  batchItemColumn1.setSelector((row) => row.uuid);

  const batchItemColumn2 = TableColumn.create({ name: 'batchUuid', property: 'batchUuid' });
  batchItemColumn2.setSelector((row) => row.batchUuid);

  const batchItemColumn3 = TableColumn.create({
    name: 'Status',
    property: 'status',
    sortable: true,
    sortField: 'status',
    selector: 'status',
  })
  batchItemColumn3.setSelector((row)=>{
    return row.status
  })

  const batchItemColumns = [
    batchItemColumn1,
    batchItemColumn2,
    batchItemColumn3
  ];

  const batchItemsListState = BatchItemListState.create({
    store: batchItemStore,
    columns: batchItemColumns,
  });

  const x = () => {
    return (
      <BatchItemListView batchItemsListState={batchItemsListState}/>
    );
  };
  /* Batch Item Modal */

  const actionCol = TableColumn.create({
    name: 'Actions',
    property: 'action',
    allowOverflow: true,
    button: true,
    width: '100px',
  })
  actionCol.setCell((row) => (
    <TableActionsDropdown actionsList={[
      <DropdownItem key={'view/'+row.uuid} onClick={() => handleViewActionClick(row.uuid)}><Icon.Search />View</DropdownItem>,
      <DropdownItem key={'edit/'+row.uuid}><Icon.Edit /><Link to={"/codes/batches/" + row.uuid}>Edit</Link></DropdownItem>,
      <DropdownItem key="divider" divider />,
      <DropdownItem key={'delete/'+row.uuid} className="align-text-bottom"><Icon.Delete /><Link to={"/codes/batches/delete/" + row.uuid}>Delete</Link></DropdownItem>
    ]} />
  ))

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (batchFormState.downloadType === 'tags'){
      console.log("Tag Download")
      console.log(batchFormState.tagSelectStore.selectedTags.flatMap((tag)=>{ return tag.uuid}));
      await batchFormState.handleTagsDownload({
        tags: batchFormState.tagSelectStore.selectedTags.flatMap((tag)=>{ return tag.uuid}),
        batchName: batchFormState.batchName,
      });
    } else {
      await batchFormState.handleCsvUpload({
        batchType: e.target.batchType.value,
        uploadFiles: uploadFiles,
        batchName: batchFormState.batchName,
      });
    }
    batchListViewState.refresh()
    history.push("/batches");
  };

  // const batchFormState = BatchFormState.create({title: "Create Batch"})

  const batchFormState = batchListViewState.batchFormState
  batchFormState.setHandleSubmit(handleSubmit)
  batchFormState.setUploadFiles(setUploadFiles)
  ///handleSubmit={handleSubmit} setUploadFiles={setUploadFiles}
  return (
    <Container fluid>
      <Header>
        <Breadcrumb>
          <BreadcrumbItem>
            <RouterLink routeName="dashboard" ><FormattedMessage id="routes.home.defaultMessage"/></RouterLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <RouterLink routeName="codes" ><FormattedMessage id="routes.codes.defaultMessage"/></RouterLink>
          </BreadcrumbItem>
          <BreadcrumbItem active><FormattedMessage id="routes.batches.defaultMessage"/></BreadcrumbItem>
        </Breadcrumb>
        <ScanbuyModal
          modalStore={modalStore}
          EmbedComponent={x} />
      </Header>
      <Row>
        <Col sm={8}>
          <BatchListView batchListState={batchListViewState} />
        </Col>
        <Col sm={4}>
          <BatchForm batchFromState={batchFormState}  />
        </Col>
      </Row>
    </Container>
  )}
)
export default BatchListPage;
