import {types} from "mobx-state-tree";
import {UtmsList} from "../../../models/utms/UtmsListModel";
import {TableColumn} from "../../tables/ScanbuyTable";
import {GroupSelectState} from "../../elements/groups/GroupSelectState";
import {ModalState} from "../../elements/modal/GenericModal";
import {TagSelectState} from "../../elements/tags/TagSelectState";
import {TagModel as Tag} from "../../../models/tags/TagModel";
import {formConstants} from "../../../utils/constants";
import {AdvancedConfFilterSelectState} from "../../elements/filters/AdvancedConfFilterSelectState";
import lodash from "lodash";
import {CodeOwnerFilterSelectState} from "../../elements/filters/CodeOwnerFilterSelectState";
import {DimensionSelectViewState} from "../../elements/dimensions/dimensionSelectViewState";
import {TagFilterSelectState} from "../../elements/filters/TagFilterSelectState";
import {GroupFilterSelectState} from "../../elements/filters/GroupFilterSelectState";

export const UtmsListViewState = types.model('UtmsListViewState', {
  listModel: types.late(() => (UtmsList)),
  columns: types.maybe(types.array(types.late(() => TableColumn))),
  groupSelectStore: types.maybe(types.reference(GroupSelectState)),
  codeOwnerFilterSelectState: types.maybeNull(types.reference(CodeOwnerFilterSelectState)),
  advFilterSelectState: types.maybeNull(types.reference(AdvancedConfFilterSelectState)),
  dimensionSelectViewState: types.maybeNull(types.reference(DimensionSelectViewState)),
  tagFilterSelectState: types.maybeNull(types.reference(TagFilterSelectState)),
  groupFilterSelectState: types.maybeNull(types.reference(GroupFilterSelectState)),
  modalStore: types.maybe(ModalState),
  tagSelectStore: types.maybeNull(types.reference(TagSelectState)),
  selectedTags: types.optional(types.array(types.reference(Tag, {acceptsUndefined: true})), []),
  isFilterTypeOR: types.optional(types.boolean, true),

  isFilterVisible: types.optional(types.boolean, true),
  filterGroup: types.optional(types.boolean, true)
}).volatile((self) => ({
  modalComponent: types.string,
  modalComponentStore: types.string,
  toastComponentStore: types.string
})).actions((self) => ({
  handleRowClick(row, componentStore, component) {
    self.modalComponent = component;
    self.modalComponentStore = componentStore
    self.modalStore.handleTitle(componentStore.title)
    self.modalStore.toggle()
  },
  toggleFilterVisible() {
    self.isFilterVisible = !self.isFilterVisible
  },
  setColumns(col) {
    self.columns = col
  },
  onSearchChange(txt){
    console.log(txt?.currentTarget?.value);
    const searchText = txt?.currentTarget?.value;
    if(searchText){
      if(searchText.length > 2) {
        self.listModel.removeSearchParams(["q"]);
        self.listModel.addSearchParams({ q: searchText })
        self.refresh()
      } else {
        self.listModel.removeSearchParams(["q"])
      }
    } else {
      self.listModel.removeSearchParams(["q"]);
      self.refresh()
    }
  },
  selectFilter(obj, covered){

    const shouldRemoveDimensions = covered.includes("dimensionUuid")
    const regex = shouldRemoveDimensions ? /^dimension/ : undefined;
    self.listModel.removeSearchParams(covered,regex);

    if(obj){
      const transpiledDimensions = {};
      if(!lodash.isEmpty(obj.dimensions)){
        obj.dimensions.forEach(dimension => {
          const [uuid, value] = dimension.split('_');
          if (transpiledDimensions.hasOwnProperty(`dimension[${uuid}]`)) {
            transpiledDimensions[`dimension[${uuid}]`] += ',' + value;
          } else {
            transpiledDimensions[`dimension[${uuid}]`] = value;
          }
        });
        delete obj.dimensions
      }

      const params = lodash.mapValues(obj,(value, key) => {
        return {}[key] = value.join(',');
      });
      self.listModel.addSearchParams({...params,...transpiledDimensions})
      self.refresh()
      const prevGroupUuid = self.prevGroupUuid || [];
      const currentGroupUuid = obj.groupUuid || [];
      if (!lodash.isEqual(prevGroupUuid, currentGroupUuid)) {
        self.filterGroup = false
        self.prevGroupUuid = currentGroupUuid;
      }
      self.getFilters();
    }
  },
  refresh() {
    self.listModel.fetchAll()
  },
  getFilters() {
    self.listModel.fetchFilters();
  },
  selectGroup(obj) {
    obj = obj || []
    self.listModel.addSearchParams({groupUuid: obj.flatMap((row) => row.uuid).join(",")})
    self.refresh()
  },
  setOnSelectChanges() {
    self.groupSelectStore.setMultiSelectConsumer(self.selectGroup)
    self.listModel.setSelectedGroup(self.groupSelectStore.selectedGroup) // used to filter list by group
    self.tagSelectStore?.setConsumer(self.selectTag)
    self.advFilterSelectState?.setConsumer(self.selectFilter)
    self.codeOwnerFilterSelectState?.setConsumer(self.selectFilter)
    self.dimensionSelectViewState?.setFilterConsumer(self.selectFilter)
    self.tagFilterSelectState?.setConsumer(self.selectFilter)
    self.groupFilterSelectState?.setConsumer(self.selectFilter)
  },
  afterCreate() {

  },
  selectTag(obj) {
    obj = obj || []
    self.listModel.addSearchParams({tags: obj.flatMap((row) => row.uuid).join(",")})
    self.refresh()
  },
  selectFilterMethod() {
    if (!self.isFilterTypeOR) {
      self.isFilterTypeOR = !self.isFilterTypeOR
      self.listModel.addSearchParams({tagFilterType: formConstants.Mode.OR})
    } else {
      self.isFilterTypeOR = !self.isFilterTypeOR
      self.listModel.addSearchParams({tagFilterType: formConstants.Mode.AND})
    }
    self.refresh()
  },
}))

