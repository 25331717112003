import React from "react";
import { observer } from "mobx-react";
import { Button, Col, InputGroup, InputGroupAddon, Row } from "reactstrap";
import Select from "react-select";
import { FormattedMessage } from "react-intl";
import def from "ajv/dist/vocabularies/discriminator";

const QrDownloadView = observer(({ store }) => {
    let defaultValue = { label: "PNG", value: "png" };
    // let options = [
    //   { label: "BMP", value: "bmp" },
    //   { label: "EPS", value: "eps" },
    //   { label: "GIF", value: "gif" },
    //   { label: "JPG", value: "jpg" },
    //   { label: "PDF", value: "pdf" },
    //   { label: "SVG", value: "svg" },
    // ];

    // // Remove PNG option if colorMode is CMYK
    // if (JSON.parse(store.qrImageFormData).colorMode === 'cmyk') {
    //     options = options.filter(option => option.value !== 'png');
    //     defaultValue = { label: "JPG", value: "jpg" };
    // } else {
    //   const pngOption = { label: "PNG", value: "png" };
    //   if (!options.some(option => option.value === pngOption.value)) {
    //     options = [pngOption, ...options]; // Add PNG option at the beginning
    //   }
    //   defaultValue = { label: "PNG", value: "png" };
    // }
  return (
    <Row className="justify-content-center">
      <Col lg="6">
        <InputGroup className="mb-3">
          <Select
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPlacement="top"
            menuPosition='fixed'
            onChange={store.handleImgFormat}
            className="react-select-container"
            classNamePrefix="react-select"
            defaultValue={store.qrDownloadStore.defaultImageFormat}
            name="imgFormat"
            value={store.qrDownloadStore.downloadImageFormat}
            options={store.qrDownloadStore.options} />
          {store.previewImage && !store.isLoading && <InputGroupAddon style={{ zIndex: 1 }} addonType="append" color="primary">
            <Button id="download-btn" onClick={store.downloadConfig}><FormattedMessage id="preview.download.defaultMessage" /></Button>
          </InputGroupAddon>}
        </InputGroup>
      </Col>
    </Row>
  )
})
export default QrDownloadView
