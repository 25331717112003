import {flow, getEnv, types} from "mobx-state-tree";
import ParameterSetStore from "../../stores/domain/ParameterSetStore";
import Group from "../Group";
import lodash from "lodash";

export const ParameterSetsList = types.model('ParameterSetsList ', {
  store: types.maybe(types.late(() => types.reference(ParameterSetStore))),
  isLoading: types.optional(types.boolean, false), //types.enumeration('State', ['pending', 'done', 'error']),
  page: types.optional(types.integer, 1),
  perPage: types.optional(types.integer, 10),
  totalRows: types.optional(types.integer, 0),
  selectedGroup: types.maybe(types.reference(Group)),

})
  .volatile(self => ({
    searchParams: {},
    filters: {}
  })).actions((self) => ({
    fetchAll: flow(function* fetchAll(sortParams = {}) {
      // self.items = []
      self.isLoading = true
      try {
        let queryParams = {
          page: self.page,
          perPage: self.perPage,
          ...sortParams,
          ...self.searchParams
        }

        //if its not a group query add isExclusivelyGroupOwned
        queryParams = Object.assign({
            ...queryParams,
          },
          lodash.isEmpty(queryParams.groupUuid) && {isExclusivelyGroupOwned: false}
        )

        const manager = getEnv(self).parameterSetManager
        console.log("queryParams in fetchAll parameterSets", queryParams)
        const response = yield manager.fetchAll(queryParams);
        if (response != null) {
          console.log("response.results", response.results);
          self.store?.setParameterSets(response?.results)
          self.totalRows = response._meta.itemCount;
          self.isLoading = false;
        } else {
          self.isLoading = false;
        }
      } catch (error) {
        console.error('Failed to fetch parameterSets', error)
      }
    }),
    fetchFilters: flow(function* fetchFilters(sortParams = {}) {
      self.isLoading = true;

      try {
        const queryParams = {
          ...self.searchParams
        }

        const parameterSetManager = getEnv(self).parameterSetManager
        const response = yield parameterSetManager.fetchFilters(queryParams);
        if (response != null) {
          self.filters = response
          self.isLoading = false;
        } else {
          self.isLoading = false;
        }

      } catch (error) {
        console.error('Failed to fetch all', error)
      }
    }),
    updatePage: function (page) {
      self.page = page
    },
    updatePerPage: function (perPage) {
      self.perPage = perPage
    },
    addSearchParams(param) {
      self.searchParams = {...self.searchParams, ...param}
    },
    removeSearchParams(names,  regex= ''){
      if(names){
        names.map((name) => delete self.searchParams[name])
      }
      if(regex){
        const keysToDelete = Object.keys(self.searchParams).filter(key => regex.test(key));
        keysToDelete.forEach(key => delete self.searchParams[key]);
      }
    },
    setSelectedGroup(group){  //set selected group to filter parameterSets
      self.selectedGroup = group
    }
  })).views((self) => ({
    getItems() {
      const { flatList } = self.store || {};
      return flatList || []
    }
  }))
