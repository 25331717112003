import {flow, getEnv, types} from "mobx-state-tree";
import {TagSelectState} from "../../elements/tags/TagSelectState";
import {GroupSelectState} from "../../elements/groups/GroupSelectState";
import {CodeTypeSelectState} from "../../elements/codeTypes/CodeTypeSelectState";
import Group from "../../../models/Group";
import CodeType from "../../../models/CodeType";
import {ModalState} from "../../elements/modal/GenericModal";
import {StatusState} from "../../configs/status/StatusState";
import {PageTemplateSelectState} from "../../elements/pageTemplate/PageTemplateSelectState";
import {UtmModalState} from "../../utms/modal/UtmModalState";
import {RuleSetModalState} from "../../configs/rulesets/modal/RuleSetModalState";
import {Utm} from "../../../models/utms/UtmModel";
import {DimensionSelectViewState} from "../../elements/dimensions/dimensionSelectViewState";
import lodash from "lodash";
import {DesignerModalState} from "../../qr/modal/DesignerModalState";
import {ajv} from "../../../services/AjvValidator";
import {codeBehavior, codeTypes, codeTypesWithBehavior} from "../../../utils/constants";
import Notification from "../../../utils/Notification";
import {addHttpsIfNeeded, urlValidator} from "../../../utils/common";
import {ParameterSetModalState} from "../../configs/parametersets/modal/ParameterSetModalState";
import Parameters from "../../../models/Parameters";
import StateMachine from "javascript-state-machine";
import {createTransitions} from "../../../utils/buildTransitions";
import {walletExperienceDataBuilder} from "../../elements/walletPass/utils";
import {ExtendedDataSettingModalState} from "../../extendedData/modal/ExtendedDataSettingModalState";
import {ExtendedDataModel} from "../../../models/extendedData/ExtendedData";


const defaultSchemaJson = {
  "type": "object",
  "properties": {
    "url": {
      "title": "Landing Url:",
      "type": "string",
      "validationMessage": {
        "default": "Url must be of proper format: http(s)://...",
        "302": "Url is required"
      }
    }
  },
  "required": ["url"]
};

export const EditCodeState = types.model('EditCodeState',{
  isLoading: types.optional(types.boolean, false),
  uuid: types.optional(types.string, ""),
  name: types.optional(types.string, ""),
  nameIsValid: types.optional(types.boolean, true),
  status: types.optional(types.string, ""),
  tagSelectStore: types.maybe(types.late(() => types.reference(TagSelectState))),
  groupSelectStore: types.maybe(types.late(() => types.reference(GroupSelectState))),
  codeTypeSelectStore: types.maybe(types.late(() => types.reference(CodeTypeSelectState))),
  pageTemplateSelectStore: types.maybe(types.late(() => types.reference(PageTemplateSelectState))),
  selectedGroup: types.maybe(types.reference(Group)),
  experienceSelected: types.maybe(types.reference(CodeType)),
  vCardDownLoadLinkData : types.optional(types.string, ""),
  behavior:  types.optional(types.string, ""),

  selectedTags: types.optional(types.array(types.string), []),
    //types.optional(types.array(types.reference(Tag, {acceptsUndefined: true})), []),
  experienceData: types.optional(types.string, '{}'),
  experienceUISchema: types.optional(types.string, '{}'),
  formHasErrors: types.optional(types.boolean, false),
  errorMessage: types.optional(types.string, "Null"),
  selectedDefaultUrl: types.optional(types.string, ''),
  inSubmission: types.optional(types.boolean, false),
  liveValidateSchema: types.optional(types.boolean, false),
  extendedDataSettingModalState: types.maybe(types.late(() => ExtendedDataSettingModalState)),
  extendedDataList: types.optional(types.array(types.safeReference(types.late(() => ExtendedDataModel))), []),


  experienceReadonly: types.optional(types.boolean, false),
  qrUrlReadonly: types.optional(types.boolean, false),

  modalStore: types.maybe(ModalState),
  statusViewState:types.maybe(types.late(() => StatusState)),
  utmModalState:types.maybe(types.late(() => UtmModalState)),
  utm:types.maybe(types.late(() => Utm)),
  ruleSetModalState:types.maybe(types.late(() => RuleSetModalState)),
  rules: types.optional(types.string, "[]"),
  designerModalState:types.maybe(types.late(() => DesignerModalState)),
  imageUuid: types.optional(types.string, ""),
  parameterSetModalState:types.maybe(types.late(() => ParameterSetModalState)),
  parameters: types.maybe(Parameters),
  dimensionSelectViewState: types.maybe(types.late(() => types.reference(DimensionSelectViewState))),
  selectedDimensions: types.optional(types.string,'{}'),
  previousPlatform: types.optional(types.string, ""),
  codeStatus: types.maybe(types.string),

}).volatile((self) => ({
  modalComponent: types.object,
  modalComponentStore: types.object,
  urlListener: types.fn,
  nameListener: types.fn,
  modalSize: types.str,
  modalClassName: types.str,
  fsm: types.fn,
})).actions(self => ({
  setUuid(uuid) {
    self.uuid = uuid
  },
  handleImageUuidChange(uuid) {
    self.imageUuid = uuid
  },
  handleNameChange(e) {
    self.name = e.target.value
    self.nameIsValid = true
  },
  setCodeStatus(status) {
    self.codeStatus = status
  },
  handleHasErrors(boolean) {
    self.formHasErrors = boolean
  },
  handleErrorMessage(message) {
    self.errorMessage = message
  },
  setShortUrlListener(listener) {
    self.urlListener = listener
  },
  setNameListener(listener) {
    self.nameListener = listener;
  },
  handleConfigModalPopUp(component,title,componentStore,size="",className = ""){
    self.modalComponentStore = self[componentStore];
    self.modalComponent = component;
    self.modalSize=size;
    self.modalClassName=className;
    self.modalStore.toggle()
    self.modalStore.handleTitle(title)
  },
  setOnSelectChanges() {
    self.groupSelectStore.setConsumer(self.selectGroup)
    self.tagSelectStore.setConsumer(self.selectTag)
    self.codeTypeSelectStore.setConsumer(self.selectExperience)
    self.designerModalState?.setConsumer(self.handleImageUuidChange)
    self.dimensionSelectViewState?.setConsumer(self.selectDimension)
    self?.extendedDataSettingModalState?.setConsumer(self.selectExtendedDataSelection);
  },
  selectExtendedDataSelection(d) {
    const selectedExtendedData = self.extendedDataSettingModalState?.getSelectedExtendedDataMap() || {};
    self.setExtendedDataList(Object.values(selectedExtendedData));
  },
  setExtendedDataList(arr) {
    self.extendedDataList = arr;
  },
  selectDimension(obj,parentUuid){
    let existingDimension = JSON.parse(self?.selectedDimensions)
    existingDimension[parentUuid] = obj?.uuid
    existingDimension = lodash.pickBy(existingDimension, value => !(lodash.isEmpty(value))); // to delete empty array object
    self.selectedDimensions = JSON.stringify(existingDimension)
  },
  getDimensions(){
    if(lodash.isEmpty(JSON.parse(self.selectedDimensions))) return undefined
    return JSON.parse(self.selectedDimensions)
  },
  validateDimension(){
    if(!self.dimensionSelectViewState?.dimensionsEnabled) return true
    let dimensions = self.getSelectedDimensions()
    const dimensionUuids = self.dimensionSelectViewState?.dimensionSelectStates.map(selectState => selectState?.selectStateUuid)
    dimensionUuids.forEach(uuid => {
      if (!dimensions.hasOwnProperty(uuid)) {
        self.dimensionSelectViewState?.dimensionSelectStates?.find(selectState => selectState?.selectStateUuid == uuid).setDefaults()
      }
    })
    dimensions = self.getSelectedDimensions()
    return dimensionUuids.every(uuid => dimensions.hasOwnProperty(uuid));
  },
  getSelectedDimensions(){
    return JSON.parse(self.selectedDimensions)
  },
  selectGroup(obj) {
    self.selectedGroup = obj;
    const dimensionUuids = self.dimensionSelectViewState?.dimensionSelectStates
      .filter(selectState => selectState?.dimension?.type === "CONDITIONAL")
      .map(selectState => selectState?.selectStateUuid);
    dimensionUuids.forEach(uuid => {
      self.dimensionSelectViewState?.dimensionSelectStates?.find(selectState => selectState?.selectStateUuid == uuid).getListOnGroup(self?.selectedGroup?.uuid)
    })
  },
  selectTag(arr) {
    self.selectedTags = arr.flatMap((tag)=> tag?.uuid)
  },
  selectExperience(obj) {
    if (!self.experienceDataMap) {
      self.experienceDataMap = {};
    }
    if (self.experienceSelected) {
      self.experienceDataMap[self.experienceSelected.name] = self.experienceData;
      if (self.experienceDataMap.hasOwnProperty(obj.name)) {
        self.experienceData = self.experienceDataMap[obj.name];
      } else {
        self.experienceData = '{}';
      }
    }
    self.experienceSelected = obj;
  },
  returnDynamic(behavior) {
    const filteredBehavior = behavior?.filter(b => b !== codeBehavior.STATIC);
    if(filteredBehavior?.length === 1) {
      return filteredBehavior[0];
    } else {
      return "";
    }
  },
  buildFSM(currentCodeType) {
    const initState = currentCodeType.name +"|"+self.returnDynamic(currentCodeType.behavior);
    const transitions = createTransitions(codeTypesWithBehavior);
    let FSM = new StateMachine({
      init: initState,
      transitions: transitions
    })
    self.fsm = FSM;
    self.codeTypeSelectStore.setFsm(self.fsm);
  },
  searchGroup(txt) {
    console.log(txt)
  },
  updatePreviousPlatform(platform) {
    self.previousPlatform = platform
  },
  updateExperienceData(data) {
    if(self.isCodeTypeSocialMedia()) {
      self.updateFormData(data);
    }
    if (data.hasOwnProperty("enableWalletPass") && data?.enableWalletPass) {
      data = {
        ...data,
        ...walletExperienceDataBuilder(self.experienceSelected?.uuid, data),
      };
    }
    if (data.hasOwnProperty("passType")) {
      // For Mobile Wallet Pass experience
      ["eventTicket", "coupon", "storeCard", "generic"].forEach((t) => {
        if (data.hasOwnProperty(t) && data[t]) {
          data[t] = {
            ...data[t],
            "showMobileWalletCode": data[t].barcodeType === "None" || data[t].showMobileWalletCode
          };
        }
      });
    }
    self.experienceData = JSON.stringify(data)
    console.log(self.toJSON())
  },
  addVCardDownLoadLinkData(data) {
    self.vCardDownLoadLinkData = JSON.stringify(data)
  },
  experienceExtendedDataView() {
    return self.extendedDataSettingModalState?.formData;
  },
  updateExperienceUISchema(uiSchema="{}") {
    self.experienceUISchema = uiSchema;
  },
  updateExperienceUISchemaOnData(data) {
    let uiSchema = JSON.parse(self.experienceUISchema);
    if (data.hasOwnProperty("passType")) {
      // For Mobile Wallet Pass experience
      ["eventTicket", "coupon", "storeCard", "generic"].forEach((t) => {
        if (uiSchema.hasOwnProperty(t) && data[t]) {
          uiSchema[t] = {
            ...uiSchema[t],
            "showMobileWalletCode": {
              "ui:readonly": data[t].barcodeType === "None"
            }
          };
        }
      });
    }
    self.updateExperienceUISchema(JSON.stringify(uiSchema));
  },
  updateFormData(data) {
    const platformDefaults = {
      "Facebook": ["Follow", "Share"],
      "Instagram": "Follow",
      "YouTube": ["Watch", "Subscribe"],
      "LinkedIn": "Connect",
      "Pinterest": "Follow",
      "Reddit": ["Follow", "Join"],
      "Snapchat": "Follow",
      "Telegram": "Follow",
      "TikTok": ["Follow", "Share"],
      "Tumblr": "Follow",
      "X (Twitter)": ["Follow", "Tweet"],
      "Line": "Add"
    };

    if (data.platform !== self.previousPlatform) {
      self.updatePreviousPlatform(data.platform);
      if (platformDefaults.hasOwnProperty(data.platform)) {
        data.username = "";
      }
    }

    if (Array.isArray(platformDefaults[data.platform])) {
      if (platformDefaults[data.platform].includes(data.action)) {
        data.action = data.action;
      } else {
        data.action = platformDefaults[data.platform][0];
      }
    } else {
      if (data.action === platformDefaults[data.platform]) {
        data.action = data.action;
      } else {
        data.action = platformDefaults[data.platform];
      }
    }
  },
  hydrateForm(code){
    self.name = code?.name
    self.experienceSelected = code?.type
    self.codeTypeSelectStore.setDefaultCodeType(code?.type)
    if( code?.behavior === codeBehavior.STATIC){
    self.selectedDefaultUrl = code?.shortUrl?.uri
    }else{
    self.selectedDefaultUrl = code?.shortUrl?.domain + "/" + code?.shortUrl?.uri
    }
    self.behavior = code?.behavior
    self.experienceData = JSON.stringify(code?.data)
    self.extendedDataSettingModalState?.hydrateModal(code);
    self.pageTemplateSelectStore.setStyles(code?.dataDetail?.styles)
    self.groupSelectStore.selectGroup(code?.owner?.groupUuid)
    self.tagSelectStore.setSelectedTags(code?.tags?.flatMap((tag)=> tag.uuid))
    self.statusViewState.setStatus(code?.status)
    self.setCodeStatus(code?.status)
    self.utmModalState.utm.hydrateUtm(code?.utm)
    self.parameters.hydrateParameters(code?.parameters)
    self.rules = JSON.stringify(code.rules)
    if(code?.statusDetail?.schedule){
      self.statusViewState.setStatusScheduleData(code?.statusDetail?.schedule)
      self.statusViewState.setScheduleStatusChange()
    }
    if(code?.owner?.dimensions){
       self.dimensionSelectViewState.setDimensions(code?.owner?.dimensions)
    }
      self.utm = Utm.create({
        uuid: "editCode",
        campaign: code.utm?.campaign,
        source: code.utm?.source,
        medium: code.utm?.medium,
        term: code.utm?.term,
        content: code.utm?.content
      })
  },
  fetchWithID: flow(function* f(){
    if(self.uuid === "") return
    self.isLoading = true
    const manager = getEnv(self).codeManager
    const res = yield manager.fetchQrcodeWithId(self.uuid)
    self.urlListener(  res?.behavior === codeBehavior.STATIC ? res?.shortUrl?.uri : res?.shortUrl?.domain + "/" + res?.shortUrl?.uri)
    self.hydrateForm(res)
    self.nameListener(res?.name);
    self.isLoading = false
    return res // needs to return to fetch image from server with image config uuid
  }),
  validateSchema(schema, formData) {
    const validate = ajv.compile(schema)
    let valid = validate(formData)
    if (!valid) {
      self.liveValidateSchema = true
    }
    if(self.name === "") {
      self.nameIsValid = false
      valid = false
    }
    return valid
  },
  validateForm() {
    const isExperienceFormValid = self.validateSchema(JSON.parse(self.experienceSelected.schema), JSON.parse(self.experienceData));
    if (self?.getExtendedDataList()?.length) {
      const isExtenedFormValid = self.extendedDataSettingModalState?.validateForm();
      return isExperienceFormValid && isExtenedFormValid;
    }
    return isExperienceFormValid;
  },
  updateCode: flow(function* f() {
    // TODO: validate Input
    if(self.experienceSelected.name==="appLink"){
      const experienceDataObj = JSON.parse(self.experienceData);
      experienceDataObj.fallback = addHttpsIfNeeded(experienceDataObj.fallback )
      experienceDataObj.playStore = addHttpsIfNeeded(experienceDataObj.playStore)
      experienceDataObj.appStore = addHttpsIfNeeded(experienceDataObj.appStore)
      try {
        urlValidator(experienceDataObj.fallback);
        urlValidator(experienceDataObj.playStore);
        urlValidator(experienceDataObj.appStore);
        self.experienceData = JSON.stringify(experienceDataObj);
      } catch (error) {
        new Notification()
          .setType("error")
          .setMessage(error.message)
          .send();
        self.isLoading = false;
        return;
      }
    }
    if(self.experienceSelected.name==="url" || self.experienceSelected.name==="socialMedia") {
      const experienceDataObj = JSON.parse(self.experienceData);
      const url = experienceDataObj.url;
      if (url) {
        experienceDataObj.url = addHttpsIfNeeded(url);
        self.experienceData = JSON.stringify(experienceDataObj);
        try {
          urlValidator(experienceDataObj.url);
        } catch (error) {
          new Notification()
            .setType("error")
            .setMessage(error.message)
            .send();
          self.isLoading = false;
          return;
        }
      }
    }
    const validSchema = self.validateForm()
    if (validSchema) {
    self.isLoading = true
    if(!self.validateDimension()) {
      //TODO: Make this generic for all validations
      new Notification()
        .setType("error")
        .setMessage(`select atleast one dimensions each`)
        .send();
      self.isLoading = false
      return
    }
    const manager = getEnv(self).codeManager
    const resp = yield manager.update(self);
    self.formHasErrors && new Notification()
      .setType("error")
      .setMessage(`${self.name} Code failed updating`)
      .send();
    !self.formHasErrors && new Notification()
      .setType("success")
      .setMessage(`${self.name} Code updated`)
      .send()
    //&& self.urlListener(self.selectedDefaultUrl ? self.selectedDefaultUrl : "hi")
    self.handleHasErrors(false)
    self.isLoading = false
    return resp
    }else{
      return null
    }
  }),
  addRulesToCode(){
    const rules = self.ruleSetModalState.createRuleSetState.grabRuleSetForCode()
    if(rules?.length > 0){
      self.rules = self.ruleSetModalState.noRuleOpen? "[]": rules
      return self.rules
    }else{
      return null
    }
  },
  addUtmToCode(){
    if ( self.utmModalState.noUtmOpen || !self.utmModalState.utm.doesUtmHaveValue()){
      self.utm = Utm.create({uuid:"noUtm"})
    }else{
      self.utm = self.utmModalState.utm.grabUtmForCode()
    }
  },
  addParameterSetToCode() {
    if (!self.parameterSetModalState.noParameterSetOpen) {
      self.parameters.setParameters("codeParamType",self.parameterSetModalState.codeParamsTypeSelectState.selectedCodeParameters.map((param) => param.uuid))
      self.parameters.setParameters("scanParamType",self.parameterSetModalState.scanParamsTypeSelectState.selectedScanParameters.map((param) => param.uuid))
      self.parameters.setParameters("countParamType",self.parameterSetModalState.countParamsTypeSelectState.selectedCountParameters.map((param) => param.uuid))
      self.parameters.setParameters("premiumParamType",self.parameterSetModalState.premiumParamsTypeSelectState.selectedPremiumParameters.map((param) => param.uuid))
      self.parameters.setParameters("customParamType",self.parameterSetModalState.customParamsTypeSelectState.selectedCustomParameters.map((param) => param.uuid))

    } else if (self.parameterSetModalState.noParameterSetOpen || !self.parameterSetModalState.doesParamsHaveValues()) {
      self.parameters.clearParams()
    }
  },
  afterCreate() {
  }
})).views((self) => ({
  configButtonColor(bool) {
    return bool ? "secondary" : "primary"
  },
  configClassName(bool) {
    return bool ? "btn-outline-secondary mr-1 mb-1" : "btn-outline-primary mr-1 mb-1"
  },
  shouldRenderURL(){
    return self.behavior !== codeBehavior.STATIC
  },
  shouldRenderExtendedData() {
    return true; // todo: default for all?
  },
  hasExtendedData() {
    return Object.keys(self.getExtendedDataList())?.length && self.extendedDataSettingModalState?.showDropdown;
  },
  getExtendedDataList() {
    return self.extendedDataList?.filter(n => n);
  },
  getExtendedDataRequest() {
    return self.hasExtendedData() ? {
      "items": self?.getExtendedDataList()?.map((item) => item.uuid),
      "data": self?.experienceExtendedDataView()
    } : {};
  },
  shouldRenderUTMConfig() {
    return (self.experienceSelected.name === codeTypes.URL || self.experienceSelected.name === codeTypes.GS1)
      && self.behavior === codeBehavior.DYNAMIC
  },
  shouldRenderParametersConfig() {
    return (self.experienceSelected.name === codeTypes.URL || self.experienceSelected.name === codeTypes.GS1)
      && self.behavior === codeBehavior.DYNAMIC
  },
  shouldRenderRulesConfig() {
    return (self.experienceSelected.name === codeTypes.URL || self.experienceSelected.name === codeTypes.GS1)
      && self.behavior === codeBehavior.DYNAMIC
  },
  shouldRenderStatusConfig() {
    return self.experienceSelected?.behavior?.includes(codeBehavior.DYNAMIC) && self.behavior === codeBehavior.DYNAMIC
  },
  shouldRenderSecondTabInPreview(){
    return self.behavior === codeBehavior.DYNAMIC
  },
  isCodeTypeSocialMedia() {
    return self.experienceSelected?.name === codeTypes.SocialMedia
  },
  experienceDataView() {
    return JSON.parse(self.experienceData)
  },
  experienceSchemaJson() {
    //TODO: this method is being called everytime code form fields like name or url is changed, needs refactoring
    let {schema} = self.experienceSelected || {}
    console.log("Schema is :" + schema);
    schema = schema || JSON.stringify(defaultSchemaJson);
    return JSON.parse(schema);
  },
  experienceUISchemaView() {
    let {uiSchema} = self.experienceSelected;
    self.updateExperienceUISchema(JSON.stringify({
      ...JSON.parse(uiSchema || '{}'),
      ...JSON.parse(self.experienceUISchema)
    }));
    return JSON.parse(self.experienceUISchema);
  },
  parametersHaveValue() {
    return self.parameters.codeParamType.length > 0
      || self.parameters.scanParamType.length > 0
      || self.parameters.countParamType.length > 0
      || self.parameters.premiumParamType.length > 0
      || self.parameters.customParamType.length > 0
  },
  isStatusNotDefault() {
    return (self.codeStatus != "PUBLISHED" || self.statusViewState.scheduleStatusChange);
  }
}))
