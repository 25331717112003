import React from "react";
import { observer } from "mobx-react-lite";
import { Col, Collapse, CustomInput, FormGroup, Input, Label, Row, Button } from "reactstrap";
import ColorButton from "../../../ColorButton";
import {  useIntl,FormattedMessage} from "react-intl";
import {frames} from "../../../../assets/img/frames/key";
import FrameShapeListItem from "./FrameShapeListItem";
import CaptionList from "./CaptionList";
const FrameAndCaptionsView = observer(({frameAndCaptionsStore, extendConfig, imageCharsStore, colorStore}) => {
    console.log("frameAndCaptionsStore", JSON.stringify(frameAndCaptionsStore))
    const intl = useIntl();
    return (
            <>
                <Row>
                    <Col sm={2}>
                        <h5>Frame</h5>
                    </Col>
                    <Col sm={1}></Col>
                    <Col sm={1}>
                        <div className="move-switch-position">
                    <CustomInput
                        type="switch"
                        id="frame"
                        checked={frameAndCaptionsStore.frame}
                        onChange={() => frameAndCaptionsStore.toggleFrame(imageCharsStore)}
                        />
                            </div>
                    </Col>
                </Row>
                <Collapse isOpen={frameAndCaptionsStore.frame}>
                    <Row>


                        {
                            frames.map((frame) => {
                                return (
                                    <>
                                    <Col sm={1}></Col>
                                    <Col sm={2}>
                                        <FrameShapeListItem FrameAndCaptionsStore={frameAndCaptionsStore}
                                            shape={frame.key}
                                            group="frameSides"
                                            imageClass={frame.image}
                                            imageCharsStore={imageCharsStore}
                                        />
                                    </Col>
                                    </>
                                    );
                            })
                        }

                    </Row>
                    <Row>
                        <Col sm={1}></Col>
                        <Col sm={4}>
                            <FormattedMessage id="qrDesign.create.frameRotation.defaultMessage" />
                        </Col>
                        <Col sm={1}>
                            {/*<div className="move-switch-position">*/}
                            <CustomInput
                                    type="switch"
                                    id="isFrameRotation"
                                    name="isFrameRotation"
                                    checked={frameAndCaptionsStore.frameRotation}
                                    onChange={() => frameAndCaptionsStore.toggleIsFrameRotation()}
                                    />
                            {/*</div>*/}
                            </Col>
                    </Row>
                    <Row>
                        <Col sm={1}></Col>
                        <Col sm={4}>
                            <FormattedMessage id="qrDesign.create.frameColorSameAsModules.defaultMessage"/>
                        </Col>
                        <Col sm={1}>
                            <CustomInput
                                type="switch"
                                id="frameUseForegroundColor"
                                name="frameUseForegroundColor"
                                checked={frameAndCaptionsStore.frameUseForegroundColor}
                                onChange={() => frameAndCaptionsStore.toggleFrameUseForegroundColor()}
                            />
                        </Col>
                        {!frameAndCaptionsStore.frameUseForegroundColor && <>
                            {/*<Col sm={2}>*/}
                            {/*    <FormattedMessage id="qrDesign.create.frameColor.defaultMessage"/>*/}
                            {/*</Col>*/}
                            <Col sm={1}>
                            <ColorButton
                                defaultedColor={frameAndCaptionsStore.frameColor}
                                colorPosition="frameColor"
                                selectColor={frameAndCaptionsStore.handleSelectColor}
                                colorMode={colorStore.colorMode}
                                handleColorMode={colorStore.handleColorMode}
                                isCMYK={colorStore.isCMYK}
                            />
                        </Col>
                        </>}
                    </Row>
                    <Row>
                        <Col sm={1}></Col>
                        <Col sm={4}>
                            <FormattedMessage id="qrDesign.create.fillFrameWithQrModules.defaultMessage" />
                        </Col>
                        <Col sm={1}>
                            <CustomInput
                                type="switch"
                                id="backgroundModules"
                                name="backgroundModules"
                                checked={frameAndCaptionsStore.backgroundModules}
                                onChange={() => frameAndCaptionsStore.toggleFillFrameWithQrModules(colorStore)}
                                />
                        </Col>
                    </Row>
                    {
                        frameAndCaptionsStore.backgroundModules &&
                    <Row>
                        <Col sm={1}></Col>
                        <Col sm={4} style={{paddingLeft:"30px"} }>
                            <FormattedMessage id="qrDesign.create.modulesColorSameAsForeground.defaultMessage" />
                        </Col>
                        <Col sm={1}>
                            <CustomInput
                                type="switch"
                                id="modulesUseForegroundColor"
                                name="modulesUseForegroundColor"
                                checked={frameAndCaptionsStore.modulesUseForegroundColor}
                                onChange={() => frameAndCaptionsStore.toggleModulesUseForegroundColor(colorStore)}
                            />
                        </Col>
                        {!frameAndCaptionsStore.modulesUseForegroundColor && <>
                            {/*<Col sm={1}>*/}
                            {/*<FormattedMessage id="qrDesign.create.modulesColor.defaultMessage" />*/}
                            {/*</Col>*/}
                            <Col sm={1}>
                            <ColorButton
                                defaultedColor={frameAndCaptionsStore.backgroundModulesColor}
                                colorPosition="backgroundModulesColor"
                                selectColor={frameAndCaptionsStore.handleSelectColor}
                                colorMode={colorStore.colorMode}
                                handleColorMode={colorStore.handleColorMode}
                                isCMYK={colorStore.isCMYK}
                            />
                        </Col>
                        </>}
                    </Row>
                    }
                   <Row>
                        <Col sm={1}></Col>
                        <Col sm={4}>
                            <FormattedMessage id="qrDesign.create.frameBackgroundImage.defaultMessage" />
                        </Col>
                        <Col sm={1}>
                            <CustomInput
                                type="switch"
                                id="isFrameBackgroundImage"
                                name="isFrameBackgroundImage"
                                checked={frameAndCaptionsStore.frameBackgroundImage}
                                onChange={() => frameAndCaptionsStore.toggleFrameBackgroundImage()}
                                />
                        </Col>
                    </Row>
                </Collapse>
                <hr/>
                <Row>
                    <Col sm={2}>
                        <h5>
                            <FormattedMessage id="qrDesign.create.captions.captionsTitle.defaultMessage" />
                        </h5>
                    </Col>
                    <Col sm={1}></Col>
                    <Col sm={2}>
                        <div className="move-switch-position">
                    <CustomInput
                        type="switch"
                        id="captions"
                        checked={frameAndCaptionsStore.captions}
                        onChange={() => frameAndCaptionsStore.toggleCaptions(imageCharsStore)}
                        />
                        </div>
                    </Col>
                </Row>
                <Collapse isOpen={frameAndCaptionsStore.captions}>
                    <Row>
                        <Col sm={1}></Col>
                        <Col sm={3}>
                            <FormattedMessage id="qrDesign.create.captions.text.defaultMessage" />
                        </Col>
                    </Row>
                    <Row>

                            <Col sm={1}>
                            </Col>
                            <Col sm={11}>
                                <CaptionList frameAndCaptionsStore={frameAndCaptionsStore} extendConfig={extendConfig} imageCharsStore={imageCharsStore} colorStore={colorStore}/>
                            </Col>


                    </Row>
                    {/* <Row>
                        <Col sm={1}></Col>
                        <Col sm={2}>
                            <FormattedMessage id="qrDesign.create.captions.circular.defaultMessage" />
                        </Col>
                        <Col sm={2}>
                            <CustomInput
                                type="switch"
                                id="isCircularText"
                                checked={frameAndCaptionsStore.circularText}
                                onChange={frameAndCaptionsStore.toggleCircularText}
                                />
                        </Col>
                    </Row> */}
                </Collapse>
            </>
        )
    })
export default FrameAndCaptionsView;



