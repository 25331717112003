import React from "react";
import {UtmsList} from "../../models/utms/UtmsListModel";
import {UtmsListViewState} from "../../components/utms/list/UtmsListViewState";
import async from "../../components/Async";
import IosPaper from "react-ionicons/lib/IosPaper";
import {buildUtmsColumns} from "../../components/utms/list/UtmsListView";
import {RouterState, RouterStore} from "mobx-state-router";
import {CreateUtmState} from "../../components/utms/create/CreateUtmState";
import {EditUtmState} from "../../components/utms/edit/EditUtmState";
import {ModalState} from "../../components/elements/modal/GenericModal";
import {Utm} from "../../models/utms/UtmModel";
import {types} from "mobx-state-tree";

// Page Async Reference, for lazy loading in UI
const UtmsListHome = async(()=> import("../../pages/utms/UtmsListHome"));
const CreateUtmHome = async(()=> import("src/pages/utms/CreateUtmHome.js"));
const EditUtmHome = async(()=> import("src/pages/utms/EditUtmHome.js"));
export const viewMap = {
  utms: <UtmsListHome/>,
  createUtm: <CreateUtmHome/>,
  editUtm: <EditUtmHome/>
};
export const routeDefinitions = [
  {
    path: "utms",
    name: "UTM",
    icon: IosPaper,
    component: UtmsListHome,
    children: null,
    // featureFlagRequired: "cmp_ui_utms"
  },
];
export const routeTransitions = [
  {
    name: "utms",
    pattern: "/configs/utms",
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
      const groupSelectState = rootStore.rootUiStore.groupSelectStore;
      const tagSelectStore = rootStore.rootUiStore.tagSelectStore;
      const utmStore = rootStore.domainStore.utmStore
      tagSelectStore?.tagStore?.init(); //reset tags
      tagSelectStore?.setSelectedTags([]);
      const utmsList = UtmsList.create({
        store:utmStore
      });
      const advFilterSelectState = rootStore.rootUiStore.advFilterSelectState;
      const codeOwnerFilterSelectState = rootStore.rootUiStore.codeOwnerFilterSelectState;
      const dimensionSelectViewState = rootStore.rootUiStore.dimensionSelectViewState;
      const tagFilterSelectState = rootStore.rootUiStore.tagFilterSelectState;
      const groupFilterSelectState = rootStore.rootUiStore.groupFilterSelectState;
      advFilterSelectState.reset();
      codeOwnerFilterSelectState.reset();
      dimensionSelectViewState.reset();
      tagFilterSelectState.reset();
      groupFilterSelectState.reset()
      const modalState = ModalState.create({});
      const utmsListViewState = UtmsListViewState.create({
        listModel: utmsList,
        groupSelectStore: groupSelectState,
        tagSelectStore:tagSelectStore,
        modalStore: modalState,
        advFilterSelectState: advFilterSelectState,
        codeOwnerFilterSelectState: codeOwnerFilterSelectState,
        dimensionSelectViewState: dimensionSelectViewState,
        tagFilterSelectState: tagFilterSelectState,
        groupFilterSelectState: groupFilterSelectState
    });
      utmsListViewState.setColumns(buildUtmsColumns(routerStore, utmsListViewState))
      await rootStore.rootUiStore.attachPageState("utmsListViewState", utmsListViewState);
      utmsListViewState.refresh();
      await  rootStore.rootUiStore.utmsListViewState?.getFilters();

    },
    onExit: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
// const {rootStore} = routerStore.options;
    }
  },
  {
    name: 'createUtm',
    pattern: '/configs/utms/create',
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
      let {queryParams} = toState;
      const groupSelectStore = rootStore.rootUiStore.groupSelectStore;

      const tagSelectStore = rootStore.rootUiStore.tagSelectStore;
      const dimensionsMultiSelectState = rootStore.rootUiStore.dimensionsMultiSelectState;


      const snapShot = (queryParams.q)?rootStore.rootUiStore?.globalCache[`clone-utm:${queryParams.q}`]:null
      const snapShotUtmUuid = snapShot?.uuid;
      let utm;
      let createUtmState;

      if (snapShot && snapShotUtmUuid){
        const utmStore = rootStore.rootUiStore?.utmSelectState?.store
        utm = await utmStore?.getUtm(snapShotUtmUuid);
        groupSelectStore?.selectGroup(snapShot?.owner?.groupUuid);
        tagSelectStore?.setSelectedTags(snapShot.tags);
        dimensionsMultiSelectState.init();
        dimensionsMultiSelectState.setDefaults();
        dimensionsMultiSelectState.getListOnGroup(snapShot?.owner?.groupUuid);

        createUtmState = CreateUtmState.create({
          groupSelectStore: groupSelectStore,
          tagSelectStore: tagSelectStore,
          isExclusivelyGroupOwned: utm.isExclusivelyGroupOwned,
          utm: Utm.create({
            uuid:"createUtm",
            name: "",
            campaign: utm.campaign,
            source: utm.source,
            medium: utm.medium,
            term: utm.term,
            content: utm.content,
          }),
          dimensionsMultiSelectStore: dimensionsMultiSelectState,
        })
      } else {
        groupSelectStore.selectGroup(groupSelectStore.groupStore?.groups[0]);
        tagSelectStore?.tagStore?.init(); //reset tags
        tagSelectStore?.setSelectedTags([]);
        dimensionsMultiSelectState.init();
        dimensionsMultiSelectState.setDefaults();
        dimensionsMultiSelectState.getListOnGroup(groupSelectStore.groupStore?.groups[0]);
        createUtmState = CreateUtmState.create({
          groupSelectStore:groupSelectStore,
          tagSelectStore:tagSelectStore,
          utm: Utm.create({uuid:"createUtm"}),
          dimensionsMultiSelectStore: dimensionsMultiSelectState
        })

      }

      await rootStore.rootUiStore.attachPageState('createUtmState', createUtmState);
      snapShot && snapShotUtmUuid && createUtmState.hydrateDimension(utm?.owner?.dimensions);
    },
    onExit: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
    }
  },
  {
    name: 'editUtm',
    pattern: '/configs/utms/edit/:uuid',
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {params} = toState

      const {rootStore} = routerStore.options;

      const groupSelectState = rootStore.rootUiStore.groupSelectStore;
      const tagSelectStore = rootStore.rootUiStore.tagSelectStore;
      const dimensionsMultiSelectState = rootStore.rootUiStore.dimensionsMultiSelectState;
      dimensionsMultiSelectState.init();
      dimensionsMultiSelectState.setDefaults();

      const editUtmState = EditUtmState.create({
        uuid:params.uuid,
        groupSelectStore: groupSelectState,
        tagSelectStore: tagSelectStore,
        utm: Utm.create({uuid:"createUtm"}),
        dimensionsMultiSelectStore: dimensionsMultiSelectState,
      });

      await rootStore.rootUiStore.attachPageState('editUtmState', editUtmState)
      await editUtmState.fetchWithID()
    }
  },
]
