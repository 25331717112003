import { types } from "mobx-state-tree";
// import { Batch } from "../../models/Batch";

const BatchStore = types.model('BatchStore',{
  cost: types.optional(types.string,'')
}).actions((self)=>({
  searchFor(txt){
    //todo : search for groups with name like 'txt'
  },
  reload(){

  },
  save(group){

  },
  update(group){

  },
  add(data){

  },
  delete(group){

  },
    afterCreate(){
    console.log("created")
    }

}))

export default BatchStore
