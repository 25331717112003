import React from "react";
import {
  Card, CardBody, Col, DropdownItem, InputGroup, InputGroupAddon, Row, Button, Input, CustomInput, Badge
} from "reactstrap";
import {observer} from "mobx-react-lite";
import {FormattedMessage, useIntl} from "react-intl";
import * as Icon from "react-feather";
import ScanbuyTable, {TableColumn} from "../../../tables/ScanbuyTable";
import TableActionsDropdown from "../../../tables/TableActionsDropdown";
import GroupSelect from "../../../elements/groups/GroupSelect";
import dayjs from 'dayjs';
import DeleteParameterSetState from "../delete//DeleteParameterSetState";
import DeleteParameterSetView from "../delete/DeleteParameterSetView";
import GenericModal from "../../../elements/modal/GenericModal";
import {useRouterStore} from "mobx-state-router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleDown, faAngleDoubleUp} from "@fortawesome/free-solid-svg-icons";
import AdvancedConfFilterSelect from "../../../elements/filters/AdvancedConfFilterSelect";
import {buildDimensionFilters} from "../../../../utils/buildDimensionFilters";
import TagSelect from "../../../elements/tags/TagSelect";
import {formConstants} from "../../../../utils/constants";
import {createdByColumn} from "../../../tables/TableColumns";
import {getSnapshot} from "mobx-state-tree";
import TagFilterSelect from "../../../elements/filters/TagFilterSelect";
import GroupFilterSelect from "../../../elements/filters/GroupFilterSelect";


export const buildParameterSetsColumns = (routerStore, parameterSetsListViewState) => {
  const {rootStore} = routerStore.options;
  const c1 = TableColumn.create({name: 'ParameterSet', property: 'parameterSet'});
  c1.setSelector((row) => row.label);
  const c2 = TableColumn.create({name: 'Default', property: 'isDefault'});
  //c2.setSelector((row)=> row.isDefault.toString());

  const c3 = TableColumn.create({
    name: 'Tag(s)', property: 'tags', hide: 'sm'
  });
  c3.setSelector((row) => {
    return row.tags;
  });
  c3.setCell((row) => {
    const tags = row.tags.flatMap((t) => {
      return {uuid: t.uuid, name: t.name, color: t.uiStyleConfigs?.color || 'info'}
    });
    return (<div>
      {tags.map((tag, index) => (<Badge
        key={index}
        color="info"
        className="badge-pill mr-1 mb-1"
      >
        {tag.name}
      </Badge>))}
    </div>)
  })

  const createdBy = createdByColumn();
  const c4 = TableColumn.create({
    name: 'Create Date', property: 'created', width: '15%', hide: 'sm', sortable: true, sortField: 'createdAt',
  });
  c4.setSelector((row) => {
    // console.log(row)
    console.log("row", row);
    const {createdAt} = row || {};
    const formattedCreateDate = dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss');
    return formattedCreateDate;
  });

  const c5 = TableColumn.create({
    name: 'Last Modified', property: 'updated', width: '15%', hide: 'sm', sortable: true, sortField: 'updatedAt',
  });
  c5.setSelector((row) => {
    // console.log(row)
    const {updatedAt} = row || {};
    const formattedUpdateDate = dayjs(updatedAt).format('YYYY-MM-DD HH:mm:ss');
    return formattedUpdateDate;
  });
  const actionColl = TableColumn.create({
    name: 'Actions', property: 'actions', button: true, allowOverflow: true, ignoreRowClick: true
  });
  actionColl.setCell((row) => (
    <TableActionsDropdown actionsList={[
      ...(row.isEditable ? [
      <DropdownItem key={'edit/' + row.uuid}
                    onClick={() => {
                      routerStore.goTo('editParameterSet', {
                        params: {uuid: row.uuid},
                      });
                    }}
      >
        <Icon.Edit/>Edit</DropdownItem>]: []),
      ...(row.isEditable ? [
      <DropdownItem key={'new'} onClick={() => {
        rootStore.rootUiStore.addCloneToCache(`clone-parameterSet:${row.uuid}`,row);
        routerStore.goTo('createParameterSet',{
          queryParams: {q: row.uuid}
        })
      }}><Icon.Copy/>&nbsp;<FormattedMessage id="listCodes.actions.dropdown.clone.defaultMessage"/></DropdownItem>]: []),
      ...(row.isEditable ? [
      <DropdownItem key={row.uuid} value={"Delete " + row.uuid} className="align-text-bottom"
                    onClick={() => {
                      const componentStore = DeleteParameterSetState.create(
                        {
                          parameterSetName: row.name,
                          title: `Delete ${row.name ? row.name : ""}`,
                          uuid: row.uuid
                        });
                      rootStore.rootUiStore.attachPageState('deleteParameterSetStore', componentStore)

                      componentStore.setToggleListener(parameterSetsListViewState.modalStore.toggle)
                      componentStore.setRefreshListener(parameterSetsListViewState.refresh)
                      parameterSetsListViewState.handleRowClick(row, componentStore, DeleteParameterSetView);
                    }}> <Icon.Delete/>&nbsp;<FormattedMessage id="listCodes.actions.dropdown.delete.defaultMessage"/>
      </DropdownItem>]:[])
    ]}/>
  ))

  return [

    c1,
    // c2,
    c3,
    createdBy,
    c4,
    c5,
    actionColl,
  ];
}

const ParameterSetsListView = observer(({parameterSetsListViewState}) => {
    const routerStore = useRouterStore();
    const intl = useIntl();
    console.log("parameterSetStore", parameterSetsListViewState.columns);
    console.log("parameterSetStore", parameterSetsListViewState.store);
    parameterSetsListViewState.setOnSelectChanges()
    parameterSetsListViewState?.advFilterSelectState?.setEmails(parameterSetsListViewState.listModel?.filters?.users);
    parameterSetsListViewState?.tagFilterSelectState?.setTags(parameterSetsListViewState.listModel?.filters?.tags);
    parameterSetsListViewState?.filterGroup && parameterSetsListViewState?.groupFilterSelectState?.setGroups(parameterSetsListViewState?.listModel?.filters?.groups)
    parameterSetsListViewState?.dimensionSelectViewState?.setFilteredDimensions(parameterSetsListViewState?.listModel?.filters.dimensions);


    const customStyles = {
      headRow: {
        style: {
          border: 'none',
        },
      },
      headCells: {
        style: {
          color: '#202124',
          fontSize: '14px',
        },
      },
      rows: {
        highlightOnHoverStyle: {
          backgroundColor: 'rgb(230, 244, 244)',
          borderBottomColor: '#FFFFFF',
// borderRadius: '5px',
          outline: '1px solid #FFFFFF',
        },
      },
      pagination: {
        style: {
          border: 'none',
        },
      },
    }

    return (
      <>
        <Card>
          <CardBody>
            <Row>
              <Col className="text-right">
                {parameterSetsListViewState.isFilterVisible ? <Button color="primary" onClick={() => {
                    parameterSetsListViewState.toggleFilterVisible()
                  }}><FontAwesomeIcon icon={faAngleDoubleUp}/></Button>
                  : <Button color="primary" onClick={() => {
                    parameterSetsListViewState.toggleFilterVisible()
                  }}><FontAwesomeIcon icon={faAngleDoubleDown}/></Button>}
              </Col>
            </Row>
            {parameterSetsListViewState?.isFilterVisible && <>
              <Row>
                <Col sm={5}>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      {intl.formatMessage({id: "filterByGroups.defaultMessage"})}
                    </InputGroupAddon>
                    {/*<CodeOwnerFilterSelect codeOwnerFilterSelectState={designsListState.codeOwnerFilterSelectState}/>*/}
                    {/*<GroupSelect groupSelectStore={parameterSetsListViewState.groupSelectStore} multi={true}/>*/}
                    <GroupFilterSelect groupFilterSelectState={parameterSetsListViewState.groupFilterSelectState}/>
                  </InputGroup>
                </Col>
                <Col sm={5}>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      {intl.formatMessage({id: "listCodes.filter.filterName.defaultMessage"})}
                    </InputGroupAddon>
                    <AdvancedConfFilterSelect advFilterSelectState={parameterSetsListViewState.advFilterSelectState}/>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                {parameterSetsListViewState?.dimensionSelectViewState?.dimensionsEnabled && parameterSetsListViewState?.dimensionSelectViewState?.dimensionSelectStates?.map(dimensionSelectStore => buildDimensionFilters(dimensionSelectStore))}
              </Row>
              <Row>
                <Col sm={5}>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      {intl.formatMessage({id: "listCodes.searchInput.defaultMessage"})}
                    </InputGroupAddon>
                    <Input
                      type="text"
                      name="searchInput"
                      placeholder="Search"
                      onChange={parameterSetsListViewState.onSearchChange}
                    />
                  </InputGroup>
                </Col>
                <Col sm={5}>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      {intl.formatMessage({id: "listCodes.filterBytags.defaultMessage"})}
                    </InputGroupAddon>
                    {/*<TagSelect tagSelectStore={parameterSetsListViewState?.tagSelectStore}/>*/}
                    <TagFilterSelect tagFilterSelectState={parameterSetsListViewState.tagFilterSelectState}/>
                  </InputGroup>
                </Col>
                <Col sm={1}>
                  <CustomInput
                    type="switch"
                    id="isFilterTypeOR"
                    name="isFilterTypeOR"
                    label={formConstants.Mode.OR}
                    checked={parameterSetsListViewState.isFilterTypeOR}
                    onChange={() => parameterSetsListViewState.selectFilterMethod()}
                  />
                </Col>
                <Col sm={1} className="justify-content-end text-right">
                  <Button color="primary" onClick={() => {
                    routerStore.goTo('createParameterSet', {});
                  }}>
                    <FormattedMessage id="qrDesign.manageList.create.defaultMessage"/>
                  </Button>
                </Col>
              </Row>
            </>}
            <Row>
              <Col>
                {parameterSetsListViewState.listModel.store.list.length ?
                  <ScanbuyTable
                    columns={parameterSetsListViewState.columns}
                    customStyles={customStyles}
                    store={parameterSetsListViewState.listModel}
                  />
                  :
                  <ScanbuyTable
                    columns={parameterSetsListViewState.columns}
                    customStyles={customStyles}
                    store={parameterSetsListViewState.listModel}
                  />
                }
              </Col>
            </Row>
          </CardBody>
        </Card>
        <GenericModal
          modalStore={parameterSetsListViewState.modalStore}
          componentStore={parameterSetsListViewState.modalComponentStore}
          EmbedComponent={parameterSetsListViewState.modalComponent}
        />
      </>
    )
  }
)

export default ParameterSetsListView
