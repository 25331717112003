import React from "react";
import { observer } from "mobx-react-lite";
import AsyncSelect from "react-select/async";

const QrConfigSelect = observer(({ store }) => {
  return (
    <AsyncSelect
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      menuPlacement="top"
      menuPosition='fixed'
      sm={10}
      className="react-select-container"
      classNamePrefix="react-select"
      loadOptions={store?.configOptionLoadValues}
      //onInputChange={(inputValue) => store?.configOptionLoadValues(inputValue)}
      defaultOptions={store?.configOptionValues()}
      //cacheOptions
      id="selectedGroup"
      value={store.selectedConfig || null}
      //inputValue={createCodeInput.selectedGroup}
      isSearchable
      onChange={(data) => store?.selectConfig(data)}
      getOptionLabel={option => option.label}
      getOptionValue={option => option.uuid}
      placeholder="Select"
      isClearable
    />
  );
});

export default QrConfigSelect;
