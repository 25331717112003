import React from "react";
import { observer } from "mobx-react-lite";
import Select from "react-select";

const RuleSetSelectView = observer(({ store }) => {
    return (<>
      <Select
            sm={10}
            className="react-select-container"
            classNamePrefix="react-select"
            options={store?.options()}
            id="selected"
            value={store?.selected}
            onInputChange={(data) => store?.search(data)}
            onChange={(data) => store?.select(data)}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.uuid}
            placeholder="Select"
          />
      </>
    );
});

export default RuleSetSelectView;
