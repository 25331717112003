import {flow, getEnv, types} from "mobx-state-tree";
import {GroupSelectState} from "../../../elements/groups/GroupSelectState";
import {ParameterSetSelectState} from "../../../elements/parameterSets/ParameterSetSelectState";
import {CodeParamsTypeSelectState} from "../../../elements/parameters/codeType/CodeParamsTypeSelectState";
import {ScanParamsTypeSelectState} from "../../../elements/parameters/scanType/ScanParamsTypeSelectState";
import {CountParamsTypeSelectState} from "../../../elements/parameters/countType/CountParamsTypeSelectState";
import {PremiumParamsTypeSelectState} from "../../../elements/parameters/premiumType/PremiumParamsTypeSelectState";
import {CustomParamsTypeSelectState} from "../../../elements/parameters/customType/CustomParamsTypeSelectState";


export const ParameterSetModalState = types.model("ParameterSetModalState", {
  isLoading: types.optional(types.boolean, false),
  groupSelectStore: types.maybe(types.reference(GroupSelectState)),
  noParameterSetOpen:types.optional(types.boolean, false),
  customOpen:types.optional(types.boolean, true),
  parameterSetSelectState:types.maybe(types.reference(ParameterSetSelectState)),

  codeParamsTypeSelectState: types.maybe(CodeParamsTypeSelectState),
  scanParamsTypeSelectState: types.maybe(ScanParamsTypeSelectState),
  countParamsTypeSelectState: types.maybe(CountParamsTypeSelectState),
  premiumParamsTypeSelectState: types.maybe(PremiumParamsTypeSelectState),
  customParamsTypeSelectState: types.maybe(CustomParamsTypeSelectState),

})
  .actions(self => {
    return ({

      setNoneOpen() {
        self.noParameterSetOpen = true
        self.presetsOpen = false
        self.customOpen = false
      },
      setCustomOpen() {
        self.noParameterSetOpen = false
        self.presetsOpen = false
        self.customOpen = true
      },
      consumeParameterSetSelect() {
        self.parameterSetSelectState.setConsumer(self.fetchParameterSetWithID)
      },
      hydrateForm(parameterSet) {
        self.codeParamsTypeSelectState.selectParameters(parameterSet.parameters?.codeParamType)
        self.scanParamsTypeSelectState.selectParameters(parameterSet.parameters?.scanParamType)
        self.countParamsTypeSelectState.selectParameters(parameterSet.parameters?.countParamType)
        self.premiumParamsTypeSelectState.selectParameters(parameterSet.parameters?.premiumParamType)
        self.customParamsTypeSelectState.selectParameters(parameterSet.parameters?.customParamType)
      },
      fetchParameterSetWithID: flow(function* f(uuid) {
        self.isLoading = true
        const parameterSetManager = getEnv(self).parameterSetManager
        const res = yield parameterSetManager.getParameterSet(uuid)
        const parameterSet = {
          parameters:res
        }
        self.hydrateForm(parameterSet)
        self.isLoading = false
      }),
      mapParametersToSelectState(params) {
        params.forEach(p => {
          switch (p?.paramType) {
            case "codeParams":
              self.codeParamsTypeSelectState.store?.setList(p?.items)
              break;
            case "scanParams":
              self.scanParamsTypeSelectState.store?.setList(p?.items)
              break;
            case "countParams":
              self.countParamsTypeSelectState.store?.setList(p?.items)
              break;
            case "premiumParams":
              self.premiumParamsTypeSelectState.store?.setList(p?.items)
              break;
            case "customParams":
              self.customParamsTypeSelectState.store?.setList(p?.items)
              break;
            default:
              break;
          }
        })
      },
      fetchAllParameters: flow(function* f() {
        const manager = getEnv(self).parametersManager
        const resp = yield manager.fetchAll();
        if (resp?.results) {
          self.mapParametersToSelectState(resp?.results)
        }
      }),
    });
  }).views((self) => ({
    doesParamsHaveValues() {
      return self.codeParamsTypeSelectState.selectedCodeParameters.length > 0 || self.scanParamsTypeSelectState.selectedScanParameters.length > 0
    }
  }))
// toastr.error(`Failure`, `${self.errorMessage}`)
