import React from 'react';
import { flow, types } from 'mobx-state-tree'
import { TagListModel } from '../../../models/tags/Tag'
import { GroupSelectState } from '../../elements/groups/GroupSelectState';
import { TagSelectState } from '../../elements/tags/TagSelectState';
import Group from '../../../models/Group';
import { TagModel as Tag } from "../../../models/tags/TagModel";
import Notification from "../../../utils/Notification";
import lodash from "lodash";
import {DimensionsMultiSelectState} from "../../elements/dimensions/multi-select/dimensionsMultiSelectState";

export const TagFormState = types.model("TagFormState", {
  title: types.optional(types.string, ""),
  name: types.optional(types.string, ""),
  groupSelectStore: types.maybe(types.late(() => types.reference(GroupSelectState))),
  tagSelectStore: types.maybe(types.late(() => types.reference(TagSelectState))),
  selectedGroup: types.maybe(types.reference(Group)),
  store: types.maybe(types.late(() => TagListModel)),
  selectedTags: types.optional(types.array(types.string), []),
  formHasErrors: types.optional(types.boolean, false),
  errorMessage: types.optional(types.string, "Null"),
  isExclusivelyGroupOwned: types.optional(types.boolean, false),
  dimensionsMultiSelectStore: types.maybeNull(types.late(() => types.reference(DimensionsMultiSelectState))),
  dimensionAll: types.optional(types.boolean, true),
  isDimensionSelectVisible: types.optional(types.boolean, false),
    //types.optional(types.array(types.reference(Tag, {acceptsUndefined: true})), []),
}).volatile((self)=>({
  handleSubmit: types.fn,
  handleCancel: types.fn
})).actions((self)=> ({
  setOnSelectChanges() {
    self.groupSelectStore?.setConsumer(self.setSelectedGroup)
    self.tagSelectStore?.setConsumer(self.setSelectedTags)
  },
  setSelectedTags: function (tagsArr) {
    self.selectedTags = tagsArr.flatMap((tag)=> tag?.uuid)
  },
  setHandleSubmit: (fn) => {
    self.handleSubmit = fn;
  },
  setHandleCancel: (fn) => {
    self.handleCancel = fn;
  },
  selectGroup(obj) {
    self.selectedGroup = obj;
    self.tagSelectStore?.tagStore?.setFilterOn(obj?.uuid);
    self.dimensionsMultiSelectStore?.getListOnGroup(obj?.uuid);
  },
  setSelectedGroup(obj) {
    self.selectedGroup = obj;
    self.tagSelectStore?.tagStore?.setFilterOn(obj?.uuid);
    self.dimensionsMultiSelectStore?.getListOnGroup(obj?.uuid);
  },
  handleHasErrors(boolean) {
    self.formHasErrors = boolean
  },
  handleErrorMessage(message) {
    self.errorMessage = message
  },
  toggleIsExclusivelyGroupOwned(){
    self.isExclusivelyGroupOwned = !self.isExclusivelyGroupOwned
  },
  toggleDimensionAll(){
    self.dimensionAll = !self.dimensionAll
    self.toggleIsDimensionSelectVisible(!self.dimensionAll)
    self.dimensionAll ? self.dimensionsMultiSelectStore.selectAllDimensions() : self.dimensionsMultiSelectStore.reset()
  },
  toggleIsDimensionSelectVisible(visible){
    self.isDimensionSelectVisible = visible
  },
  hydrateDimension(dimensions = []){
    const isDimensionAll = Object.values(dimensions).every(item => lodash.isEmpty(item));
    self.dimensionAll = !isDimensionAll
    self.toggleDimensionAll()
    if(!self.dimensionAll){
      self.dimensionsMultiSelectStore.hydrateDimensions(dimensions)
    }
  },
  get: flow(function* f(uuid) {
    return yield self.store.get(uuid)
  }),
  add: flow(function* f(data) {
    const resp = yield self.store.add(data, self)
    console.log("response", resp);
    if(resp.data) {
      self.store.tagStore.appendTagModel(resp.data)
    }
    self.formHasErrors && new Notification()
      .setType("error")
      .setMessage(`${resp?.data?.name} Tag failed creating`)
      .send();
    !self.formHasErrors && new Notification()
      .setType("success")
      .setMessage(`${resp?.data?.name} Tag created`)
      .send();
    return resp;
  }),
  // add(data) {
  //   yield self.store.add(data)
  // },
  update: flow(function* f(uuid, data) {
    const resp =  yield self.store.put(uuid, data, self);
    console.log("resp", resp);
    self.formHasErrors && new Notification()
      .setType("error")
      .setMessage(`${resp?.name} Tag failed updating`)
      .send();
    !self.formHasErrors && new Notification()
      .setType("success")
      .setMessage(`${resp?.name} Tag updated`)
      .send();
    return resp;
  }),
  delete: flow(function* f(uuid) {
    yield self.store.delete(uuid)
    self.store.tagStore.removeTag(uuid)
    self.store.fetchAll()
  }),
  hydrateForm: flow(function* f(uuid) {
    const tag = yield self.store.get(uuid)
    self.name = tag?.name
    self.groupSelectStore.selectGroup(tag.owner?.groupUuid)
    self.dimensionsMultiSelectStore?.getListOnGroup(tag.owner?.groupUuid);
    self.tagSelectStore.setSelectedTags(tag?.tags)
    self.setSelectedTags(tag?.tags);
    self.isExclusivelyGroupOwned = tag?.isExclusivelyGroupOwned
    self.hydrateDimension(tag?.owner?.dimensions);
  }),
}))
