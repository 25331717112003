import {flow, getRoot, resolveIdentifier, types} from "mobx-state-tree";
import CodeListState from "../components/codes/list/CodeListState";
import {GroupSelectState} from "../components/elements/groups/GroupSelectState";
import {TagSelectState} from "../components/elements/tags/TagSelectState";
import CreateQrFromTemplateState from "../components/qrTemplates/CreateQrFromTemplateState";
import GroupStore from "./domain/GroupStore";
import CreateCode from "../components/codes/create/CreateCodeDomain";
import {CodeTypeSelectState} from "../components/elements/codeTypes/CodeTypeSelectState";
import CodeTypeStore from "./domain/CodeTypeStore";
import BatchListState from "../components/codes/batches/BatchListState";
import DesignerState from "../components/qr/designer/DesignerState";
import QrPreviewState from "../components/qr/qrpreview/QrPreviewState";
import {QrConfigSelectState} from "../components/elements/qrconfig/QrConfigSelectState";
import {QrConfigStore} from "./domain/QrConfigStore";
import {DesignsListState} from "../components/qr/qrList/DesignsListState";
import ReportsState from "../components/reports/ReportsState"
import SupersetState from "../components/reports/superset/SupersetState";
import {DeleteDesignState} from "../components/qr/deleteDesign/DeleteDesignState";
import TagsListViewState from "../components/configs/tags/TagsListViewState";
import {TagFormState} from '../components/configs/tags/TagFormState';
import {EditCodeState} from "../components/codes/edit/EditCodeState";
import {PublishDomainSelectState} from "../components/elements/publishDomain/PublishDomainSelectState";
import PublishDomainStore from "./domain/PublishDomainStore";
import {BatchFilterSelectState} from '../components/elements/filters/BatchFilterSelectState';
import BatchItemListState from "src/components/codes/batches/items/BatchItemListState";
import TagStore from './domain/TagStore';
import {CodeFilterSelectState} from "../components/elements/filters/CodeFilterSelectState";
import DomainsListViewState from "../components/configs/domains/list/DomainsListViewState";
import {PageTemplateSelectState} from "../components/elements/pageTemplate/PageTemplateSelectState";
import {PageTemplateStore} from "./domain/PageTemplateStore";
import {RuleSetsListViewState} from "../components/configs/rulesets/list/RuleSetsListViewState";
import {CreateRuleSetState} from "../components/configs/rulesets/create/CreateRuleSetState";
import {RuleSetsStore} from "./domain/RuleSetsStore";
import {RuleTypeSelectState} from "../components/elements/ruleType/RuleTypeSelectState";
import RuleTypeStore from "./domain/RuleTypeStore";
import DeleteRuleSetState from "../components/configs/rulesets/delete/DeleteRuleSetState";
import {UtmsListViewState} from '../components/utms/list/UtmsListViewState'
import {CreateUtmState} from "../components/utms/create/CreateUtmState";
import {EditUtmState} from "../components/utms/edit/EditUtmState";
import DeleteUtmState from "../components/utms/delete/DeleteUtmState";
import DeleteDataExportState from "../components/data/exports/modal/DeleteDataExportState";
import {RuleSetSelectState} from "../components/elements/ruleSets/RuleSetSelectState";
import {UtmSelectState} from "../components/elements/utms/UtmSelectState";
import UtmStore from "./domain/UtmStore";
import {DashboardViewState} from "../components/dashboard/dashboardViewState";
import DimensionStore from "./domain/DimensionStore";
import {DimensionSelectViewState} from "../components/elements/dimensions/dimensionSelectViewState";
import {CodeOwnerFilterSelectState} from "../components/elements/filters/CodeOwnerFilterSelectState";
import {PreviewCodeAndEditDesignState} from "../components/codes/view/PreviewCodeAndEditDesignState";
import QrTemplateStore from "./domain/QrTemplateStore";
import {QrTemplateSelectState} from "../components/elements/qrtemplates/QrTemplateSelectState";
import {DimensionsMultiSelectState} from "../components/elements/dimensions/multi-select/dimensionsMultiSelectState";
import {ParameterSetsListViewState} from '../components/configs/parametersets/list/ParameterSetsListViewState';
import DeleteParameterSetState from '../components/configs/parametersets/delete/DeleteParameterSetState';
import {CreateParameterSetState} from "../components/configs/parametersets/create/CreateParameterSetState";
import {EditParameterSetState} from "../components/configs/parametersets/edit/EditParameterSetState";
import {ParameterSetSelectState} from "../components/elements/parameterSets/ParameterSetSelectState";
import ParameterSetStore from "./domain/ParameterSetStore";
import CodeParamsStore from "./domain/parameters/CodeParamsStore";
import ScanParamsStore from "./domain/parameters/ScanParamsStore";
import CountParamsStore from "./domain/parameters/CountParamsStore";
import PremiumParamsStore from "./domain/parameters/PremiumParamsStore";
import CustomParamsStore from "./domain/parameters/CustomParamsStore";
import UserStore from "./domain/UserStore";
import {AdvancedConfFilterSelectState} from "../components/elements/filters/AdvancedConfFilterSelectState";
import {TagFilterSelectState} from "../components/elements/filters/TagFilterSelectState"
import DeleteTagState from "../components/configs/tags/TagDeleteState";
import {GroupFilterSelectState} from "../components/elements/filters/GroupFilterSelectState"
import {VCardViewState} from "@scanbuy-inc/cmp_v2_pages/vcard/src/components/VCardViewState";
import DataExportFormState from "../components/data/exports/form/DataExportFormState";
import {DataExportTypeSelectState} from "../components/elements/dataExportType/DataExportTypeSelectState";
import DataExportTypeStore from "./domain/DataExportTypeStore";
import DataExportListViewState from "../components/data/exports/list/DataExportListViewState";
import {DataExportFilterSelectState} from "../components/elements/filters/data/exports/DataExportFilterSelectState";
import DomainDetailViewState from "../components/configs/domains/detail/DomainDetailViewState";
import ExtendedDataListViewState from "../components/extendedData/list/ExtendedDataListViewState";
import ExtendedDataSetFormState from "../components/extendedData/ExtendedDataSetFormState";
import {ExtendedDataSelectState} from "../components/extendedData/ExtendedDataSelectState";
import ExtendedDataStore from "./domain/ExtendedDataStore";
import ExtendedDataFormState from "../components/extendedData/ExtendedDataFormState";
import {ExtendedDataTypeSelectStore} from "../components/extendedData/ExtendedDataTypeSelectStore";
import ExtendedDataTypeStore from "./domain/ExtendedDataTypeStore";
import ModalActionState from "../components/extendedData/modal/ModalActionState";
import ExtendedDataSetStore from "./domain/ExtendedDataSetStore";
import {ExtendedDataSetSelectState} from "../components/extendedData/ExtendedDataSetSelectState";
/*--YEOMAN-UI-IMPORTS--*/


export const RootUiState = types.model('RootUiState',{
  //states for each page
  codeListViewState: types.maybe(types.late(() => CodeListState)),
  dataExportListViewState: types.maybe(types.late(() => DataExportListViewState)),
  dataExportFormState: types.maybe(types.late(() => DataExportFormState)),
  createQrFromTemplateState:types.maybe(types.late(()=>CreateQrFromTemplateState)),
  createCodeViewState: types.maybe(types.late(() => CreateCode)),
  editCodeState: types.maybe(types.late(() => EditCodeState)),
  deleteDesignState:types.maybe(types.late(() => DeleteDesignState)),

  batchListViewState: types.maybe(types.late(() => BatchListState)),
  batchItemListViewState: types.maybe(types.late(() => BatchItemListState)),
  designsListState: types.maybe(types.late(() => DesignsListState)),
  designerViewState: types.maybe(types.late(() => DesignerState)),
  editDesignerViewState:types.maybe(types.late(() => DesignerState)),

  qrPreviewStore:types.maybe(types.late(() => QrPreviewState)),
  pageTemplateSelectStore:types.maybe(types.late(() => PageTemplateSelectState)),
  qrConfigSelectStore:types.maybe(types.late(() => QrConfigSelectState)),
  qrConfigStore:types.maybe(types.late(() => QrConfigStore)),
  groupSelectStore: types.maybe(types.late(() => GroupSelectState)),
  tagSelectStore: types.maybe(types.late(()  => TagSelectState)),
  qrtemplateSelectStore: types.maybe(types.late(() => QrTemplateSelectState)),
  codeTypeSelectStore: types.maybe(types.late(()=> CodeTypeSelectState)),
  dataExportFilterSelectState: types.maybe(types.late(()=> DataExportFilterSelectState)),
  dataExportTypeSelectStore: types.maybe(types.late(() => DataExportTypeSelectState)),
  codeFilterSelectState: types.maybe(types.late(() => CodeFilterSelectState)),
  advFilterSelectState: types.maybe(types.late(()=> AdvancedConfFilterSelectState)),
  tagFilterSelectState: types.maybe(types.late(() => TagFilterSelectState)),
  groupFilterSelectState: types.maybe(types.late(() => GroupFilterSelectState)),
  codeOwnerFilterSelectState: types.maybe(types.late(() => CodeOwnerFilterSelectState)),
  reportsViewState: types.maybe(types.late(() => ReportsState)),
  supersetViewState: types.maybe(types.late(() => SupersetState)),
  batchFilterSelectState: types.maybe(types.late(() => BatchFilterSelectState)),
  publishDomainSelectStore: types.maybe(types.late(()=> PublishDomainSelectState)),
  domainsListViewState: types.maybe(types.late(()=> DomainsListViewState)),
  domainDetailViewState: types.maybe(types.late(()=> DomainDetailViewState)),
  extendedDataSelectStore: types.maybe(types.late(()=> ExtendedDataSelectState)),
  extendedDataSetSelectStore: types.maybe(types.late(()=> ExtendedDataSetSelectState)),
  extendedDataTypeSelectStore: types.maybe(types.late(()=> ExtendedDataTypeSelectStore)),
  extendedDataListViewState: types.maybe(types.late(()=> ExtendedDataListViewState)),
  extendedDataSetFormState: types.maybe(types.late(() => ExtendedDataSetFormState)),
  extendedDataFormState: types.maybe(types.late(() => ExtendedDataFormState)),
  modalActionState: types.maybe(types.late(() => ModalActionState)),

  tagsListViewState: types.maybe(types.late(() => TagsListViewState)),
  tagFormState: types.maybe(types.late(() => TagFormState)),

  createRuleSetState:types.maybe(types.late(() => CreateRuleSetState)),
  ruleSetsListViewState: types.maybe(types.late(() => RuleSetsListViewState)),
  ruleTypeSelectState: types.maybe(types.late(() => RuleTypeSelectState)),
  ruleSetSelectState:types.maybe(types.late(() => RuleSetSelectState)),
  ruleSetsStore: types.maybe(types.late(() => RuleSetsStore)),

  deleteRuleSetStore:types.maybe(types.late(() => DeleteRuleSetState)),

  utmsListViewState:types.maybe(types.late(() => UtmsListViewState)),
  createUtmState: types.maybe(types.late(() => CreateUtmState)),
  editUtmState:types.maybe(types.late(() => EditUtmState)),
  deleteUtmState:types.maybe(types.late(() => DeleteUtmState)),
  deleteDataExportState: types.maybe(types.late(() => DeleteDataExportState)),
  utmSelectState:types.maybe(types.late(() => UtmSelectState)),
  previewCodeAndEditDesignState:types.maybe(types.late(() => PreviewCodeAndEditDesignState)),

  dashboardViewState: types.maybe(types.late( () => DashboardViewState)),

  dimensionSelectViewState: types.maybe(types.late( () => DimensionSelectViewState)),
  dimensionsMultiSelectState: types.maybe(types.late(() => DimensionsMultiSelectState)),

  deleteParameterSetStore:types.maybe(types.late(() => DeleteParameterSetState)),

  parameterSetsListViewState:types.maybe(types.late(() => ParameterSetsListViewState)),
  parameterSetSelectState:types.maybe(types.late(() => ParameterSetSelectState)),
  createParameterSetState:types.maybe(types.late(() => CreateParameterSetState)),
  editParameterSetState:types.maybe(types.late(() => EditParameterSetState)),
  codeParamsStore:types.maybe(types.late(() => CodeParamsStore)),
  scanParamsStore:types.maybe(types.late(() => ScanParamsStore)),
  countParamsStore:types.maybe(types.late(() => CountParamsStore)),
  premiumParamsStore:types.maybe(types.late(() => PremiumParamsStore)),
  customParamsStore:types.maybe(types.late(() => CustomParamsStore)),
  deleteTagState:types.maybe(types.late(() => DeleteTagState)),
  vCardViewState: types.maybe(types.late(() => VCardViewState)),

  dimensionsFilterMultiSelectStore: types.maybe(types.late(() => DimensionsMultiSelectState)),

  /*--YEOMAN-ADD-STATE--*/
  // rootNode: types.maybe(types.late(() => TreeNode)),

}).volatile((self)=>({
  globalCache: types.undefined //used to store a row that is being cloned

})).actions((self)=> ({
  attachPageState(pageName, pageInstance){
    if(self[pageName]){
      //delete self[pageName];
      console.log(`${pageName} already attached??`)
    }

    self[pageName] = pageInstance
  },
  addCloneToCache(key,data){
    self.globalCache = {[key]: data}
  },
  detachPageState(pageName) {
    self[pageName] = undefined
  },
  init: flow(function* f() {
    const root = getRoot(self)

    self.groupSelectStore =  GroupSelectState.create({
      selectStateUuid: 'gss',
      groupStore: resolveIdentifier(GroupStore, root, 'g')
    });

    self.dimensionSelectViewState = DimensionSelectViewState.create({
      selectStateUuid: 'dsvs',
      store: resolveIdentifier(DimensionStore, root, 'd')
    })

    self.tagFilterSelectState = TagFilterSelectState.create({
      selectStateUuid: "tfs",
    })

    self.groupFilterSelectState = GroupFilterSelectState.create({
      selectStateUuid: "gfs"
    })


    self.codeTypeSelectStore = CodeTypeSelectState.create({
      selectStateUuid: 'ctss',
      store: resolveIdentifier(CodeTypeStore, root, 'cts')
    })
    self.dataExportTypeSelectStore = DataExportTypeSelectState.create({
      selectStateUuid: 'detss',
      store: resolveIdentifier(DataExportTypeStore, root, 'dets')
    })

    self.extendedDataTypeSelectStore = ExtendedDataTypeSelectStore.create({
      selectStateUuid: 'edtss',
      store: resolveIdentifier(ExtendedDataTypeStore, root, 'edts')
    });

    self.extendedDataSetSelectStore = ExtendedDataSetSelectState.create({
      selectStateUuid: 'edsetss',
      store: resolveIdentifier(ExtendedDataSetStore, root, "edsets")
    });

    self.extendedDataSelectStore = ExtendedDataSelectState.create({
      selectStateUuid: 'edss',
      store: resolveIdentifier(ExtendedDataStore, root, "eds")
    });

    self.qrConfigSelectStore = QrConfigSelectState.create({
      selectStateUuid: 'qrcss',
      configStore: resolveIdentifier(QrConfigStore, root, 'qrcs')
    })
    self.pageTemplateSelectStore = PageTemplateSelectState.create({
      selectStateUuid: 'ptss',
      configStore: resolveIdentifier(PageTemplateStore, root, 'pts')
    })
    self.publishDomainSelectStore = PublishDomainSelectState.create({
      selectStateUuid: 'pdss',
      publishDomainStore: resolveIdentifier(PublishDomainStore, root, 'pds')
    })
    self.ruleTypeSelectState =RuleTypeSelectState.create({
      selectStateUuid:"rtss",
      store: resolveIdentifier(RuleTypeStore, root, 'rts')
    })
    self.ruleSetSelectState =RuleSetSelectState.create({
      selectStateUuid:"rsss",
      store: resolveIdentifier(RuleSetsStore, root, 'rss')
    })
    self.utmSelectState =UtmSelectState.create({
      selectStateUuid:"utmss",
      store: resolveIdentifier(UtmStore, root, 'utms')
    })
    self.parameterSetSelectState =ParameterSetSelectState.create({
      selectStateUuid:"psss",
      store: resolveIdentifier(ParameterSetStore, root, 'pss')
    })
    const tagStore = resolveIdentifier(TagStore, root, 'ts');
    self.tagSelectStore = TagSelectState.create({
      selectStateUuid:'tss',
      tagStore: tagStore
    })
    self.qrtemplateSelectStore =  QrTemplateSelectState.create({
      selectStateUuid: 'tmpss',
      qrtemplateStore: resolveIdentifier(QrTemplateStore, root, 'tms')
    });

    self.codeFilterSelectState = CodeFilterSelectState.create({
      selectStateUuid: "cfss",
      codeTypeStore: resolveIdentifier(CodeTypeStore, root, 'cts'),
      publishDomainStore: resolveIdentifier(PublishDomainStore, root, 'pds'),
      userStore: resolveIdentifier(UserStore, root, 'us')
    })

    self.dataExportFilterSelectState = DataExportFilterSelectState.create({
      selectStateUuid: "defss",
      userStore: resolveIdentifier(UserStore, root, 'us')
    });

    self.advFilterSelectState = AdvancedConfFilterSelectState.create({
      selectStateUuid: "adv",
      userStore: resolveIdentifier(UserStore, root, 'us')
    })

    self.codeOwnerFilterSelectState = CodeOwnerFilterSelectState.create({
      selectStateUuid: "cofss",
      groupStore: resolveIdentifier(GroupStore, root, 'g'),
      dimensionStore: resolveIdentifier(DimensionStore, root, 'd')
    })

    self.dimensionsMultiSelectState = DimensionsMultiSelectState.create({
      selectStateUuid: "dmss",
      dimensionStore: resolveIdentifier(DimensionStore, root, 'd')
    })

    self.dimensionsFilterMultiSelectStore = DimensionsMultiSelectState.create({
      selectStateUuid: "dfmss",
      dimensionStore: resolveIdentifier(DimensionStore, root, 'd')
    });

    self.batchFilterSelectState = BatchFilterSelectState.create({
      selectStateUuid: "bfss",
    })
    console.log("RootUIState init end.")
  }),
  setAnyDefaultListSelects(){
    self.codeTypeSelectStore?.initDefaults();
    self.dimensionSelectViewState.init()
    //self.publishDomainSelectStore?.initDefaults();
  },
  afterCreate(){
    console.log('AfterCreate RootUiState')

  }
}))




