import {flow, getEnv, types} from "mobx-state-tree";
import ColorState from "./color/ColorState";
import EyesAndModulesState from "./eyesAndModules/EyesAndModulesState";
import LogoState from "./logo/LogoState";
import {QrConfigSelectState} from "../../elements/qrconfig/QrConfigSelectState";
import ImageAndCharacteristicsState from "./imageAndCharacteristics/ImageAndCharacteristicsState";
import {GroupSelectState} from "../../elements/groups/GroupSelectState";
import FrameAndCaptionsState from "./frameAndCaption/FrameAndCaptionsState";
import {DimensionsMultiSelectState} from "../../elements/dimensions/multi-select/dimensionsMultiSelectState";
import lodash from "lodash";
import Notification from "../../../utils/Notification";
import QrPreviewState from "../qrpreview/QrPreviewState";
import PreviewManager from "../../../managers/preview/PreviewManager";
import HttpClient from "../../../services/HttpClient";
import {scannabilityCheck} from "../../../utils/scannability";
import {TagSelectState} from "../../elements/tags/TagSelectState";

const DesignerState = types.model('DesignerState', {
  isLoading: types.optional(types.boolean, false),
  isEditForm: types.optional(types.boolean, false),
  isTemplateForm: types.optional(types.boolean, false),
  isCMYK: types.optional(types.boolean, false),

  logoOpen: types.optional(types.boolean, true),
  logoStore: types.maybe(LogoState),
  logoType: types.optional(types.string, "logoImage"),

  eyeOpen: types.optional(types.boolean, true),
  eyeStore: types.maybe(EyesAndModulesState),

  colorOpen: types.optional(types.boolean, true),
  colorStore: types.maybe(ColorState),

  imageCharsOpen: types.optional(types.boolean, true),
  imageCharsStore: types.maybe(ImageAndCharacteristicsState),

  tagSelectStore: types.maybe(types.late(() => types.reference(TagSelectState))),
  frameOpen: types.optional(types.boolean, true),
  frameAndCaptionsStore: types.maybe(FrameAndCaptionsState),
  overlayOpen: types.optional(types.boolean, false),
  isDimensionSelectVisible: types.optional(types.boolean, false),
  dimensionAll: types.optional(types.boolean, true),
  configurationName: types.optional(types.string, ''),
  isExclusivelyGroupOwned: types.optional(types.boolean, true),
  makeDefault: types.optional(types.boolean, false),
  extendConfig: types.optional(types.string, "{}"),
  qrConfigSelectStore: types.maybeNull(types.late(() => types.reference(QrConfigSelectState))),
  groupSelectStore: types.maybeNull(types.late(() => types.reference(GroupSelectState))),
  dimensionsMultiSelectStore: types.maybeNull(types.late(() => types.reference(DimensionsMultiSelectState))),
  selectedConfigUUID:types.optional(types.string, ""),
  qrPreviewStore: types.maybe(QrPreviewState),
  isDefaultDesigner: types.optional(types.boolean, true)
}).volatile(self => ({
  selectedCallback: types.fn,
})).actions((self) => ({
  // handleModalPopUp(component,title,componentStore,size="",className = ""){
  //   self.modalComponentStore = self[componentStore];
  //   self.modalComponent = component;
  //   self.modalSize=size;
  //   self.modalClassName=className;
  //   self.modalStore.toggle()
  //   self.modalStore.handleTitle(title)
  // },
  toggleLogoOpen() {
    self.logoOpen = !self.logoOpen
  },
  toggleEyeOpen() {
    self.eyeOpen = !self.eyeOpen
  },
  toggleColorOpen() {
    self.colorOpen = !self.colorOpen
  },
  toggleFrameOpen() {
    self.frameOpen = !self.frameOpen
  },
  toggleOverlayOpen() {
    self.overlayOpen = !self.overlayOpen
  },
  toggleImageOpen() {
    self.imageOpen = !self.imageOpen
  },
  handleConfigNameChange(e) {
    self.configurationName = e.target.value
  },
  toggleIsExclusivelyGroupOwned(){
    self.isExclusivelyGroupOwned = !self.isExclusivelyGroupOwned
  },
  toggleDimensionAll(){
    self.dimensionAll = !self.dimensionAll
    self.toggleIsDimensionSelectVisible(!self.dimensionAll)
    self.dimensionAll ? self.dimensionsMultiSelectStore.selectAllDimensions() : self.dimensionsMultiSelectStore.reset()
  },
  toggleIsDimensionSelectVisible(visible){
    self.isDimensionSelectVisible = visible
  },
  setMakeDefault() {
    self.makeDefault = !self.makeDefault
  },
  setGenerateImageListener(listener) {
    self.selectedCallback = listener
  },
  selectTag(arr) {
    self.selectedTags = arr.flatMap((tag)=> tag?.uuid)
  },
  handleUpdateImage() {
    self.selectedCallback(self.extendConfig)
  },
  updateExtendConfig(key, value) {
    const parsedConfig = JSON.parse(self.extendConfig)
    const updatedConfig = {...parsedConfig, ...{[key]: value}}
    self.extendConfig = JSON.stringify(updatedConfig)
    console.log("update extendConfig", self.extendConfig)
  },
  deleteFormExtendConfig(key){
    const parsedConfig = JSON.parse(self.extendConfig)
    delete parsedConfig[key]
    self.extendConfig = JSON.stringify(parsedConfig)
  },
  selectGroup(obj) {
    self.tagSelectStore?.tagStore?.setFilterOn(obj?.uuid);
    self.dimensionsMultiSelectStore?.getListOnGroup(obj?.uuid)
  },
  setOnSelectChanges() {
    self.colorStore.setConsumer(self.updateExtendConfig)
    self.colorStore.setDeleteKeyConsumer(self.deleteFormExtendConfig)
    self.colorStore.setConsumeExtendConfig(self.extendConfig)

    self.eyeStore.setDesignerConsumer(self.updateExtendConfig)
    self.eyeStore.setPreviewConsumer(self.handleUpdateImage)
    self.logoStore.setConsumer(self.updateExtendConfig)
    self.logoStore.setDeleteKeyConsumer(self.deleteFormExtendConfig)
    self.logoStore.setPreviewConsumer(self.handleUpdateImage)
    self.frameAndCaptionsStore.setConsumer(self.updateExtendConfig)
    self.frameAndCaptionsStore.setDeleteKeyConsumer(self.deleteFormExtendConfig)
    self.frameAndCaptionsStore.captionRows.forEach((caption) => {
      caption.setConsumer(self.updateExtendConfig)
    })
    self.imageCharsStore.setConsumer(self.updateExtendConfig)
    self.tagSelectStore?.setConsumer(self.selectTag)
    self.groupSelectStore?.setConsumer(self.selectGroup)
  },
  hydrateDimension(dimensions = []){
    const isDimensionAll = Object.values(dimensions).every(item => lodash.isEmpty(item));
    self.dimensionAll = !isDimensionAll
    self.toggleDimensionAll()
    if(!self.dimensionAll){
      self.dimensionsMultiSelectStore.hydrateDimensions(dimensions)
    }
  },
  //isModalExperience
  hydrateEditComponent(input, isDesignerTemplateExperience = false, isClone= false) {
    //console.log("$$$ hydrateEditComponent", input)
    //clear all the stores
    self.colorStore = ColorState.create({})
    self.eyeStore = EyesAndModulesState.create({})
    self.logoStore = LogoState.create({})
    self.imageCharsStore = ImageAndCharacteristicsState.create({})
    self.frameAndCaptionsStore = FrameAndCaptionsState.create({})
    self.configurationName = ''
    self.makeDefault = false
    self.extendConfig = "{}"
    self.isExclusivelyGroupOwned = input?.isExclusivelyGroupOwned
    //then hydrate the stores
    self.configurationName = isClone ?  "" : input?.name
    isDesignerTemplateExperience && self.groupSelectStore?.selectGroup(input?.owner?.groupUuid)
    isDesignerTemplateExperience && self.dimensionsMultiSelectStore?.getListOnGroup(input?.owner?.groupUuid);
    self.tagSelectStore?.setSelectedTags(input?.tags?.flatMap((tag)=> tag?.uuid))
    const configuration = JSON.parse(input?.qrConfig)
    self.logoOpen = false
    self.eyeOpen  = false
    self.colorOpen = false
    self.imageCharsOpen = false
    self.frameOpen = false
    for (const property in configuration) {
      if (self.colorStore.hasOwnProperty(property)) {
        if(property !== "backgroundColor" && configuration[property] !== "#ffffff"){
          self.colorStore[property] = configuration[property]
          self.colorOpen = true
        } else {
          self.colorStore[property] = configuration[property]
        }
      }

      if(configuration["addThreeEyeballColors"] === false){
        self.colorStore.eyeballColor = self.colorStore.ball1Color
      }
      if(configuration["addThreeEyelidColors"] === false){
        self.colorStore.eyelidColor = self.colorStore.eye1Color
      }
      if(property === "gradientType"){
        self.colorStore.addGradient = true
        self.colorOpen = true
      }
      if (self.imageCharsStore[property]) {
        self.imageCharsStore[property] = configuration[property]
      }
      if (self.eyeStore.hasOwnProperty(property)) {
        self.eyeStore[property] = configuration[property]
        if(property === "changeIndividualEyes"){
          if (self.eyeStore.changeIndividualEyes === true) {
            if (self.eyeStore.eye1Shape !== 0 || self.eyeStore.ball1Shape !== 0) {
              self.eyeStore.openEye1 = true
            }
            if (self.eyeStore.eye2Shape !== 0 || self.eyeStore.ball2Shape !== 0) {
              self.eyeStore.openEye2 = true
            }
            if (self.eyeStore.eye3Shape !== 0 || self.eyeStore.ball3Shape !== 0) {
              self.eyeStore.openEye3 = true
            }
          }
          self.eyeOpen = self.eyeStore.changeIndividualEyes
        }
           if (self.eyeStore.eye1Shape !== 0 || self.eyeStore.ball1Shape !== 0){
             self.eyeStore.openPrimaryEye = true
             self.eyeOpen = true
           }
      }

      if (self.logoStore.hasOwnProperty(property)) {
        if(property === "backgroundImage"){
          self.logoStore.openBackgroundImage = true
        }
        self.logoStore[property] = configuration[property]
        self.logoOpen = true
        if( configuration[property] === "emptyImage"){
          self.logoStore.activeTab = "2"
        }
      }
      // if(configuration.logo){
      //   self.logoStore.logo = configuration.logo
      // }
      if(configuration.imageFormat) {
        self.logoStore.logoFormat = configuration.imageFormat
        if(configuration.imageFormat === "pdf"){
          self.logoStore.pdfLogoPng = configuration.pdfLogoPng
        }
      }
      // if(configuration.fullBackground) {
      //   self.logoStore.fullBackground = configuration.fullBackground
      // }
      // if(configuration.backgroundImage) {
      //   self.logoStore.backgroundImage = configuration.backgroundImage
      // }
      // if(configuration.logoPercentageWidth) {
      //   self.logoStore.logoPercentageWidth = configuration.logoPercentageWidth
      // }
      if(configuration[property].hasOwnProperty("captionTextPosition")) {
        self.frameAndCaptionsStore.captionRows.push(configuration[property])
        self.frameAndCaptionsStore.captions = true
         if (property !== "frameColor"){self.frameOpen = true}

      }
      if(configuration.frameSides) {
        self.frameAndCaptionsStore.frameSides = configuration.frameSides
        self.frameAndCaptionsStore.frame = true
        self.frameOpen = true
      }
      if(configuration.frameColor) {
        self.frameAndCaptionsStore.frameColor = configuration.frameColor
      }
      if(!configuration.frameUseForegroundColor) {
        self.frameAndCaptionsStore.frameUseForegroundColor = configuration.frameUseForegroundColor
        self.frameOpen = true
      }
      if(configuration.backgroundModules) {
        self.frameAndCaptionsStore.backgroundModules = configuration.backgroundModules
        self.frameOpen = true
      }
      if(!configuration.modulesUseForegroundColor) {
        self.frameAndCaptionsStore.modulesUseForegroundColor = configuration.modulesUseForegroundColor
        self.frameAndCaptionsStore.backgroundModulesColor = configuration.backgroundModulesColor
        self.frameOpen = true
      }
      if(configuration.frameOrientation) {
        self.frameAndCaptionsStore.frameRotation = configuration.frameOrientation
        self.frameOpen = true
      }
      if(configuration.backgroundModules) {
        self.frameAndCaptionsStore.fillInColor = configuration.backgroundModules
        self.frameOpen = true
      }
      if(configuration.circularText) {
        self.frameAndCaptionsStore.circularText = configuration.circularText
      }
      if(configuration.frameBackgroundImage) {
        self.frameAndCaptionsStore.frameBackgroundImage = configuration.frameBackgroundImage
      }
      // check is v1 designer config
      if(configuration["isDefaultDesigner"] === false) {
        self.isDefaultDesigner = configuration["isDefaultDesigner"]
      }
      if(configuration.bwr) {
        self.imageCharsStore.bwr = configuration["bwr"]
      }
      self.updateExtendConfig(property, configuration[property])
    }

    if(input?.owner?.dimensions){
      self.hydrateDimension(input?.owner?.dimensions)
    }

  },
  fetchConfigWithID: flow(function* f(uuid) { //this function is only used for the edit template experience
    const imageConfigManager = getEnv(self).imageConfigManager
    const res = yield imageConfigManager.fetchWithId(uuid)
    self.hydrateEditComponent(res,true)
    // self.handleUpdateImage()
    return res

  }),
  updateConfig: flow(function* f() {
    const GroupIsSelected = !!self.groupSelectStore.selectedGroup?.uuid
    const NameIsCreated = self.configurationName !== ""
    const DimensionIsSelected = !self.isDimensionSelectVisible || !lodash.isEmpty(self.dimensionsMultiSelectStore.getTranspiledDimensions())

    if (GroupIsSelected && NameIsCreated && DimensionIsSelected) {
      self.isLoading = true
      self.updateExtendConfig("addThreeEyelidColors", self.colorStore.addThreeEyelidColors)
      const data = {
        "uuid": self.selectedConfigUUID,
        "name": self.configurationName ? self.configurationName : "You forgot to name me",
        "data": self.extendConfig,
        "groupUuid": self.groupSelectStore.selectedGroup.uuid,
        "isExclusivelyGroupOwned" : self.isExclusivelyGroupOwned,
        "tags": self.selectedTags,
        ...(self.dimensionsMultiSelectStore.dimensionStore.dimensionsEnabled) && {"dimensions": self.dimensionsMultiSelectStore.getTranspiledDimensions()}
      }
      const imageConfigManager = getEnv(self).imageConfigManager
      const res = yield imageConfigManager.update(data)
      if (res) {
        new Notification()
          .setType("success")
          .setMessage(`${self.configurationName} Designer Template updated`)
          .send();
        self.isLoading = false
         //update the config in the list
        self.qrConfigSelectStore.configStore.defaultConfiguration.find((config) => config.uuid === self.selectedConfigUUID).updateConfig(data)
        self.handleUpdateImage(self.extendConfig)
        return res
      } else {
        new Notification()
          .setType("error")
          .setMessage(`${self.configurationName} Designer Template failed updating`)
          .send();
        self.isLoading = false
      }
    }else{
      !GroupIsSelected && new Notification()
        .setType("error")
        .setMessage("Please select a group")
        .send();
      !NameIsCreated && new Notification()
        .setType("error")
        .setMessage("Please create a name")
        .send();
      !DimensionIsSelected  && new Notification()
        .setType("error")
        .setMessage("Please select Dimensions")
        .send();
    }

  }),
  addConfig: flow(function* f(qrPreviewStore) {
    const GroupIsSelected = !!self.groupSelectStore.selectedGroup?.uuid
    const NameIsCreated = self.configurationName !== ""
    const DimensionIsSelected = !self.isDimensionSelectVisible || !lodash.isEmpty(self.dimensionsMultiSelectStore.getTranspiledDimensions())
    if (GroupIsSelected && NameIsCreated && DimensionIsSelected) {
      self.isLoading = true
      self.updateExtendConfig("addThreeEyelidColors", self.colorStore.addThreeEyelidColors)
      const resp = yield self.qrConfigSelectStore.configStore.addConfig(self)
      if (resp === 201) {
        new Notification()
          .setType("success")
          .setMessage(`${self.configurationName} Designer Template created`)
          .send();
        self.isLoading = false
        return resp
      } else {
        new Notification()
          .setType("error")
          .setMessage(`${self.configurationName} Designer Template failed creating`)
          .send();
        self.isLoading = false
        return resp
      }
    } else {
      !GroupIsSelected && new Notification()
        .setType("error")
        .setMessage("Please select a group")
        .send();
      !NameIsCreated && new Notification()
        .setType("error")
        .setMessage("Please create a name")
        .send();
      !DimensionIsSelected && new Notification()
        .setType("error")
        .setMessage("Please select Dimensions")
        .send();
    }
  }),
  handleQrPreview(qrPreviewState) {
    self.qrPreviewStore = qrPreviewState
  },
  toggleIsDefaultDesigner(){
    self.isDefaultDesigner = !self.isDefaultDesigner
    self.updateExtendConfig("isDefaultDesigner", self.isDefaultDesigner)
  }
}))


export default DesignerState;
// {\"dataShape\":0,\"imageFormat\":\"png\",\"colorMode\":\"rgb\"" +
// ",\"quietZone\":1,\"errorCorrection\":\"M\",\"size\":1," +
// "\"resolution\":1200,\"frameOrientation\":true,\"frameSides\":8," +
// "\"backgroundColor\":\"#ffffff\",\"data\":\"Th1s 1s @ s@mpl3 QR v@lu3\"}"


