import {flow, getEnv, getType, types} from "mobx-state-tree";
import {ExtendedDataSetSelectState} from "../ExtendedDataSetSelectState";
import lodash from "lodash";
import {ExtendedDataSelectState} from "../ExtendedDataSelectState";
import Ajv from "ajv";
import addFormats from "ajv-formats";

export const ExtendedDataSettingModalState = types.model("ExtendedDataSettingModalState", {
  isLoading: types.optional(types.boolean, false),
  showDropdown: types.optional(types.boolean, true),
  extendedDataSetSelectStore: types.maybe(types.late(() => types.reference(ExtendedDataSetSelectState))),
  extendedDataSelectStore: types.maybe(types.late(() => types.reference(ExtendedDataSelectState))),
  schema: types.optional(types.string, '{}'),
  liveValidateSchema: types.optional(types.boolean, false),
  data: types.optional(types.string, '{}'),
}).volatile((self) => ({
  notifyCalled: types.fn,
})).actions((self) => ({
  toggleDropdownVisibility() {
    self.showDropdown = !self.showDropdown;
  },
  setLiveValidateSchema(v) {
    self.liveValidateSchema = v;
  },
  setOnSelectChanges() {
    self?.extendedDataSetSelectStore?.setConsumer(self?.selectExtendedDataSet);
    self?.extendedDataSelectStore?.setConsumer(self?.selectExtendedDataSet);
  },
  setConsumer(func) {
    self.notifyCalled = func;
  },
  transformErrors(errors) {
    return errors.map(error => {
      return error;
    });
  },
  buildSchema() {
    // Concatenate json schema for the selected items
    let schema = {};
    const properties = {};
    try {

      const sortObjectBy = (obj, prop="title") => {
        return Object.fromEntries(
          Object.entries(obj)
            .sort((a, b) => a[1][prop]?.localeCompare(b[1][prop]))
        );
      }

      Object.values(self.getSelectedExtendedDataMap())?.forEach(item => {
        if (item && item.schemaDefinition && item.propName) {
          properties[item.propName] = item.schemaDefinition;
        }
      });

      if (Object.keys(properties)?.length) {
        schema = {
          "type": "object",
          "properties": sortObjectBy(properties)
        };
      }
    } catch (e) {
      console.error(e);
    }
    self.setExtendedSceham(schema);
    return schema;
  },
  validateSchema(schema, formData) {
    const ajv = new Ajv({allErrors: true, validateFormats: false})
    addFormats(ajv);
    const validate = ajv.compile(schema)
    let valid = validate(formData)
    if (!valid) {
      self.setLiveValidateSchema(true);
    }
    return valid
  },
  validateForm() {
    const isExtenedFormValid = self.validateSchema(self.extendedDataSchema, self.formData);
    self.setLiveValidateSchema(!isExtenedFormValid);
    return isExtenedFormValid;
  },
  clearState() {
    self.setExtendedDataSetSelectStore();
    self.hydrateModal();
    self.setLiveValidateSchema(false);
    self.isLoading = false;
  },
  setExtendedSceham(s) {
    self.schema = JSON.stringify(s);
  },
  getExtendedSchema() {
    return JSON.parse(self.schema);
  },
  updateExtendedFormData(data) {
    self.data = JSON.stringify(data);
  },
  onSelectChange(data) {
    self.buildSchema();
    if (self.notifyCalled) {
      self.notifyCalled(data);
    }
  },
  onAutoFillSelectChange(data) {
    self.extendedDataSetSelectStore?.setSelectedExtendedData(data);
    self.onSelectChange(data);
  },
  onExtendedSelectChange(data) {
    self.extendedDataSelectStore?.setSelectedExtendedData(data);
    self.onSelectChange(data);
  },
  setExtendedDataSelectStore(arr) {
    self.extendedDataSelectStore?.setSelectedExtendedData(arr);
  },
  setExtendedDataSetSelectStore(arr) {
    self.extendedDataSetSelectStore?.setSelectedExtendedData(arr);
  },
  getDefaultExtendedDataAndHydrate: flow(function* (visibilityConditions={}) {
    self.isLoading = true;
    try {
      if (!lodash.isEmpty(visibilityConditions)) {
        console.log("fetching getDefaultExtendedDataAndHydrate:", JSON.stringify(visibilityConditions))
        const response = yield getEnv(self).extendedDataManager.fetchAllExtendedData({
          visibilityConditions
        });

        if (response?.results) {
          self.hydrateModal({
            extendedData: {
              items: response?.results
            }
          });
        }
      }
    } catch (e) {
      console.error(`${getType(self).name} Error: Failed to getDefaultExtendedDataAndHydrate`, e);
    }
    self.isLoading = false;
  }),
  hydrateModal(code) {
    self.setExtendedDataSelectStore(code?.extendedData?.items);
    self.updateExtendedFormData(code?.extendedData?.data);
    self.buildSchema();
    if (self.notifyCalled) {
      self.notifyCalled(self.getSelectedExtendedDataMap());
    }
  },
  getSelectedExtendedDataMap() {
    const autoFormList = self.extendedDataSetSelectStore?.getSelectedExtendedData() || [];
    const additionalList = self.extendedDataSelectStore?.getSelectedExtendedData() || [];
    return lodash.keyBy([...autoFormList, ...additionalList], "uuid");
  },
})).views((self) => ({
  get formData() {
    return JSON.parse(self.data);
  },
  get extendedDataSchema() {
    const val = JSON.parse(self.schema);
    return JSON.parse(self.schema);
  },
  getAutoFillDefaultValues() {
    return self.extendedDataSetSelectStore?.getSelectedExtendedDataSets()?.length ? self.extendedDataSetSelectStore?.getSelectedExtendedDataSets() : [];
  },
  getExtendedDefaultValues() {
    return self.extendedDataSelectStore?.getSelectedExtendedData()?.length ? self.extendedDataSelectStore?.getSelectedExtendedData() : [];
  },
  getExtendedOptionValues() {
    return self?.extendedDataSelectStore?.optionValues();
  },
  getAutoFillOptionValues() {
    return self?.extendedDataSetSelectStore?.optionValues();
  }
}));
