import {flow, getEnv, getSnapshot, types} from "mobx-state-tree";
import {GroupSelectState} from "../../../elements/groups/GroupSelectState";
import {RuleType} from "../../../../models/RuleType";
import {RuleTypeSelectState} from "../../../elements/ruleType/RuleTypeSelectState";
import RuleTypeStore from "../../../../stores/domain/RuleTypeStore";
import {TreeNode} from "./CreateRuleForm";
import lodash from "lodash";
import {detach} from "mobx-state-tree";
import {ajv} from "../../../../services/AjvValidator";
import Notification from "../../../../utils/Notification";
import {TagSelectState} from "../../../elements/tags/TagSelectState";
import {RuleSetsListViewState} from "../list/RuleSetsListViewState";
import {DimensionsMultiSelectState} from "../../../elements/dimensions/multi-select/dimensionsMultiSelectState";

export const CreateRuleSetState = types
  .model("CreateRuleSetState", {
    isModalUi:types.optional(types.boolean, false),
    groupSelectStore: types.maybe(types.reference(GroupSelectState)),
    ruleset: types.optional(types.string, "{}"),
    ruleTypeSelectState: types.maybe(types.late(() => types.reference(RuleTypeSelectState))),
    ruleInputData: types.optional(types.string, '{}'),
    rootNode: types.maybe(types.late(() => (TreeNode))),
    data: types.optional(types.string, '{}'),
    primaryUrl: types.optional(types.string, ''),
    isPrimaryUrlDefault: types.optional(types.boolean, false),
    ruleSetName: types.optional(types.string, ''),
    nameIsValid: types.optional(types.boolean, true),
    selectedRuleSetUUID:types.optional(types.string, ""),
    formHasErrors: types.optional(types.boolean, false),
    tagSelectStore: types.maybe(types.late(() => types.reference(TagSelectState))),
    ruleSetsListViewStore: types.maybe(types.reference(RuleSetsListViewState)),
    isExclusivelyGroupOwned: types.optional(types.boolean, true),
    dimensionsMultiSelectStore: types.maybeNull(types.late(() => types.reference(DimensionsMultiSelectState))),
    dimensionAll: types.optional(types.boolean, true),
    isDimensionSelectVisible: types.optional(types.boolean, false),
  })
  .actions(self => ({
    setOnSelectChanges() {
      self.groupSelectStore?.setConsumer(self.selectGroup)
    },
    selectGroup(obj) {
      self.dimensionsMultiSelectStore?.getListOnGroup(obj?.uuid)
    },
    addRoot(obj) {
      self.rootNode = obj
    },
    handlePrimaryUrlChange(e) {
      self.primaryUrl = e.target.value
    },
    handleRuleSetNameChange(e) {
      self.ruleSetName = e.target.value
      self.nameIsValid = true
    },
    handleIsPrimary(e) {
      self.isPrimaryUrlDefault = !self.isPrimaryUrlDefault
      console.log(self)
    },
    toggleIsExclusivelyGroupOwned(){
      self.isExclusivelyGroupOwned = !self.isExclusivelyGroupOwned
    },
    toggleDimensionAll(){
      self.dimensionAll = !self.dimensionAll
      self.toggleIsDimensionSelectVisible(!self.dimensionAll)
      self?.dimensionAll ? self?.dimensionsMultiSelectStore?.selectAllDimensions() : self?.dimensionsMultiSelectStore?.reset()
    },
    toggleIsDimensionSelectVisible(visible){
      self.isDimensionSelectVisible = visible
    },
    hydrateDimension(dimensions = []){
      const isDimensionAll = Object.values(dimensions).every(item => lodash.isEmpty(item));
      self.dimensionAll = !isDimensionAll
      self.toggleDimensionAll()
      if(!self.dimensionAll){
        self.dimensionsMultiSelectStore.hydrateDimensions(dimensions)
      }
    },
    updateRuleSet(key, value) {
      const parsedConfig = JSON.parse(self.ruleset)
      const updatedConfig = {...parsedConfig, ...{[key]: value}}
      self.ruleset = JSON.stringify(updatedConfig)
      console.log("update ruleset", self.ruleset)
    },
    validateSchema(node) {
      console.log("createrulesetstatevalidate schema", node)
      const schema  = JSON.parse(node.ruleTypeSelectState?.selected?.ruleSchema)
      //mutate factValue in schema to be an array of strings

      const data = JSON.parse(node.ruleInputData)
      if (data.factValue) {
        if(typeof data.factValue[0] !== "string" && typeof data?.factValue !== 'number' && data?.factValue[0]?.value) { //<-- this is needed because validation will turn the array of objects into an array of strings
          data.factValue = data.factValue.map((item) => {
            return item.value
          })
        }
      }
      // ^^ this is a hack and will need changing when fact value is something other than an array of strings

      // const validate = ajv.compile(schema)
      // let valid = validate(data)
      // if (!valid) {
      //   console.log(validate.errors)
      //   node.liveValidation()
      //   //self.formHasErrors = true //TODO: 12/20/2023 Need to fix validation, this prevents valid rules from being saved rn.
      // }
      return data
    },
    mapData(array, validateForCode = false) {

      let result = [];
      self.formHasErrors = false
      const recurseToMapData = (arr) => {
        const levelResults = [];
        for (const el of arr) {


          const ruleInputData = validateForCode ? self.validateSchema(el) : JSON.parse(el["ruleInputData"])

          // cleaning children, json need regenerate
          ruleInputData["rules"] = []
          const schemaFormData = {...ruleInputData}

          // if (!schemaFormData["action"]){
          //   schemaFormData["action"] = {"url":""}
          // }
          schemaFormData["action"]["rid"] = el.position
          if (lodash.isEmpty(el.children)) {

            levelResults.push({
              ...schemaFormData,
              "type": !validateForCode?el.ruleTypeSelectState?.selected?.split("/")[0] : el.ruleTypeSelectState.selected.uuid.split("/")[0],
            })
          } else {
            levelResults.push({
              ...schemaFormData,
              "type": !validateForCode?el.ruleTypeSelectState?.selected?.split("/")[0] : el.ruleTypeSelectState.selected.uuid.split("/")[0],
              "rules": recurseToMapData(el["children"])
            });
          }
        }
        return levelResults;
      };
      result = recurseToMapData(array);
      return JSON.stringify(result);
    },
    validateAndFixUrls(data) {
        try {
        const dataArray = JSON.parse(data);

        dataArray.forEach(item => {
        if (item.action && item.action.url) {
          let url = item.action.url;

          if (!url.startsWith('http://') && !url.startsWith('https://')) {
            url = 'http://' + url;
          }
          const urlPattern = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=!,| ]*)$/i;
          const match = url.match(urlPattern);

          if (match) {
            item.action.url = url;
          }
          else {
            throw new Error(`Invalid URL: ${url}`);
          }
        }
        });
         return JSON.stringify(dataArray)
        } catch (error) {
        console.error("Error parsing or updating data:", error);
          throw error;
        }
        },
    addRuleSet: flow(function* f(state) {
      self.data = self.mapData(self.rootNode.children, true)
      try {
        self.data = self.validateAndFixUrls(self.data);
      } catch (error) {
        self.isLoading = false;
        console.error(error.message);
        new Notification()
          .setType("error")
          .setMessage(`${self.ruleSetName} Invalid URL`)
          .send()
        return
      }
      const NameIsCreated = self.ruleSetName !== ''
      const DimensionIsSelected = !self.isDimensionSelectVisible || !lodash.isEmpty(self.dimensionsMultiSelectStore.getTranspiledDimensions())
      if (NameIsCreated && DimensionIsSelected) {
        self.isLoading = true
        const ruleSetManager = getEnv(self).ruleSetManager
        const resp = !self.selectedRuleSetUUID
          ? yield ruleSetManager.create(self)
          : yield ruleSetManager.update(self,self.selectedRuleSetUUID)
        if (!resp.message) {
          self.ruleSetsListViewStore?.listModel?.store?.appendRules(resp.data)
          !self.selectedRuleSetUUID
            ? new Notification()
              .setType("success")
              .setMessage(`${self.ruleSetName} Rule Set created`)
              .send()
            : new Notification()
              .setType("success")
              .setMessage(`${self.ruleSetName} Rule Set updated`)
              .send();
          self.isLoading = false
          return resp
        } else {
          !self.selectedRuleSetUUID
            ? new Notification()
              .setType("error")
              .setMessage(`${self.ruleSetName} Rule Set failed creating`)
              .send()
            : new Notification()
              .setType("error")
              .setMessage(`${self.ruleSetName} Rule Set failed updating`)
              .send();
          self.isLoading = false
        }
      } else {
        self.nameIsValid = false
      }
    }),
    grabRuleSetForCode(){
      const data = self.mapData(self.rootNode.children,true)
      if(self.formHasErrors){
        return null
      }
      return data
    },
    selectRuleType(obj) {
      self.ruleTypeSelected = obj;
    },
    hydrateTree(array){
      detach(self.rootNode.children)
      const recurseToGenerateNodes = (arr,currentNode = self.rootNode) => {
        if(!lodash.isEmpty(arr)) {
          for (const [i, node] of arr.entries()) {
            currentNode.addNewChild(self.ruleTypeSelectState.store, node)
            if (!lodash.isEmpty(node.rules)) {
              recurseToGenerateNodes(node.rules, currentNode.children[i])
            }
          }
        }
      };
      recurseToGenerateNodes(array);
    },
    hydrateForm(input, mode) {
      mode === "clone" ? self.ruleSetName = "" : self.ruleSetName = input?.name
      self.groupSelectStore?.selectGroup(input?.owner?.groupUuid)
      self.dimensionsMultiSelectStore?.getListOnGroup(input?.owner?.groupUuid)
      self.tagSelectStore?.setSelectedTags(input?.tags?.flatMap(tag=> tag.uuid))
      self.isExclusivelyGroupOwned =  input.isExclusivelyGroupOwned
      self.hydrateDimension(input?.owner?.dimensions)
      self.hydrateTree(input?.rules)
    },
    fetchRuleSetWithID: flow(function* f(uuid, mode) {
      self.rootNode.children.length && self.rootNode.clearChildren()
      const res = yield getEnv(self).ruleSetManager.getRuleSet(uuid)
      self.hydrateForm(res, mode)
    }),
  })).views((self) => ({
    ruleInputDataView() {
      return JSON.parse(self.ruleInputData)
    },
    ruleTypeSchemaJson() {
      let {ruleSchema} = self.ruleTypeSelected || "{}"
      return JSON.parse(ruleSchema);
    },
    uiSchemaJson() {
      let {ruleUiSchema} = self.ruleTypeSelected || "{}"
      return JSON.parse(ruleUiSchema);
    }
  }))
