import React from 'react'
import {
  Badge,
  Button,
  Card,
  CardBody, Col, CustomInput,
  DropdownItem, Input, InputGroup, InputGroupAddon, Row, UncontrolledTooltip,
} from 'reactstrap';
import { observer } from 'mobx-react-lite';
import ScanbuyTable, {TableColumn} from '../../../components/tables/ScanbuyTable';
import TableActionsDropdown from '../../../components/tables/TableActionsDropdown';
import * as Icon from "react-feather";
import { RouterLink } from 'mobx-state-router';
import GroupSelect from '../../elements/groups/GroupSelect';
import {FormattedMessage, useIntl } from "react-intl";
import {createdByColumn, createdColumn, updatedColumn} from '../../tables/TableColumns';
import TagSelect from "../../elements/tags/TagSelect";
import {formConstants} from "../../../utils/constants";
import {getSnapshot} from "mobx-state-tree";
import AdvancedConfFilterSelect from "../../elements/filters/AdvancedConfFilterSelect";
import CodeOwnerFilterSelect from "../../elements/filters/CodeOwnerFilterSelect";
import {buildDimensionFilters} from "../../../utils/buildDimensionFilters";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleDown, faAngleDoubleUp} from "@fortawesome/free-solid-svg-icons";
import TagFilterSelect from "../../elements/filters/TagFilterSelect";
import DeleteTagState from "./TagDeleteState";
import DeleteTagView from "./TagDeleteView"
import GenericModal from "../../elements/modal/GenericModal";
import GroupFilterSelect from "../../elements/filters/GroupFilterSelect";

const customStyles = {
  headRow: {
    style: {
      border: 'none',
    },
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: 'rgb(230, 244, 244)',
      borderBottomColor: '#FFFFFF',
      // borderRadius: '5px',
      outline: '1px solid #FFFFFF',
    },
  },
  pagination: {
    style: {
      border: 'none',
    },
  },
}

export const buildTagsListColumns = (routerStore,tagsListViewState) => {

  const {rootStore} = routerStore.options;
  const c1 = TableColumn.create({
    name: 'Name', property: 'name', sortable: true, sortField: 'name'
  })
  c1.setSelector((row) => {
      return(
        <>
        <span id={`tooltip-${row.uuid}`}>{row.name}</span>
        <UncontrolledTooltip target={`tooltip-${row.uuid}`} placement="top">{row.name}</UncontrolledTooltip>
        </>
      )}
  )

  const c2 = TableColumn.create({
    name: 'Tags', property: 'tags', hide: 'sm',
  })
  c2.setSelector((row) => (
    <>
      {row.tags.map((tag, index) => (
        <Badge key={index} color="primary" className="mr-1 mb-1">
          {tag.name}
        </Badge>
      ))}
    </>
  ))

  const createdBy = createdByColumn();

  const actionColl = TableColumn.create({
    name: 'Action', property: 'actions', button: true, allowOverflow: true, ignoreRowClick: true
  });

  actionColl.setCell((row) => (
      <TableActionsDropdown actionsList={[
        ...(row.isEditable ? [
        <DropdownItem id="action-btn-edit" key={'edit/' + row.uuid} onClick={() => {
          routerStore.goTo('editTag', {
            params: { uuid: row.uuid },
          });
        }}><Icon.Edit/>&nbsp;Edit</DropdownItem>]: []),
        ...(row.isEditable ? [
        <DropdownItem id="action-btn-clone" key={'new'} onClick={() => {
          // const snapshot = getSnapshot(row);
          rootStore.rootUiStore.addCloneToCache(`clone-tag:${row.uuid}`,row);
          routerStore.goTo('createTag',{
            queryParams: {q: row.uuid}
          })
        }}><Icon.Copy/>&nbsp;<FormattedMessage id="listCodes.actions.dropdown.clone.defaultMessage"/>
        </DropdownItem>]: []),
        ...(row.isEditable ? [
          <DropdownItem key={row.uuid} value={"Delete " + row.uuid} className="align-text-bottom"
                        onClick={() => {
                          const componentStore = DeleteTagState.create(
                            {
                              title: `Delete ${row.name ? row.name : ""}`,
                              uuid: row.uuid
                            });
                          rootStore.rootUiStore.attachPageState('deleteTagState', componentStore)
                          componentStore.setToggleListener(tagsListViewState.modalStore.toggle)
                          componentStore.setRefreshListener(tagsListViewState.refresh)
                          tagsListViewState.handleRowClick(row, componentStore, DeleteTagView);
                        }}> <Icon.Delete/>&nbsp;<FormattedMessage id="listCodes.actions.dropdown.delete.defaultMessage"/>
          </DropdownItem>]: [])
//         <DropdownItem id="action-btn-delete" key={'delete/' + row.uuid} onClick={() => {
//           routerStore.goTo('deleteTag', {
//             params: { uuid: row.uuid },
//           });
//         }}><Icon.Delete/>&nbsp;Delete</DropdownItem>]: [])
      ]}/>
    ))

  return [ c1, c2, createdBy, createdColumn, updatedColumn, actionColl];
}

const TagListView = observer(({tagsListViewState}) => {
    const intl = useIntl();
  // useEffect(() => {
  //   tagsListViewState?.handleFilterChange({
  //     groupUuid: tagsListViewState?.groupSelectStore?.selectedGroup?.uuid,
  //   })
  // }, [tagsListViewState?.groupSelectStore?.selectedGroup]);

  // wired tagsListViewState and groupSelectStore to remove this logic^^
  tagsListViewState.groupSelectStore  &&  tagsListViewState.setOnSelectChanges()
  tagsListViewState?.advFilterSelectState?.setEmails(tagsListViewState.store?.filters?.users);
  tagsListViewState?.tagFilterSelectState?.setTags(tagsListViewState.store?.filters?.tags);
  tagsListViewState?.filterGroup && tagsListViewState?.groupFilterSelectState?.setGroups(tagsListViewState?.store?.filters?.groups);
  tagsListViewState?.dimensionSelectViewState?.setFilteredDimensions(tagsListViewState?.store?.filters.dimensions);
  return (
    <>
      <Card style={{
        backgroundColor: '#FFFFFF',
      }}>
        <CardBody>
          <Row>
            <Col className="text-right">
              {tagsListViewState.isFilterVisible ? <Button id="codelist-dropdown-open" color="primary" onClick={()=> {tagsListViewState.toggleFilterVisible()}}><FontAwesomeIcon icon={faAngleDoubleUp} /></Button>
                : <Button id="codelist-dropdown-closed" color="primary" onClick={()=> {tagsListViewState.toggleFilterVisible()}}><FontAwesomeIcon icon={faAngleDoubleDown} /></Button>}
            </Col>
          </Row>
          {tagsListViewState?.isFilterVisible && <>
            <Row>
              <Col sm={5}>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    {intl.formatMessage({ id: "filterByGroups.defaultMessage"})}
                  </InputGroupAddon>
                  {/*<CodeOwnerFilterSelect codeOwnerFilterSelectState={tagsListViewState.codeOwnerFilterSelectState}/>*/}
                  {/*<GroupSelect groupSelectStore={tagsListViewState?.groupSelectStore} multi={true}/>*/}
                  <GroupFilterSelect groupFilterSelectState={tagsListViewState.groupFilterSelectState}/>
                </InputGroup>
              </Col>
              <Col sm={5}>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    {intl.formatMessage({id: "listCodes.filter.filterName.defaultMessage"})}
                  </InputGroupAddon>
                  <AdvancedConfFilterSelect advFilterSelectState={tagsListViewState.advFilterSelectState}/>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              {tagsListViewState?.dimensionSelectViewState?.dimensionsEnabled && tagsListViewState?.dimensionSelectViewState?.dimensionSelectStates?.map(dimensionSelectStore => buildDimensionFilters(dimensionSelectStore))}
            </Row>
            <Row>
              <Col sm={5}>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    {intl.formatMessage({id: "listCodes.searchInput.defaultMessage"})}
                  </InputGroupAddon>
                  <Input
                    type="text"
                    name="searchInput"
                    placeholder="Search"
                    onChange={tagsListViewState.onSearchChange}
                  />
                </InputGroup>
              </Col>
              <Col sm={5}>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    {intl.formatMessage({ id: "listCodes.filterBytags.defaultMessage"})}
                  </InputGroupAddon>
                  {/*<TagSelect tagSelectStore={tagsListViewState?.tagSelectStore}/>*/}
                  <TagFilterSelect tagFilterSelectState={tagsListViewState.tagFilterSelectState}/>
                </InputGroup>
              </Col>
              <Col sm = {1}>
                <CustomInput
                  type="switch"
                  id="isFilterTypeOR"
                  name="isFilterTypeOR"
                  label={formConstants.Mode.OR}
                  checked={tagsListViewState.isFilterTypeOR}
                  onChange={() => tagsListViewState.selectFilterMethod()}
                />
              </Col>
              <Col sm={1}  className="justify-content-end text-right" >
                <RouterLink routeName="createTag">
                  <Button id="create-tag-btn" onClick={()=> {
                  }} color="primary">Create</Button>
                </RouterLink>
              </Col>
            </Row>
          </>}
          <Row>
            <Col>
              <ScanbuyTable
                columns={tagsListViewState.columns}
                customStyles={customStyles}
                store={tagsListViewState.store}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <GenericModal
        modalStore={tagsListViewState.modalStore}
        componentStore={tagsListViewState.modalComponentStore}
        EmbedComponent={tagsListViewState.modalComponent}
      />

    </>
  )
})

export default TagListView
