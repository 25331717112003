import Notification from './Notification';
import PreviewManager from '../managers/preview/PreviewManager';
import HttpClient from '../services/HttpClient';
import {Html5Qrcode} from "html5-qrcode"

const IMAGE_SERVICE = "https://nigiu8dgm4.execute-api.us-east-2.amazonaws.com/dev";

// export function scannabilityCheck(base64Image) {
//   return new Promise((resolve, reject) => {
    
//     const image = document.getElementById('qrDesignPreview');
    
//     // Set the src attribute of the img element to the base64 image data
//     image.src = base64Image;

//     image.onload = function () {
//       const canvas = document.createElement('canvas');
//       document.body.appendChild(canvas)
//       var context = canvas.getContext('2d');

//       canvas.width = image.width;
//       canvas.height = image.height;
//       context.drawImage(image, 0, 0, canvas.width, canvas.height);

//       const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
//       const code = jsQR(imageData.data, canvas.width, canvas.height);
//       if (code) {
//         document.body.removeChild(canvas);
//         resolve(true);
//       } else {
//         document.body.removeChild(canvas);
//         resolve(false);
//       } 
      
//     }
   
//   })         
// }

export async function generateImageCheckscannability(config) {
  const designerHttpClient = new HttpClient(IMAGE_SERVICE);
  const previewManager = new PreviewManager(designerHttpClient);
  const configForScan = {
    name:"",
    qrImageFormData:JSON.stringify(config),
    shortUrl:""
  }
  const resp = await previewManager.create(configForScan)

  return resp ? await scannabilityCheck(resp.data?.split("'")[1]) : false;
}

export function scannabilityCheck(base64Image) {
  return new Promise((resolve, reject) => {
    const html5QrCode = new Html5Qrcode(/* element id */ "reader");
    let scanabilityCheck = false;
    const imageFile = base64ToFile(base64Image, 'image.png', 'image/png')
    // check if the file is a QR code
    html5QrCode.scanFile(imageFile, true)
    .then(decodedText => {
      // success, it is a QR code and it is readable
      scanabilityCheck = true;
      resolve(true)
    })
    .catch(err => {
      // failure, handle it.
      console.log(`Error scanning file. Reason: ${err}`)
      scanabilityCheck = false;
      resolve(false)
    });
  })
}

function base64ToFile(base64String, fileName, fileType) {
  // Convert the base64 string to binary data
  const binaryData = atob(base64String);

  // Create a Uint8Array to hold the binary data
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  // Create a Blob from the binary data
  const blob = new Blob([uint8Array], { type: fileType });

  // Create a File object from the Blob
  const imageFile = new File([blob], fileName, { type: fileType });

  return imageFile;
}
