export function setDefaultBackgroundColor(extend) {
    if(!extend.hasOwnProperty('backgroundColor') && !extend.hasOwnProperty('transparentBackground')) {
        extend.backgroundColor = "#ffffff"
    } else if (!extend.hasOwnProperty('backgroundColor') && extend.transparentBackground === false ) {
        extend.backgroundColor = "#ffffff"
    }
    return extend
}

export function setEyesAndModules(extend) {
    console.log("setEyesAndModules", JSON.stringify(extend))
    if(!extend.hasOwnProperty('changeIndividualEyes') || extend.changeIndividualEyes === false) {
        const eyeLidAngle = extend.allEyeLidsRotation
        if(eyeLidAngle === 90) {
            extend.eye1Rotation = ["fv"]
            extend.eye1RotationAngle = 90
            extend.eye2Rotation = ["fh","fv"]
            extend.eye2RotationAngle = 90
            extend.eye3Rotation = []
            extend.eye3RotationAngle = 90
        } else if(eyeLidAngle === 180) {
            extend.eye1Rotation = ["fh","fv"]
            extend.eye1RotationAngle = 180
            extend.eye2Rotation = ["fv"]
            extend.eye2RotationAngle = 180
            extend.eye3Rotation = ["fh"]
            extend.eye3RotationAngle = 180
        } else if(eyeLidAngle === 270) {
            extend.eye1Rotation = ["fh"]
            extend.eye1RotationAngle = 270
            extend.eye2Rotation = []
            extend.eye2RotationAngle = 270
            extend.eye3Rotation = ["fh", "fv"]
            extend.eye3RotationAngle = 270
        } else {
            extend.eye1Rotation = []
            extend.eye1RotationAngle = 0
            extend.eye2Rotation = ["fh"]
            extend.eye2RotationAngle = 0
            extend.eye3Rotation = ["fv"]
            extend.eye3RotationAngle = 0
        }
        if(extend.hasOwnProperty('allEyeBallsRotation') ) {
            let ballAngle
            if(extend.allEyeBallsRotation == null ) {
                ballAngle = 0
            } else {
                ballAngle = extend.allEyeBallsRotation
            }
            extend.ball1Rotation = [ballAngle]
            extend.ball2Rotation = [ballAngle]
            extend.ball3Rotation = [ballAngle]
            extend.ball1RotationAngle = ballAngle
            extend.ball2RotationAngle = ballAngle
            extend.ball3RotationAngle = ballAngle
        }

    } else if(extend.hasOwnProperty('changeIndividualEyes') && extend.changeIndividualEyes === true) {
        extend.allEyeLidsRotation = 0
        extend.allEyeBallsRotation = 0
    }
    return extend
}
export function urlValidator(url) {
  if(url){
    const pattern = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=!,| ]*)$/i;
    const isUrlValid = pattern.test(url);

    if (!isUrlValid) {
      throw new Error("Invalid URL");
    }
  }
}
export function  addHttpsIfNeeded(url) {
  if (url && !/^https?:\/\//i.test(url)) {
    return 'http://' + url;
  }
  return url;
}

export const getJSONFromBase64 = (base64String, defaultVal={}) => {
  if (base64String) {
    try {
      return JSON.parse(Buffer.from(base64String, 'base64').toString('ascii'));
    } catch (err) {
      console.error(err);
    }
  }
  return defaultVal;
};

export const getBase64String = (value) => {
  try {
    return Buffer.from(JSON.stringify(value)).toString('base64');
  } catch (err) {
    console.error(err);
  }
  return null;
};
