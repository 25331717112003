import {flow, getEnv, types} from "mobx-state-tree";
import UtmStore from "../../stores/domain/UtmStore";
import Group from "../Group";
import lodash from "lodash";


export const UtmsList  = types.model('UtmsList ', {
        store: types.maybe(types.late(() => types.reference(UtmStore))),
        isLoading: types.optional(types.boolean, false), //types.enumeration('State', ['pending', 'done', 'error']),
        page: types.optional(types.integer, 1),
        perPage: types.optional(types.integer, 10),
        totalRows: types.optional(types.integer, 0),
        selectedGroup: types.maybe(types.reference(Group)),
        sortOrder: types.optional(types.string, "desc"),
        sortField:  types.optional(types.string, "updatedAt"),
    })
    .volatile(self => ({
        searchParams: {},
        filters: {}
    })).actions((self) => ({
        setSortOrder(sortDirection){
          self.sortOrder = sortDirection
        },
        setSortField(sortField){
          self.sortField = sortField
        },
        fetchAll: flow(function* fetchAll(sortParams = {}) {
            self.isLoading = true
            if (Object.keys(sortParams).length === 0) {
              sortParams = {
                sort: 'updatedAt',
                order: self.sortOrder
              };
            }
            try {
                let queryParams = {
                    page: self.page,
                    perPage: self.perPage,
                    ...sortParams,
                    ...self.searchParams
                }

              //if its not a group query add isExclusivelyGroupOwned
              queryParams = Object.assign({
                  ...queryParams,
                },
                lodash.isEmpty(queryParams.groupUuid) && {isExclusivelyGroupOwned: false}
              )

              const manager = getEnv(self).utmManager
                const response = yield manager.fetchAll(queryParams)
              if (response) {
                self.store?.setUtms(response?.results)
                self.totalRows = response._meta.itemCount;
                self.isLoading = false
              }else {
                self.isLoading = false;
              }
            }catch (error) {
                console.error('Failed to fetch utms', error)
            }
        }),
    fetchFilters: flow(function* fetchFilters(sortParams = {}) {
      self.isLoading = true;

      try {
        const queryParams = {
          ...self.searchParams
        }

        const utmManager = getEnv(self).utmManager
        const response = yield utmManager.fetchFilters(queryParams);
        if (response != null) {
          self.filters = response
          self.isLoading = false;
        } else {
          self.isLoading = false;
        }

      } catch (error) {
        console.error('Failed to fetch all', error)
      }

    }),
        updatePage: function (page) {
            self.page = page
        },
        updatePerPage: function (perPage) {
            self.perPage = perPage
        },
        addSearchParams(param){
            self.searchParams = {...self.searchParams, ...param}
        },
        removeSearchParams(names,  regex= ''){
            if(names){
                names.map((name) => delete self.searchParams[name])
            }
            if(regex){
              const keysToDelete = Object.keys(self.searchParams).filter(key => regex.test(key));
              keysToDelete.forEach(key => delete self.searchParams[key]);
            }
        },
        setSelectedGroup(group){  //set selected group to filter utms
            self.selectedGroup = group
        }
    })).views( (self) => ({
        getItems() {
            if(!self.selectedGroup) return self.store.flatList //if no group selected, return all utms
          return self.store.filteredList() || [];
        }
}))
