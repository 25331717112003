import {flow, getEnv, types} from "mobx-state-tree";
import {RuleSetsStore} from "../stores/domain/RuleSetsStore";

export const RuleSetsListModel = types.model({
  store:types.maybe(types.late(() => types.reference(RuleSetsStore))),
  isLoading: types.optional(types.boolean, false), //types.enumeration('State', ['pending', 'done', 'error']),
  page: types.optional(types.integer, 1),
  perPage: types.optional(types.integer, 10),
  totalRows: types.optional(types.integer, 0),
  sortOrder: types.optional(types.string, "desc"),
  sortField:  types.optional(types.string, "updatedAt"),
}).volatile(self => ({
  searchParams: {}
})).actions((self) => ({
  fetchAll(sortParams) {
  self.store.fetchAll(sortParams)
  },
  fetchFilters() {
    self.store.fetchFilters()
  },
  setSortOrder(sortDirection){
    self.sortOrder = sortDirection
  },
  setSortField(sortField){
    self.sortField = sortField
  },
  updatePage: function (page) {
    self.page = page
  },
  updatePerPage: function (perPage) {
    self.perPage = perPage
  },
  addSearchParams(param){
    self.searchParams = {...self.searchParams, ...param}
    self.store.addSearchParams(self.searchParams)
  },
  removeSearchParams(names,regex = ''){
    if(names){
      names.map((name) => delete self.searchParams[name])
    }
    if(regex){
      const keysToDelete = Object.keys(self.searchParams).filter(key => regex.test(key));
      keysToDelete.forEach(key => delete self.searchParams[key]);
    }
    self.store.clearSearchParams(names,regex)
  },
})).views( (self) => ({
  getItems() {
    const { flatList } = self.store || {};
    return flatList || []
  }
}))
