import {flow, getEnv, types} from "mobx-state-tree";
import {GroupSelectState} from "../../elements/groups/GroupSelectState";
import {Utm} from "../../../models/utms/UtmModel";
import Notification from "../../../utils/Notification";
import {TagSelectState} from "../../elements/tags/TagSelectState";
import {DimensionsMultiSelectState} from "../../elements/dimensions/multi-select/dimensionsMultiSelectState";
import lodash from "lodash";


export const EditUtmState = types.model("EditUtmState", {
  utm: types.maybe(Utm),
  uuid: types.optional(types.string, ""),
  isLoading: types.optional(types.boolean, false),
  isMadeDefault: types.optional(types.boolean, false),
  groupSelectStore: types.maybe(types.late(() => types.reference(GroupSelectState))),
  tagSelectStore: types.maybe(types.late(() => types.reference(TagSelectState))),
  isExclusivelyGroupOwned: types.optional(types.boolean, true),
  dimensionsMultiSelectStore: types.maybeNull(types.late(() => types.reference(DimensionsMultiSelectState))),
  dimensionAll: types.optional(types.boolean, true),
  isDimensionSelectVisible: types.optional(types.boolean, false),
})
  .actions(self => ({
    setOnSelectChanges() {
      self.groupSelectStore?.setConsumer(self.selectGroup)
    },
    selectGroup(obj) {
      self.dimensionsMultiSelectStore?.getListOnGroup(obj?.uuid)
    },
    handleIsDefault() {
      self.isMadeDefault = !self.isMadeDefault
    },
    hydrateForm(utm) {
      self.utm.hydrateUtm(utm)
      self.groupSelectStore.selectGroup(utm?.owner?.groupUuid)
      self.dimensionsMultiSelectStore?.getListOnGroup(utm?.owner?.groupUuid)
      // self.tagSelectStore.setSelectedTags(utm?.tags?.flatMap(tag.uuid))
      self.tagSelectStore.setSelectedTags(utm?.tags?.flatMap((tag)=> tag.uuid))
      self.isExclusivelyGroupOwned = utm?.isExclusivelyGroupOwned;
      self.hydrateDimension(utm?.owner?.dimensions)
    },
    toggleIsExclusivelyGroupOwned(){
      self.isExclusivelyGroupOwned = !self.isExclusivelyGroupOwned
    },
    toggleDimensionAll(){
      self.dimensionAll = !self.dimensionAll
      self.toggleIsDimensionSelectVisible(!self.dimensionAll)
      self.dimensionAll ? self.dimensionsMultiSelectStore.selectAllDimensions() : self.dimensionsMultiSelectStore.reset()
    },
    toggleIsDimensionSelectVisible(visible){
      self.isDimensionSelectVisible = visible
    },
    hydrateDimension(dimensions = []){
      const isDimensionAll = Object.values(dimensions).every(item => lodash.isEmpty(item));
      self.dimensionAll = !isDimensionAll
      self.toggleDimensionAll()
      if(!self.dimensionAll){
        self.dimensionsMultiSelectStore.hydrateDimensions(dimensions)
      }
    },
    fetchWithID: flow(function* f() {
      self.isLoading = true
      const manager = getEnv(self).utmManager
      const res = yield manager.getUtm(self.uuid)
      self.hydrateForm(res)
      self.isLoading = false
    }),
    validateForm() {
      let isValid = true;
      isValid = self.utm.validateUtmModel() // validate fields other than name
      if(self.utm.name === ""){
        self.utm.setNameIsInvalid(true)
        isValid = false;
      }
      return isValid;
    },
    updateUtm: flow(function* f() {
      if (self.validateForm()) {
        self.isLoading = true
        const manager = getEnv(self).utmManager
        const resp = yield manager.update(self);
        if (resp) {
          new Notification()
            .setType("success")
            .setMessage(`${resp?.name} UTM updated`)
            .send();
          return resp
        }
        self.isLoading = false
      }
    }),

  })).views((self) => ({}))
