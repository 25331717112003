import { RouterState, RouterStore } from "mobx-state-router";
import { TagListModel } from "../../models/tags/Tag";
import TagsListViewState from "../../components/configs/tags/TagsListViewState";
import { buildTagsListColumns } from "../../components/configs/tags/TagListView";
import { TagFormState } from "../../components/configs/tags/TagFormState";
import React from "react";
import async from "../../components/Async";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import {ModalState} from "../../components/elements/modal/GenericModal";

const TagListPage = async(() => import("../../pages/tags/TagList"));
const CreateEditTagPage = async(() => import("../../pages/tags/Tag"));

export const viewMap = {
  tagList: <TagListPage/>,
  createTag: <CreateEditTagPage/>,
  editTag: <CreateEditTagPage/>,
  deleteTag: <CreateEditTagPage/>,
}

export const routeDefinitions = [
  {
    path: "tagList",
    name: "Tags",
    icon: faTags,
    component: TagListPage,
    //header: "Configuration",
    children: null,
  }
]

export const routeTransitions = [
  {
    name: 'tagList',
    pattern: '/configs/tags',
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;

      if(!rootStore.rootUiStore.tagsListViewState) {
        const groupSelectState = rootStore.rootUiStore.groupSelectStore;
        groupSelectState.selectGroup(undefined);
        const tagStore = rootStore.domainStore.tagStore;
        tagStore.init(); //reset tags
        const tags = TagListModel.create({
          tagStore: tagStore
        });
        const modalState = ModalState.create({});
        const advFilterSelectState = rootStore.rootUiStore.advFilterSelectState;
        const codeOwnerFilterSelectState = rootStore.rootUiStore.codeOwnerFilterSelectState;
        const dimensionSelectViewState = rootStore.rootUiStore.dimensionSelectViewState;
        const tagFilterSelectState = rootStore.rootUiStore.tagFilterSelectState;
        const groupFilterSelectState = rootStore.rootUiStore.groupFilterSelectState;
        advFilterSelectState.reset();
        codeOwnerFilterSelectState.reset();
        dimensionSelectViewState.reset();
        tagFilterSelectState.reset();
        groupFilterSelectState.reset();

        const tagSelectStore = rootStore.rootUiStore.tagSelectStore;
        const tagsListViewState = TagsListViewState.create({
          store: tags,
          // columns: buildTagsListColumns(routerStore),
          groupSelectStore: groupSelectState,
          tagSelectStore: tagSelectStore,
          codeOwnerFilterSelectState: codeOwnerFilterSelectState,
          advFilterSelectState: advFilterSelectState,
          dimensionSelectViewState: dimensionSelectViewState,
          tagFilterSelectState: tagFilterSelectState,
          modalStore: modalState,
          groupFilterSelectState: groupFilterSelectState
        });

        console.log("Attaching Page State : tagsListViewState ")
        tagsListViewState.setColumns(buildTagsListColumns(routerStore, tagsListViewState))
        await rootStore.rootUiStore.attachPageState('tagsListViewState', tagsListViewState)
      }
      rootStore.rootUiStore.tagsListViewState.refresh()
      await  rootStore.rootUiStore.tagsListViewState?.getFilters();

    },
    onExit: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;

    }
  },
  {
    name: 'createTag',
    pattern: '/configs/tags/create',
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
      const groupSelectState = rootStore.rootUiStore.groupSelectStore;
      const tagSelectStore = rootStore.rootUiStore.tagSelectStore;
      const dimensionsMultiSelectState = rootStore.rootUiStore.dimensionsMultiSelectState;
      let {queryParams} = toState;


      //groupSelectState.selectGroup(undefined);


      const tagStore = rootStore.domainStore.tagStore;
      tagStore.init(); //reset tags
      const tags = TagListModel.create({
        tagStore: tagStore
      });
      const snapShot = (queryParams.q)?rootStore.rootUiStore?.globalCache[`clone-tag:${queryParams.q}`]:null
      let createTagFormState;
      if(snapShot) {
        groupSelectState.selectGroup(snapShot?.owner?.groupUuid);
        tagSelectStore?.setSelectedTags(snapShot.tags);
        dimensionsMultiSelectState.init();
        dimensionsMultiSelectState.setDefaults();
        dimensionsMultiSelectState.getListOnGroup(snapShot?.owner?.groupUuid);

        createTagFormState = TagFormState.create({
          groupSelectStore: groupSelectState,
          name: "",
          tagSelectStore,
          store:tags,
          isExclusivelyGroupOwned: snapShot?.isExclusivelyGroupOwned,
          dimensionsMultiSelectStore: dimensionsMultiSelectState,
        });
      } else {
        groupSelectState.selectGroup(groupSelectState.groupStore?.groups[0])
        tagSelectStore?.tagStore?.init(); //reset tags
        tagSelectStore?.setSelectedTags([]); //need to reset tags when on new page due to reuse domain store
        dimensionsMultiSelectState.init();
        dimensionsMultiSelectState.setDefaults();
        dimensionsMultiSelectState.getListOnGroup(groupSelectState.groupStore?.groups[0]);

        createTagFormState = TagFormState.create({
          groupSelectStore: groupSelectState,
          tagSelectStore,
          store: tags,
          dimensionsMultiSelectStore: dimensionsMultiSelectState,
        });
      }


      await rootStore.rootUiStore.attachPageState('tagFormState', createTagFormState);
      snapShot && createTagFormState.hydrateDimension(snapShot?.owner?.dimensions);
    },
  },
  {
    name: 'editTag',
    pattern: '/configs/tags/edit/:uuid',
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {params} = toState
      const {rootStore} = routerStore.options;
      const groupSelectState = rootStore.rootUiStore.groupSelectStore;
      const tagSelectStore = rootStore.rootUiStore.tagSelectStore;
      const dimensionsMultiSelectState = rootStore.rootUiStore.dimensionsMultiSelectState;
      dimensionsMultiSelectState.init();
      dimensionsMultiSelectState.setDefaults();

      groupSelectState.selectGroup(undefined);
      tagSelectStore?.tagStore?.init(); //reset tags
      tagSelectStore?.setSelectedTags([]); //need to reset tags when on new page due to reuse domain store

      const tagStore = rootStore.domainStore.tagStore;
      tagStore.init(); //reset tags
      const tags = TagListModel.create({
        tagStore: tagStore
      });

      const editTagFormState = TagFormState.create({
        title: "Edit Tag",
        groupSelectStore: groupSelectState,
        tagSelectStore,
        store: tags,
        dimensionsMultiSelectStore: dimensionsMultiSelectState,
      });

      await rootStore.rootUiStore.attachPageState('tagFormState', editTagFormState);
      await editTagFormState.hydrateForm(params.uuid)
    },
  },
  {
    name: 'deleteTag',
    pattern: '/configs/tags/delete/:uuid',
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
      const groupSelectState = rootStore.rootUiStore.groupSelectStore;
      const tagStore = rootStore.domainStore.tagStore;
      tagStore.init(); //reset tags
      const tags = TagListModel.create({
        tagStore: tagStore
      });

      const deleteTagFormState = TagFormState.create({
        title: "Delete Tag",
        groupSelectStore: groupSelectState,
        store: tags,
      });
      await rootStore.rootUiStore.attachPageState('tagFormState', deleteTagFormState);
    },
  },

]
