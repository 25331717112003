import { flow, getEnv, types } from "mobx-state-tree";
import {TagSelectState} from "../../elements/tags/TagSelectState";
import {GroupSelectState} from "../../elements/groups/GroupSelectState";
import Group from "../../../models/Group";
import lodash from "lodash";
import Notification from "../../../utils/Notification";

const BatchFormState = types.model('BatchFromState',{
  title: types.string,
  tagSelectStore: types.maybe(types.late(() => types.reference(TagSelectState))),
  selectedTags: types.optional(types.array(types.string), []),
  formHasErrors: types.optional(types.boolean, false),
  errorMessage: types.optional(types.string, "Null"),
  batchName: types.optional(types.string, ""),
  // groupSelectStore: types.maybe(types.reference(GroupSelectState)),
  // selectedGroup: types.maybe(types.reference(Group)),
}).volatile((self)=>({
  handleSubmit: types.fn, //this type doesnt really exist
  handleCancel: types.function,
  // batchManager: types.other,
  uploadFiles: types.fn,
  downloadType: types.string,
  searchParams: {}
}))
  .actions((self)=>({
    setUploadFiles(fn){
      self.uploadFiles = fn;
      // self.files = files;
    },
    setHandleSubmit(fn){
      self.handleSubmit = fn
    },
    handleHasErrors(boolean) {
      self.formHasErrors = boolean
    },
    handleErrorMessage(message) {
      self.errorMessage = message
    },
    setHandleCancel(fn){
      // self.handleCancel = fn
    },
    setOnSelectChanges() {
      console.log("batch form group")
      self.tagSelectStore?.setConsumer(self.selectTag)
      // self.groupSelectStore?.setConsumer(self.selectGroup)
    },
    addSearchParams(param){
      self.searchParams = {...self.searchParams, ...param}
    },
    removeSearchParams(names){
      if(names){
        names.map((name) => delete self.searchParams[name])
      }
    },
    selectTag(arr) {
      self.selectedTags = arr.flatMap((tag)=> tag.uuid)
    },
    selectGroup(obj) {
      self.selectedGroup = obj;
      console.log("select group", obj?.uuid)
      self.addSearchParams({groupUuid: obj?.uuid})
    },
    setDownloadType(type){
      self.downloadType = type
    },
    handleTagsDownload: flow(function* f(data, sortParams){
      const manager = getEnv(self).bulkManager;
      const queryParams = {
        ...sortParams,
        ...self.searchParams
      }
      const config = {
        params: queryParams
      }
      const res = yield manager.handleTagsDownload(data, self, config);
      self.formHasErrors && new Notification()
        .setType("error")
        .setMessage(`${self.title} failed updating`)
        .send();
      !self.formHasErrors && new Notification()
        .setType("success")
        .setMessage(`${self.title} updated`)
        .send();
      return res;
    }),
    handleCsvUpload: flow(function* f(data){
      const manager = getEnv(self).bulkManager;
      const res = yield manager.handleCsvUpload(data, self);
      self.formHasErrors && new Notification()
        .setType("error")
        .setMessage(`${self.title} failed updating`)
        .send();
      !self.formHasErrors && new Notification()
        .setType("success")
        .setMessage(`${self.title} updated`)
        .send();
      return res;
    }),
    handleBatchNameChange(e){
      self.batchName = e.target.value
    },
    afterCreate(){
      // self.batchManager = new BulkManager()
    },
  }))


export default BatchFormState
