import React from "react";
import {
  Button,
  Col,
  Row, UncontrolledTooltip
} from "reactstrap";
import { observer } from "mobx-react-lite";
import * as Icon from "react-feather";
import RuleTypeSelectView from "../../../../elements/ruleType/RuleTypeSelectView";
import { withTheme } from '@rjsf/core';
import { Theme as Bootstrap4Theme } from '@rjsf/bootstrap-4';
import validator from '@rjsf/validator-ajv8';
import { ObjectFieldTemplateProps } from "@rjsf/utils";
import GoogleMapField from "./fieldsAndWidgets/GoogleMapField";
import CustomCheckboxWidget from "./fieldsAndWidgets/CustomCheckboxWidget";
import {TimeZonePopUp} from "../../../../elements/timeZone/TimeZonePopUp";
import {DateTimeWidget} from "../../../../elements/dateTimeWidget/DateTimeWidget";
import { TimeWidget } from "src/components/elements/dateTimeWidget/TimeWidget";
const ThemedForm = withTheme(Bootstrap4Theme);
function ObjectFieldTemplate(props: ObjectFieldTemplateProps) {
  return (
    <Row>
      {props.properties.map((element) => {
          if (!element.hidden) {
            if (element.content.props.schema.title === "Operator") {
              return (
                  <div style={{width:"67px"}}>
                {element.content}
                </div>)
            }
           return <Col className='property-wrapper'>{element.content}</Col>
         }})}
    </Row>
  );
}

const BasicRule = observer(({
                              child,
                              order,
                              indent,
                              unindent,
                              showIndent,
                              showUnindent,
                              remove,
                              ruleTypeStore,
                              volatileRuleTypeStore,
                              position,
                              rootNode,
                              readonly
                            }) => {

  // function setPositionsRecursively(node) {
  //   if (node.children.length > 0) {
  //     for (const childNode of node.children) {
  //       childNode.setPosition(`${node.position}_${childNode.order}`);
  //       setPositionsRecursively(childNode);
  //     }
  //   }
  // }

  const fields = {
    googleMap: GoogleMapField,
  };
  const countries =
    rootNode?.parent?.selectedCountries.length > 0?
    rootNode?.parent?.selectedCountries :
    rootNode?.parent?.parent?.selectedCountries;

  const regions =
    rootNode?.parent?.selectedRegions.length > 0?
    rootNode?.parent?.selectedRegions :
    rootNode?.parent?.parent?.selectedRegions;

  const widgets = {
    CustomCheckboxWidget: (props) => (
      <CustomCheckboxWidget {...props}  country={countries} region={regions} onCountryChange = {handleDataForCountry} onRegionChange ={handleDataForRegion} />
    ),
    TimeZonePopUp:TimeZonePopUp,
    DateTimeWidget:DateTimeWidget,
    TimeWidget:TimeWidget
  };

  const handleIndent = async(node) => {
    node.indent()
    // setPositionsRecursively(node)
  }

  const handleUnIndent = async(node) => {
    node.unindent()
    // setPositionsRecursively(node)
  }
  // child?.setConsumer()//clear input data when rule type is changed
  child.ruleTypeSelectState.setClearInputData(child.updateRuleInputData)
  // child.ruleTypeSelectState.setClearInputData(child.updateRuleInputData(child.ruleTypeSelectState.setClearInputData()))

  // child.updateRuleInputData(child.ruleTypeSelectState.setClearInputData())

  const handleDataForCountry = (data) => {
    child.setSelectedCountries(data)
    if(child.children.length>0){
      child.children.forEach(child => {
        const inputData=JSON.parse(child.ruleInputData)
        if (inputData.factValue===null || inputData.factValue?.length===0){
          child.ruleTypeSelectState.setSelect(undefined)
          child.children.forEach(child => {
            child.remove()
          })
        }
    })
    }
  }

  const handleDataForRegion = (data) => {
    child.setSelectedRegions(data)
    if(child.children.length>0){
      child.children.forEach(child => {
        const inputData=JSON.parse(child.ruleInputData)
        if (inputData.factValue===null || inputData.factValue?.length===0){
          child.ruleTypeSelectState.setSelect(undefined)
        }
      })
    }
  }
  const validRuleTypes = ["city", "countryRegion", "metroCode", "postalCode"]
    //[ "country", "lang", "device", "carousel", "date", "dateTimeRule", "userScanCount", "tagScanCount", "globalScanCount", "userTagScanCount"];
  let errorMessage = ""

  if (
    rootNode?.ruleTypeSelectState?.selected !== undefined &&
    validRuleTypes.includes(rootNode?.ruleTypeSelectState?.selected?.name)
  ) {
    if( rootNode?.ruleTypeSelectState?.selected?.name === "city"){
      if(!(rootNode?.parent?.ruleTypeSelectState?.selected?.name==="country" || rootNode?.parent?.parent?.ruleTypeSelectState?.selected?.name==="country")){
        errorMessage = "All location rules require selection of a country rule above."
      }
    }
    else if(rootNode?.parent?.ruleTypeSelectState?.selected?.name!=="country"
      && rootNode?.ruleTypeSelectState?.selected?.name!=="metroCode"
      && rootNode?.ruleTypeSelectState?.selected?.name!=="carousel")
     errorMessage = "All location rules require selection of a country rule above."
    else if (rootNode?.parent?.ruleTypeSelectState?.selected?.name!=="country" && rootNode?.ruleTypeSelectState?.selected?.name==="metroCode")
      errorMessage = "MetroCode/DMA rule requires selection of a country rule above and only applies with United States."
  }


  return (<>

    <Row sm={12}>
      <Col  className="pt-sm-9" style={{paddingLeft:"30px"}}>
        <div style={{width:(child.position.length===3)?"":`${(child.position.length + 2)*7}px`}}>
        {child.positionDisplayed(position)}
        </div>
      </Col>
      <Col>
        <div style={{width:"60px",marginTop:"35px"}}>
        {!showUnindent &&
          <a  id="unindent-btn-disabled" disabled={true} hidden={true} onClick={() => handleUnIndent(child)} style={{cursor:"pointer"}}>
            <Icon.ArrowLeft size={"28px"} color={"#256ea4"} id={`moveLeft${child.position}`}/>
            This block of code is never displayed !!!!
            <UncontrolledTooltip placement="bottom" target={`moveLeft${child.position}`}>Move sub-rule to level {child.levelLeftPositionDisplayed(position)}</UncontrolledTooltip>
          </a>
        }
        {showUnindent &&
          <a  id="unindent-btn-enabled" disabled={false} hidden={false} onClick={() => handleUnIndent(child)} style={{cursor:"pointer"}}>
            <Icon.ArrowLeft size={"28px"} color={"#256ea4"} id={`moveLeft${child.position}`}/>
            <UncontrolledTooltip placement="bottom" target={`moveLeft${child.position}`}> Move sub-rule to level  {child.levelLeftPositionDisplayed(position)}</UncontrolledTooltip>
          </a>
        }
        {!showIndent &&
          <a  id="indent-btn-disabled" disabled={true} hidden={true} onClick={() => handleIndent(child)} style={{cursor:"pointer"}}>
            <Icon.ArrowRight size={"28px"} color={"#256ea4"} id={`moveRight${child.position}`}/>
            This block of code is never displayed !!!!
            <UncontrolledTooltip placement="bottom" target={`moveRight${child.position}`}> Move sub-rule to level {child.nextLevelRightPositionDisplayed(position)}: 1</UncontrolledTooltip>
          </a>
        }
        {showIndent &&
          <a  id="indent-btn-enabled" disabled={false} hidden={false} onClick={() => handleIndent(child)} style={{cursor:"pointer"}}>
            <Icon.ArrowRight  size={"28px"} color={"#256ea4"} id={`moveRight${child.position}`}/>
            <UncontrolledTooltip placement="bottom" target={`moveRight${child.position}`}> Move sub-rule to level {child.nextLevelRightPositionDisplayed(position)}</UncontrolledTooltip>
          </a>
        }
        </div>
      </Col>
        <a id="add-child" className="delete" style={{paddingTop:"52px"}}  onClick={e => child.addNewChild(ruleTypeStore?ruleTypeStore:volatileRuleTypeStore)}>
          <Icon.CornerDownRight size="30px" style={{cursor:"pointer"}} id={`UncontrolledTooltip${child.position}`}/>
          <UncontrolledTooltip placement="bottom" target={`UncontrolledTooltip${child.position}`}> Add Sub-rule {child.subrulePositionDisplayed(position)}</UncontrolledTooltip>
        </a>

      <Col sm={2}>
        <div style={{marginBottom:"8px"}}>Rule type</div>
        <RuleTypeSelectView ruleTypeSelectState={child?.ruleTypeSelectState} />
      </Col>
    {/*<Col >*/}
    {/*  <Row sm={4}></Row>*/}
    {/*  <a className="delete" onClick={e => child.addNewChild(ruleTypeStore?ruleTypeStore:volatileRuleTypeStore)}>*/}
    {/*    <Icon.CornerDownRight size="30px" style={{cursor:"pointer"}} />*/}
    {/*  </a>*/}
    {/*</Col>*/}

      <Col sm={7} style={{alignItems:"center"}}>
        {(child?.ruleTypeSelectState?.selected !== undefined) &&
          <ThemedForm
            templates={{ ObjectFieldTemplate }}
            validator={validator}
            fields={fields}
            widgets={widgets}
            schema={child?.ruleTypeSchemaJson()}
            formData={child?.ruleInputDataView()}
            uiSchema={child?.uiSchemaJson()}
            onChange={(data) => child?.updateRuleInputData(data.formData)}
            showErrorList={false}
            liveValidate = {child.liveValidateSchema}
            transformErrors={child.transformErrors}
            readonly={readonly}
          >
            <div></div>
          </ThemedForm>
        }
        <small className={"text-danger"}>{errorMessage}</small>
      </Col>
      <Col></Col>
      <Col></Col>
      <Col></Col>
      {!readonly &&
        <div style={{verticalAlign:"bottom", position:"absolute",right:"40px",marginTop:"35px"}}>
          <a id="delete-rule " className="delete" onClick={() => remove()}>
            <Icon.Delete color={"#dc3545"} style={{cursor:"pointer"}} id={`Delete${child.position}`}/>
            <UncontrolledTooltip placement="bottom" target={`Delete${child.position}`}> Delete rule {child.positionDisplayed(position)}</UncontrolledTooltip>
          </a>
        </div>
      }
    </Row>
    </>
  );
});

export default BasicRule;
