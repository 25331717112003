import {flow, getEnv, types} from "mobx-state-tree";
import {GroupSelectState} from "../../elements/groups/GroupSelectState";
import DesignerState from "../designer/DesignerState";
import QrPreviewState from "../qrpreview/QrPreviewState";
import ImageConfigManager from "../../../managers/ImageConfigs/ImageConfigManager";
import HttpClient from "../../../services/HttpClient";
import PreviewManager from "../../../managers/preview/PreviewManager";
import QrConfiguration from "../../../models/QrConfiguration";
import {QrConfigSelectState} from "../../elements/qrconfig/QrConfigSelectState";
import Notification from "../../../utils/Notification";
import {setDefaultBackgroundColor} from "../../../utils/common";
import {generateImageCheckscannability} from "../../../utils/scannability";
import { NULL } from "sass";

export const DesignerModalState = types.model("DesignerModalState", {
  isLoading: types.optional(types.boolean, false),
  noDesignOpen:types.optional(types.boolean, false),
  customOpen:types.optional(types.boolean, true),
  groupSelectStore: types.maybe(types.reference(GroupSelectState)),
  isMadeDefault:types.optional(types.boolean, false),
  designerViewStore:types.maybe(types.late(() => DesignerState)),
  qrConfigSelectStore:types.maybe(types.reference(QrConfigSelectState)),
  qrPreviewStore:types.maybe(types.late(() => QrPreviewState)),
  isEditCodePage: types.optional(types.boolean, false),
}).volatile((self) => ({
  updateImageCodeUuid: types.fn,
  updateImageSelectedInPreview: types.fn,
}))
  .actions(self => {
    return ({
      handleIsDefault() {
        self.isMadeDefault = !self.isMadeDefault
        console.log(self)
      },
      setNoneOpen() {
        self.noDesignOpen = true
        self.customOpen = false
      },
      setCustomOpen() {
        self.noDesignOpen = false
        self.customOpen = true
      },
      setConsumer(fn) {
        self.updateImageCodeUuid = fn
      },
      setConsumeUpdateImageSelectedInPreview(fn){
        self.updateImageSelectedInPreview = fn
      },
      consumeUtmSelect() {
        self.utmSelectState.setConsumer(self.fetchUtmWithID)
      },
      createQrPreview: flow(function* f(extendConfig) {
        const extend = JSON.parse(extendConfig)
        setDefaultBackgroundColor(extend)
        const formDataForIcon = JSON.stringify({...extend ,size: 0.1, resolution: 300 })
        const configForIcon = {
          name:"",
          qrImageFormData:formDataForIcon,
          shortUrl:""
        }
        const designerHttpClient = new HttpClient("https://nigiu8dgm4.execute-api.us-east-2.amazonaws.com/dev")
        const previewManager = new PreviewManager(designerHttpClient)
        const res = yield previewManager.create(configForIcon)
        self.isLoading = false
        return res
      }),
      applyDesign: flow(function* f() {
        self.isLoading = true
        if(self.noDesignOpen) {
          // self.designerViewStore.qrConfigSelectStore.selectConfig(self.designerViewStore.qrConfigSelectStore.configStore.defaultConfiguration[1])
          self.designerViewStore.qrConfigSelectStore.configStore.removeImageConfig()
          self.updateImageCodeUuid("")
          self.isLoading = false
          return "no design selected"
        }
        const {extendConfig} = self?.designerViewStore
        const isScannable = yield generateImageCheckscannability(JSON.parse(extendConfig))
        const resp = yield self.createQrPreview(extendConfig)
        let isDefaultDesignerConfig = true
        if(JSON.parse(extendConfig).isDefaultDesigner === false) {
          isDefaultDesignerConfig = JSON.parse(extendConfig).isDefaultDesigner
        }
        //TODO:
        if (resp?.status === 201) {
          let qrPreview;
          if(!isDefaultDesignerConfig) {
            qrPreview = `data:image/png;base64,` + resp.data.split("'")[1].split("=")[0]
          } else {
            qrPreview = `data:image/${JSON.parse(resp.config.data).imageFormat};base64,` + resp.data.split("'")[1].split("=")[0]
          }
          //add call to the design manager
          const configImagePayload = {
            qrImageFormData:extendConfig,
            "name":"",
            "qrPreview":qrPreview
          }
          const imageConfigManager = getEnv(self).imageConfigManager
          const res = yield imageConfigManager.applyToCode(configImagePayload)
         if(res) {
           const newConfig = QrConfiguration.create({
             level: res.data.level,
             uuid: res.data.uuid,
             label: res.data.name,
             name: res.data.name,
             qrConfig: res.data.data,
             image: res.data.qrPreview
           })
           self.designerViewStore.qrConfigSelectStore.configStore.addImageToList(newConfig)
           self.updateImageCodeUuid(newConfig.uuid)
           self.isLoading = false
           return res
         }
          self.isLoading = false
          return resp
        }else{
          new Notification()
            .setType("error")
            .setMessage("Error applying design")
            .send();
          self.isLoading = false
        }
        self.isLoading = false
      }),

      updateDesign: flow(function* f(uuid) {
        self.isLoading = true
        if(self.noDesignOpen) {
          self.designerViewStore.qrConfigSelectStore.configStore.removeImageConfig()
          self.updateImageCodeUuid("")
          self.isLoading = false
          return "no design selected"
        }
        const {extendConfig} = self?.designerViewStore
        const imageConfigManager = getEnv(self).imageConfigManager
        const res = yield imageConfigManager.updateApplyToCode(extendConfig,uuid)
        if(res){
          self.designerViewStore.qrConfigSelectStore.configStore.updateImageConfig(res)
          self.isLoading = false
          return res
        }
      })
    });
  }).views((self) => ({}))
