import { types } from 'mobx-state-tree'
import lodash from "lodash";

const TenantStore = types.model('TenantStore',{
  storeUuid: types.identifier,
  name: types.maybeNull(types.string),
  tenantUuid: types.maybeNull(types.string),
}).actions((self)=>({
  initWithData(tenantInfo) {
    if(tenantInfo && lodash.isArray(tenantInfo)) {
      tenantInfo = tenantInfo[0];
    }
    self.name = tenantInfo?.name;
    self.tenantUuid = tenantInfo?.tenantUuid;
  },
}))

export default TenantStore;
