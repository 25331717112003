import {flow, getEnv, types} from 'mobx-state-tree';
// import Group from "../../models/Group";
import {GroupSelectState} from "../../elements/groups/GroupSelectState";
import {TagListModel} from "../../../models/tags/Tag";
import {TableColumn} from "../../tables/ScanbuyTable";
import {TagModel as Tag} from "../../../models/tags/TagModel";
import {TagSelectState} from "../../elements/tags/TagSelectState";
import {formConstants} from "../../../utils/constants";
import {AdvancedConfFilterSelectState} from "../../elements/filters/AdvancedConfFilterSelectState";
import lodash from "lodash";
import {CodeOwnerFilterSelectState} from "../../elements/filters/CodeOwnerFilterSelectState";
import {DimensionSelectViewState} from "../../elements/dimensions/dimensionSelectViewState";
import {TagFilterSelectState} from "../../elements/filters/TagFilterSelectState";
import {GroupFilterSelectState} from "../../elements/filters/GroupFilterSelectState";
//
import {ModalState} from "../../elements/modal/GenericModal";
// import {GenericToastState} from "../elements/toast/GenericToastState";



const TagsListViewState = types.model('TagsListViewState', {
  store: types.maybe(types.late(() => TagListModel)), //types.maybe(types.late(() => types.reference(TagStore))) <-- hold the store
  columns: types.maybe(types.array(types.late(() => TableColumn))),
  groupSelectStore: types.maybe(types.reference(GroupSelectState)),
  tagSelectStore: types.maybeNull(types.reference(TagSelectState)),
  codeOwnerFilterSelectState: types.maybeNull(types.reference(CodeOwnerFilterSelectState)),
  advFilterSelectState: types.maybeNull(types.reference(AdvancedConfFilterSelectState)),
  tagFilterSelectState: types.maybeNull(types.reference(TagFilterSelectState)),
  groupFilterSelectState: types.maybeNull(types.reference(GroupFilterSelectState)),
  dimensionSelectViewState: types.maybeNull(types.reference(DimensionSelectViewState)),
  selectedTags: types.optional(types.array(types.reference(Tag, {acceptsUndefined: true})), []),
  isFilterTypeOR: types.optional(types.boolean, true),
  isFilterVisible: types.optional(types.boolean, true),
  filterGroup: types.optional(types.boolean, true),
  // selectedGroup: types.maybe(types.reference(Group)),
  modalStore: types.maybe(ModalState),
  // toastStore: types.maybe(GenericToastState),

}).volatile((self) => ({
  modalComponent: types.string,
  modalComponentStore: types.string,
  toastComponentStore: types.string
}))
.actions((self) => ({
  // setOnSelectChanges() {
  //   self.groupSelectStore.setConsumer(self.selectGroup)
  //
  // },
  //
  // selectGroup(obj) {
  //   //self.selectedGroup = obj;
  //   self.store.addSearchParams({groupUuid: obj.uuid})
  //   self.refresh()
  // },
  setColumns(col) {
    self.columns = col
  },
  handleRowClick(row, componentStore, component) {
    self.modalComponent = component;
    self.modalComponentStore = componentStore
    self.modalStore.handleTitle(componentStore.title)
    self.modalStore.toggle()
  },
  setOnSelectChanges() {
    self.groupSelectStore.setMultiSelectConsumer(self.selectGroup)

    self.tagSelectStore?.setConsumer(self.selectTag)
    self.advFilterSelectState?.setConsumer(self.selectFilter)
    self.codeOwnerFilterSelectState?.setConsumer(self.selectFilter)
    self.dimensionSelectViewState?.setFilterConsumer(self.selectFilter)
    self.tagFilterSelectState?.setConsumer(self.selectFilter)
    self.groupFilterSelectState?.setConsumer(self.selectFilter)
  },
  toggleFilterVisible() {
    self.isFilterVisible = !self.isFilterVisible
  },
  selectGroup(obj) {
    obj = obj || []
    self.store.addSearchParams({groupUuid: obj.flatMap((row) => row.uuid).join(",")})
    self.refresh()
  },
  selectTag(obj) {
    obj = obj || []
    self.store.addSearchParams({tags: obj.flatMap((row) => row.uuid).join(",")})
    self.refresh()
  },
  onSearchChange(txt){
    console.log(txt?.currentTarget?.value);
    const searchText = txt?.currentTarget?.value;
    if(searchText){
      if(searchText.length > 2) {
        self.store.removeSearchParams(["q"]);
        self.store.addSearchParams({ q: searchText })
        self.refresh()
      } else {
        self.store.removeSearchParams(["q"])
      }
    } else {
      self.store.removeSearchParams(["q"]);
      self.refresh()
    }
  },
  selectFilter(obj, covered){

    const shouldRemoveDimensions = covered.includes("dimensionUuid")
    const regex = shouldRemoveDimensions ? /^dimension/ : undefined;
    self.store.removeSearchParams(covered, regex);

    if(obj){
      const transpiledDimensions = {};
      if(!lodash.isEmpty(obj.dimensions)){
        obj.dimensions.forEach(dimension => {
          const [uuid, value] = dimension.split('_');
          if (transpiledDimensions.hasOwnProperty(`dimension[${uuid}]`)) {
            transpiledDimensions[`dimension[${uuid}]`] += ',' + value;
          } else {
            transpiledDimensions[`dimension[${uuid}]`] = value;
          }
        });
        delete obj.dimensions
      }

      const params = lodash.mapValues(obj,(value, key) => {
        return {}[key] = value.join(',');
      });
      self.store.addSearchParams({...params,...transpiledDimensions})
      self.refresh()
      const prevGroupUuid = self.prevGroupUuid || [];
      const currentGroupUuid = obj.groupUuid || [];
      if (!lodash.isEqual(prevGroupUuid, currentGroupUuid)) {
        self.filterGroup = false
        self.prevGroupUuid = currentGroupUuid;
      }
      self.getFilters();
    }
  },
  selectFilterMethod() {
    if (!self.isFilterTypeOR) {
      self.isFilterTypeOR = !self.isFilterTypeOR
      self.store.addSearchParams({tagFilterType: formConstants.Mode.OR})
    } else {
      self.isFilterTypeOR = !self.isFilterTypeOR
      self.store.addSearchParams({tagFilterType: formConstants.Mode.AND})
    }
    self.refresh()
  },
  handleFilterChange(e) {
    //self.selectedGroup = e
    console.log("handleFilterChange")
    self.store.fetchAll(e);
  },
  refresh() {
    self.store.fetchAll()
  },
  getFilters() {
    self.store.fetchFilters();
  }
  // deleteRow(uuid) {
  //   // self.store.items = self.store.items.filter(item => item.uuid !== uuid)
  //   // commented out above is a filter that is not remounting component properly, having something to do with mobX,
  //   // therefore we fetchAll for now
  //   self.store.fetchAll()
  // },
  // afterCreate() {
  //   self.modalComponent = (<></>)
  //   self.modalComponentStore = {}
  //
  // }
}))

export default TagsListViewState;
