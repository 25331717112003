import React, {useState} from "react";
import {
  Button, Card,
  CardBody,
  CardHeader, CardFooter,
  CardTitle,
  Form,
  FormGroup, Input,
  Label,
  Nav, NavItem, NavLink,
  TabContent, TabPane,
  Row, Col
} from "reactstrap";
import { observer } from "mobx-react-lite";
import { StyledDropzone } from "../../elements/fileUpload/StyledDropzone";
import TagSelect from "../../elements/tags/TagSelect";
import { FormattedMessage } from "react-intl";
/**
 Form Component to Upload Batch
 */
const BatchForm = observer(({ batchFromState }) => {
  batchFromState.setOnSelectChanges();
  const { title, handleSubmit, uploadFiles, handleCancel, downloadType } = batchFromState;
  const [showTagDownloadBox, setShowTagDownloadBox] = useState(false);

  const [activeTab, setActiveTab] = useState('files');
  const toggleTab = (tabId) => {
    setActiveTab(tabId);
    batchFromState.setDownloadType(tabId);
  }
  
  const [buttonText, setButtonText] = useState('Upload');
  const handleBatchTypeChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === 'Download') {
      setShowTagDownloadBox(true);
      setButtonText('Download');
    } else {
      setShowTagDownloadBox(false);
      setButtonText('Upload');
    }
  };
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5">{title}</CardTitle>
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Batch Name:</Label>
            <Input type="text" 
                   name="batchName" 
                   onChange={batchFromState.handleBatchNameChange}
                   value={batchFromState.batchName}/>
          </FormGroup>
          <FormGroup>
            <Label>
              Batch Type :
            </Label>
            <Input
              type="select"
              id="batchType"
              name="batchType"
              onChange={handleBatchTypeChange}
            >
              <option>Create</option>
              <option>Publish</option>
              <option>Download</option>
            </Input>
          </FormGroup>
          <Nav tabs>
            <NavItem>
              <NavLink className={activeTab === 'files' ? 'active' : ''}
                       onClick={() => toggleTab('files')}>
                Select File :
              </NavLink>
            </NavItem>
            {showTagDownloadBox && (
            <NavItem>
              <NavLink className={activeTab === 'tags' ? 'active' : ''}
                       onClick={() => toggleTab('tags')} >
                Select Tags :
              </NavLink>
            </NavItem>)}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="files">
              <FormGroup>
                <StyledDropzone id="dropzone" name="dropzone" setUploadFiles={uploadFiles} />
              </FormGroup>
            </TabPane>
            {showTagDownloadBox && (
            <TabPane tabId="tags">
              <FormGroup>

                <Row className="p-1">
                  <Col>
                    <div className="custom-controls-stacked">
                      <TagSelect tagSelectStore={batchFromState.tagSelectStore}/>
                    </div>
                  </Col>
                </Row>
              </FormGroup>
            </TabPane>)}
          </TabContent>
          {/*<FormGroup>*/}
          {/*  <Label>*/}
          {/*    Select File :*/}
          {/*  </Label>*/}
          {/*  <Label>*/}
          {/*    Enter Tags:*/}
          {/*  </Label>*/}
          {/*  <StyledDropzone id="dropzone" name="dropzone" setUploadFiles={uploadFiles} />*/}
          {/*  {showTagDownloadBox && (*/}
          {/*    <FormGroup>*/}
          {/*      <Input type="text" name="tags" />*/}
          {/*    </FormGroup>*/}
          {/*  )}*/}
          {/*</FormGroup>*/}
          <FormGroup>
            <Button color="danger" onClick={handleCancel}>Cancel</Button>
            <Button color="primary" className="float-right">{buttonText}</Button>
          </FormGroup>
        </Form>
      </CardBody>
      {/* <CardFooter> <FormattedMessage id="batchForm.alertInfo.defaultMessage"/></CardFooter> */}
      {batchFromState.formHasErrors && <div className="alert alert" style={{
          fontSize: "20px",
          backgroundColor: "#faada7",
          width: "80%",
          marginLeft: "10%",
          padding:"5px",
          paddingLeft:"8px",
          paddingRight:"8px"
        }} role="alert">
          <div>{batchFromState.errorMessage}</div>
        </div>}
    </Card>
  );
});


export default BatchForm;
