import React from "react";
import { observer } from "mobx-react-lite";
import Select from "react-select";

const RuleTypeSelectView = observer(({ ruleTypeSelectState }) => {

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  const formatOptionLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      {/*<span style={groupBadgeStyles}></span>*/}
    </div>
  );

  return (<>
      {/*<label>*/}
      {/*  Select Type*/}
      {/*</label>*/}
      <Select
            sm={10}
            className="react-select-container"
            classNamePrefix="react-select"
            options={ruleTypeSelectState?.optionValues()}
            value={ruleTypeSelectState?.selected}
            onInputChange={(data) => ruleTypeSelectState?.search(data)}
            onChange={(data) => {
              ruleTypeSelectState?.clearInputData()
              ruleTypeSelectState?.select(data)
            }}
            // getOptionLabel={option => option.label}
            getOptionValue={option => option.uuid}
            formatGroupLabel={formatGroupLabel}
            formatOptionLabel={formatOptionLabel}
            // closeMenuOnSelect={false}
            placeholder="Select"
            isDisabled={ruleTypeSelectState?.isDisabled}
          />
      </>
    );
});

export default RuleTypeSelectView;
