import {types, flow, getEnv, getType} from "mobx-state-tree";
import Group from "../../../../models/Group";
import {TagSelectState} from "../../../elements/tags/TagSelectState";
import {GroupSelectState} from "../../../elements/groups/GroupSelectState";
import Ajv from 'ajv';
import addFormats from "ajv-formats"
import Notification from "../../../../utils/Notification";
import {DataExportTypeSelectState} from "../../../elements/dataExportType/DataExportTypeSelectState";
import {DimensionSelectViewState} from "../../../elements/dimensions/dimensionSelectViewState";
import lodash from "lodash";
import {DataExportType} from "../../../../models/data/exports/DataExportType";
import {DimensionsMultiSelectState} from "../../../elements/dimensions/multi-select/dimensionsMultiSelectState";

const defaultUISchema = {
  "columns": {
    'ui:widget': 'dualListBox'
  },
  "filter": {
    'toggle': {
      'ui:options': {
        'label': false
      },
      'ui:widget': 'filterToggle',
    },
    'ui:options': {
      'label': false
    },
    'definition': {
      'ui:options': {
        'label': false
      },
      "onColumns":{
        "items": {
          "name": {
            'ui:widget': 'redshiftColumnSelect',
            "ui:placeholder": "Select"
          }
        }
      },
    }
  },
  "useOr": {
    'ui:options': {
      'label': false
    },
    'ui:widget': 'filterToggle',
  },
  "orderBy": {
    'toggle': {
      'ui:options': {
        'label': false
      },
      'ui:widget': 'orderByToggle',
    },
    'ui:options': {
      'label': false
    },
    'definition': {
      'ui:options': {
        'label': false
      },
      "name": {
        'ui:widget': 'redshiftColumnSelect',
        "ui:placeholder": "Select"
      }
    }
  }
};

const DataExportFormState = types.model('DataExportFormState', {
  name: types.optional(types.string, ""),
  isLoading: types.optional(types.boolean, false),
  tagSelectStore: types.maybe(types.late(() => types.reference(TagSelectState))),
  selectedTags: types.optional(types.array(types.string), []),
  groupSelectStore: types.maybe(types.late(() => types.reference(GroupSelectState))),
  selectedGroups: types.maybe(types.array(types.safeReference(Group))),
  dataExportTypeSelectStore: types.maybe(types.late(() => types.reference(DataExportTypeSelectState))),
  dataExportTypeSelected: types.maybe(types.reference(DataExportType)),
  data: types.optional(types.string, '{}'),
  formHasErrors: types.optional(types.boolean, false),
  errorMessage: types.optional(types.string, "Null"),
  // isExclusivelyGroupOwned: types.optional(types.boolean, true),
  dimensionSelectViewState: types.maybeNull(types.reference(DimensionSelectViewState)),
  dimensionsMultiSelectStore: types.maybeNull(types.late(() => types.reference(DimensionsMultiSelectState))),
  dimensionAll: types.optional(types.boolean, true),
  isDimensionSelectVisible: types.optional(types.boolean, false),
  liveValidateSchema: types.optional(types.boolean, false),
  hideActions: types.optional(types.array(types.string), []),
}).volatile((self) => ({

})).actions(self => ({
  setDefaults() {
    self.isLoading = true;
    self?.setOnSelectChanges();
    self?.dataExportTypeSelectStore?.store?.getDataExportTypes()?.forEach(item => {
      if (item.type === 'default') {
        self?.dataExportTypeSelectStore?.select(item?.type);
        self?.selectDataExportType(item);
      };
    });
    self?.groupSelectStore?.selectGroup([]);
    self?.tagSelectStore?.setSelectedTags([]);
    // self?.tagSelectStore?.tagStore?.setFilterOn(self?.groupSelectStore?.groupStore?.groups[0].uuid);
    // TODO: if no group is selected then what to filter it by?
    self?.dimensionSelectViewState?.reset();

    self.isLoading = false;
  },
  handleNameChange(e) {
    self.name = e?.target?.value;
  },
  handleHasErrors(boolean) {
    self.formHasErrors = boolean;
  },
  handleErrorMessage(message) {
    self.errorMessage = message;
  },
  setOnSelectChanges() {
    self.groupSelectStore?.setMultiSelectConsumer(self.selectGroup);
    self.tagSelectStore?.setConsumer(self.selectTag);
    self.dataExportTypeSelectStore?.setConsumer(self.selectDataExportType);
  },
  selectGroup(arr) {
    arr = arr || [];
    self.selectedGroups = arr.flatMap((group) => group.uuid);
  },
  selectTag(arr) {
    self.selectedTags = arr.flatMap((tag) => tag.uuid);
  },
  selectDataExportType(obj) {
    self.dataExportTypeSelected = obj;
    // clear data when export type is updated
    self.data = JSON.stringify({});
  },
  toggleIsExclusivelyGroupOwned(){
    self.isExclusivelyGroupOwned = !self.isExclusivelyGroupOwned
  },
  toggleDimensionAll(){
    self.dimensionAll = !self.dimensionAll
    self.toggleIsDimensionSelectVisible(!self.dimensionAll)
    self.dimensionAll ? self.dimensionsMultiSelectStore.selectAllDimensions() : self.dimensionsMultiSelectStore.reset()
  },
  toggleIsDimensionSelectVisible(visible){
    self.isDimensionSelectVisible = visible
  },
  updateExportData(data) {
    self.data = JSON.stringify(data);
  },
  transformErrors(errors) {
    return errors.map(error => {
      return error;
    });
  },
  validateForm() {
    // if (!self?.groupSelectStore?.selectedGroups?.length) {
    //   new Notification()
    //     .setType("error")
    //     .setMessage("Please select at least one Group")
    //     .send();
    //   return false;
    // }

    const definition = JSON.parse(self?.dataExportTypeSelected?.definition);
    const data = self.getFormData();
    const ajv = new Ajv({allErrors: true, validateFormats: false});
    addFormats(ajv);
    const validate = ajv.compile(definition);
    let valid = validate(data);
    if (!valid) {
      self.liveValidateSchema = true;
      console.error(`${getType(self).name} Error: Failed to validate form data`, validate.errors);
    }
    return valid;
  },
  update: flow(function* (uuid) {
    self.isLoading = true;
    if (self.validateDimension() && self.validateForm()) {
      self.handleHasErrors(false);
      const response = yield getEnv(self).dataExportManager.update(uuid, self);
      if (self.formHasErrors) {
        new Notification()
          .setType("error")
          .setMessage(`${self.name} Data Export failed updating`)
          .send();
      } else {
        new Notification()
          .setType("success")
          .setMessage(`${self.name} Data Export updated`)
          .send();
        self.handleHasErrors(false);
      }
      self.isLoading = false;
      return response;
    }
    self.isLoading = false;
    return null;
  }),
  getSelectedDimensions() {
    const dimensions = self.dimensionsMultiSelectStore.getTranspiledDimensions();
    return Object.values(dimensions).every(l => !l.length) ? undefined : dimensions;
  },
  validateDimension() {
    if (!self.dimensionSelectViewState?.dimensionsEnabled) return true;
    const selectedDimensions = self.getSelectedDimensions();
    if (selectedDimensions && Object.values(selectedDimensions).some(l => l.length)) {
      // TODO: validate
    }
    return true;
    // if ((!lodash.isEmpty(selectedDimensions) &&
    //     !Object.values(selectedDimensions).every(l => !l.length)) || self.dimensionAll) {
    //   return true;
    // }
    // new Notification()
    //   .setType("error")
    //   .setMessage("Please select at least one dimension")
    //   .send();
    // return false;
  },
  saveNew: flow(function* () {
    self.isLoading = true;
    if (self.validateDimension() && self.validateForm()) {
      self.handleHasErrors(false);
      const response = yield getEnv(self).dataExportManager.save(self);
      if (self.formHasErrors) {
        new Notification()
          .setType("error")
          .setMessage(`${self.name} Data Export failed creating`)
          .send();
      } else {
        new Notification()
          .setType("success")
          .setMessage(`${self.name} Data Export created`)
          .send();
        self.handleHasErrors(false);
      }
      self.isLoading = false;
      return response;
    }
    self.isLoading = false;
    return null;
  }),
  fetchItemByName: flow(function* (name, queryParams={}) {
    return yield getEnv(self).dataExportManager.fetchAllForMe({...queryParams, name}, self);
  }),
  fetchItemByUUID: flow(function* (id, queryParams={}) {
    return yield getEnv(self).dataExportManager.fetchByUUID(id, queryParams, self);
  }),
  fetchModelAndHydrate: flow(function* (id, queryParams={}){
    if (!id) {
      console.warn(`${getType(self).name} Warn: ID is 'undefined' or 'null'`);
    }
    let response;
    self.isLoading = true;
    self?.setOnSelectChanges();
    try {
      // TODO: remove
      response = yield getEnv(self).dataExportManager.fetchByUUID(id, queryParams, self);
      if (response) {
        self.hydrateForm(response);
        self.handleHasErrors(false);
      } else {
        self.handleHasErrors(true);
      }
    } catch (e) {
      console.error(`${getType(self).name} Error: Failed to Fetch and Hydrate data for '${id}'`, e);
    }
    self.isLoading = false;
    return response;
  }),
  hydrateDimension(dimensions = {}){
    const isDimensionAll = Object.values(dimensions).every(item => lodash.isEmpty(item));
    self.dimensionAll = !isDimensionAll
    self.toggleDimensionAll()
    if (!self.dimensionAll) {
      self.dimensionsMultiSelectStore.hydrateDimensions(dimensions)
    }
  },
  hydrateForm(item){
    self.isLoading = true;
    try {
      self.name = item?.name;
      self.dataExportTypeSelected = item?.type;
      self.dataExportTypeSelectStore?.select(item?.type);
      self.groupSelectStore?.selectGroup(item?.owner?.groups);
      let tagUuids;
      if (item?.tags && typeof item?.tags[0] === 'string') {
        tagUuids = item?.tags;
      } else {
        tagUuids = item?.tags?.flatMap(t => t.uuid);
      }
      self.tagSelectStore?.setSelectedTags(tagUuids);
      self.hydrateDimension(item?.owner?.dimensions || {});
      self.hideActions = item?.hideActions;
      self.data = JSON.stringify(item?.definition);
    } catch (err) {
      console.error(`${getType(self).name} Error: Failed to populate fields to view`, err);
    }
    self.isLoading = false;
  },
})).views((self) => ({
  getSelectedExportSchema() {
    let schema = self?.dataExportTypeSelected?.definition || JSON.stringify({});
    return JSON.parse(schema);
  },
  getSelectedExportSchemaKey() {
    return self?.dataExportTypeSelected?.type || Date.now();
  },
  getSelectedExportUISchema() {
    let uiSchema = self?.dataExportTypeSelected?.uiSchema || JSON.stringify(defaultUISchema);
    return JSON.parse(uiSchema);
  },
  getFormData() {
    return JSON.parse(self.data);
  },
}))

export default DataExportFormState;
