import HttpClient from "../../services/HttpClient";
import AwsHttpClient from "../../services/AwsHttpClient"

class BulkManager {
  constructor(httpClient = null) {
    this.httpClient = httpClient ||  new HttpClient();
    this.awsHttpClient = new AwsHttpClient();
  }
  // function for bulk/download/tagsDownload
  handleTagsDownload = async (data, instance, queryParams) => {
    try {
      const {tags, batchName} = data;
      console.log(data)
      const apiBody = {
        tags: tags,
        batchName: batchName
      };
      const res = await this.httpClient.post('bulk/download/tagsDownload', apiBody);
      return res;
    } catch (e) {
      instance.handleHasErrors(true)
      instance.handleErrorMessage(e.message)
      console.error("Error processing tag download")
    }
  }
  // function for bulk/publish | bulk/create | bulk/download/csvDownload uploading csv file
  handleCsvUpload = async (data, instance) => {
    try {
      const { uploadFiles, batchType, totalItems, batchName } = data;
      const file = uploadFiles[0];
      console.log("batchName:", batchName);
      const signedUrlData = {
        fileName: file.name,
        action: batchType.toUpperCase(),
        totalItems: totalItems || 0,
        batchType: batchType.toLowerCase(),
        batchName: batchName
      };
      console.log("file ****", file)
      const signedUrl = await this.getSignedUrl(signedUrlData);
      const s3response = await this.awsHttpClient.put(signedUrl, file);
      console.log("s3response", s3response)
      return {"success": true}
    } catch (e) {
      instance.handleHasErrors(true)
      instance.handleErrorMessage(e.message)
      console.error("Error processing upload", e)
    }
  }

  getSignedUrl = async (signedUrlData) => {
    let subPath = signedUrlData.batchType
    console.log(subPath);
    if(signedUrlData.batchType === "download") {
      subPath = `${signedUrlData.batchType}/csvDownload`
    }
    const res = await this.httpClient.post(`bulk/${subPath}`, signedUrlData);
    console.log("getSignUrl", res)
    return res?.data.uploadUrl;
  }


}

export default BulkManager;
